import { ImportAction } from './ImportActions';

export type ImportState = {
  accepted: File[] | null;
  rejected: File[] | null;
  loaded: boolean;
  loading: boolean;
  success: boolean;
  error: string | null;
};

export const initialState: ImportState = {
  accepted: null,
  rejected: null,
  loaded: false,
  loading: false,
  success: false,
  error: null,
};

const modifyState = (state: ImportState, action: ImportAction): ImportState => {
  switch (action.type) {
    case 'SET FILES':
      return {
        ...state,
        accepted: action.accepted,
        rejected: action.rejected,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'SUCCEED':
      return {
        ...state,
        loading: false,
        success: true,
      };
    case 'FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export const reducer = (state: ImportState, action: ImportAction): ImportState => {
  const modified = modifyState(state, action);
  modified.loaded = !!modified.accepted || !!modified.rejected;
  return modified;
};
