import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { UIContext } from 'shared';

type ToastOptions = {
  context: UIContext;
};

function useToast() {
  const show = useCallback((message: string, options: ToastOptions = { context: 'primary' }) => {
    switch (options.context) {
      case 'info':
      case 'primary':
        toast.success(message);
        break;
      case 'danger':
      case 'warning':
        toast.error(message);
    }
  }, []);

  return show;
}

export default useToast;
