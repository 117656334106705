import { useFormikContext } from 'formik';
import { Batch } from '../../../../../model';
import { Currency, Heading } from '../../../../../components';
import { css } from '@emotion/css';

function BatchFormSummary() {
  const { values } = useFormikContext<Batch>();
  const root = css({
    height: '100%',
    position: 'relative',
  });

  const positioned = css({
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: 'right',
  });

  const total = Object
    .keys(values.transactions!)
    // @ts-ignore
    .reduce((sum, key) => sum + values.transactions![key], 0);

  return (
    <div className={root}>
      <div className={positioned}>
        <Heading as={'h2'} dense>
          <Currency value={total} />
        </Heading>
        Total Payment Amount
      </div>
    </div>
  );
}

export default BatchFormSummary;
