import { css } from '@emotion/css';
import { forwardRef } from 'react';
import { ReactComponent as Cog } from '../icons/cog-light.svg';
import Button, { ButtonProps } from './Button';
import FlexStack from './FlexStack';
import useTheme from './Theme/useTheme';

type Props = {
  label: string;
} & Omit<ButtonProps, 'link'>;

const CogButton = forwardRef<HTMLButtonElement, Props>(function CogButton(
  { onClick, label, ...props },
  ref
) {
  const theme = useTheme();
  const classes = css({
    color: theme.colors.black,
    margin: `${theme.spacing.sm} 0`,
    width: '100%',
  });

  return (
    <Button ref={ref} {...props} link onClick={onClick} className={classes}>
      <FlexStack>
        <span>{label}</span>
        <Cog width="16" height="16" />
      </FlexStack>
    </Button>
  );
});

export default CogButton;
