import { useContext, useMemo } from 'react';
import Color from 'color';
import Theme from './Theme';
import { ThemeContext } from './ThemeProvider';

function useTheme(): Theme {
  const context = useContext(ThemeContext);

  const theme = useMemo<Theme>(() => {
    const white = Color(context.colors.get('white'));
    const black = Color(context.colors.get('black'));
    const primary = Color(context.colors.get('primary'));
    const secondary = Color(context.colors.get('secondary'));
    const umbra = Color('rgba(0, 0, 0, 0.2)');
    const penumbra = Color('rgba(0, 0, 0, 0.14)');
    const antumbra = Color('rgba(0, 0, 0, 0.1)');

    return {
      colors: {
        white: white.hex(),
        'white-300': white.darken(0.1).hex(),
        'white-400': white.darken(0.2).hex(),
        'white-500': white.darken(0.3).hex(),

        black: black.hex(),
        'black-300': black.lighten(0.1).hex(),
        'black-400': black.lighten(0.2).hex(),
        'black-500': black.lighten(0.3).hex(),

        primary: primary.hex(),
        'primary-300': (primary.isDark() ? primary.lighten(0.1) : primary.darken(0.1)).hex(),
        'primary-400': (primary.isDark() ? primary.lighten(0.2) : primary.darken(0.2)).hex(),
        'primary-contrast': (primary.isDark() ? primary.whiten(0.9) : primary.darken(0.9)).hex(),

        secondary: secondary.hex(),

        'secondary-300': (secondary.isDark()
          ? secondary.lighten(0.1)
          : secondary.darken(0.1)
        ).hex(),

        'secondary-400': (secondary.isDark()
          ? secondary.lighten(0.2)
          : secondary.darken(0.2)
        ).hex(),

        background: (context.mode === 'light' ? white.darken(0.025) : black.lighten(0.025)).hex(),

        danger: Color('red').hex(),
        'danger-white': Color('white').mix(Color('red'), 0.1).hex(),

        warning: Color('orange').hex(),
        'warning-white': Color('orange').whiten(0.85).hex(),
      },
      mode: context.mode,
      shadows: {
        1: `0 1px 3px 0 ${umbra}, 0 1px 1px 0 ${penumbra}, 0 2px 1px -1px ${antumbra}`,
        2: `0 1px 5px 0 ${umbra}, 0 2px 2px 0 ${penumbra}, 0 3px 1px -2px ${antumbra}`,
        3: `0 1px 8px 0 ${umbra}, 0 3px 4px 0 ${penumbra}, 0 3px 3px -2px ${antumbra}`,
        4: `0 2px 4px -1px ${umbra}, 0 4px 5px 0 ${penumbra}, 0 1px 10px 0 ${antumbra}`,
        6: `0 3px 5px -1px ${umbra}, 0 6px 10px 0 ${penumbra}, 0 1px 18px 0 ${antumbra}`,
        8: `0 5px 5px -3px ${umbra}, 0 8px 10px 1px ${penumbra}, 0 3px 14px 2px ${antumbra}`,
        12: `0 7px 8px -4px ${umbra}, 0 12px 17px 2px ${penumbra}, 0 5px 22px 4px ${antumbra}`,
        16: `0 8px 10px -5px ${umbra}, 0 16px 24px 2px ${penumbra}, 0 6px 30px 5px ${antumbra}`,
        24: `0 11px 15px -7px ${umbra}, 0 24px 38px 3px ${penumbra}, 0 9px 46px 8px ${antumbra}`,
      },
      spacing: {
        sm: '.5rem',
        md: '.85rem',
        lg: '1.25rem',
        xl: '2rem;',
      },
      fontFamily: context.fonts,
    };
  }, [context]);

  return theme;
}

export default useTheme;
