import { GridArea, Heading } from 'components';

const StatusPage = () => (
  <>
    <GridArea area="heading">
      <Heading>Import Status</Heading>
    </GridArea>
    <GridArea area="content">
      <p>Content goes here...</p>
    </GridArea>
  </>
);

export default StatusPage;
