import { ChangeEventHandler, useContext } from 'react';
import { SearchContext } from 'core';
import Input from 'components/Input';

function EnrollmentFilter() {
  const { set, query } = useContext(SearchContext);

  const doChange: ChangeEventHandler<HTMLInputElement> = e => {
    set(e.target.value);
  };

  return <Input placeholder='Search by name...' onChange={doChange} value={query} />;
}

export default EnrollmentFilter;
