import { css } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { useMemo } from 'react';

function useDangerColorStyle() {
  const theme = useTheme();
  const className = useMemo(
    () =>
      css({
        color: theme.colors.danger,
      }),
    [theme]
  );

  return className;
}

export default useDangerColorStyle;
