import { Breadcrumb, Breadcrumbs, FlexStack, Heading } from 'components';
import { useModelEntity } from 'hooks';
import { Member } from 'model';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import MemberControls from './MemberControls';

function MemberEditHeader() {
  const location = useLocation();
  const url = location?.pathname;
  const { entity, loading } = useModelEntity<Member>();

  const name = loading ? (
    <Skeleton height='1rem' width={100} />
  ) : (
    `${entity?.givenName} ${entity?.surname}`
  );

  return (
    <FlexStack>
      <Heading>
        Member
        <Breadcrumbs>
          <Breadcrumb key={0} to='/member'>Members</Breadcrumb>
          <Breadcrumb key={1}>{name}</Breadcrumb>
        </Breadcrumbs>
      </Heading>
      <MemberControls home={url} />
    </FlexStack>
  );
}

export default MemberEditHeader;
