import { ModelField, Select } from 'components';
import { useFormikContext } from 'formik';
import { Batch } from 'model';
import { useEffect } from 'react';

function BatchTypeField() {
  const { values, setFieldValue } = useFormikContext<Batch>();
  const disabled = values.method !== 'credit';

  useEffect(() => {
    if (values.method !== 'credit' && values.type !== 'physical') {
      setFieldValue('type', 'physical');
    }
  }, [values, setFieldValue]);

  return (
    <ModelField<Batch> as={Select} name='type' disabled={disabled}>
      <option value='physical'>Physical</option>
      <option value='digital'>Digital</option>
    </ModelField>
  );
}

export default BatchTypeField;
