import { Global, css } from '@emotion/react';
import Color from 'color';
import { Map } from 'immutable';
import { createContext, ReactNode, useCallback, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeColors, ThemeFonts } from './Theme';

const kDefaultThemeColors = {
  primary: '#758bfd',
  secondary: '#ffbc70',
  white: '#f8fafd',
  black: '#392f5a',
};

const kDefaultFonts = {
  default: "'Roboto', sans-serif",
  mono: "'Roboto Mono', monospace",
};

type ThemeContextType = {
  mode: 'light' | 'dark';
  colors: Map<ThemeColors, string>;
  fonts: Record<ThemeFonts, string>;
  toggle: () => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  colors: Map<ThemeColors, string>(kDefaultThemeColors),
  fonts: kDefaultFonts,
  toggle: () => {},
});

type Props = {
  children?: ReactNode;
  themeColors?: typeof kDefaultThemeColors;
  fonts?: Record<ThemeFonts, string>;
};

function ThemeProvider({
  children,
  themeColors = kDefaultThemeColors,
  fonts = kDefaultFonts,
}: Props) {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const toggle = useCallback(() => {
    setMode(state => (state === 'light' ? 'dark' : 'light'));
  }, [setMode]);

  const colors = Map<ThemeColors, string>(themeColors);
  const global = css({
    '*': {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      color: mode === 'light' ? colors.get('black') : colors.get('white'),
      backgroundColor: mode === 'light' ? colors.get('white') : colors.get('black'),
      fontFamily: fonts.default,
      fontSize: 14,
    },
    input: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      outline: 'none',
      width: '100%',
    },
    textarea: {
      outline: 'none',
    },
  });

  const skeletonColor = Color(colors.get('white')).darken(0.1).hex();

  return (
    <SkeletonTheme baseColor={skeletonColor}>
      <Global styles={global} />
      <ThemeContext.Provider value={{ mode, toggle, colors, fonts }}>
        {children}
      </ThemeContext.Provider>
    </SkeletonTheme>
  );
}

export default ThemeProvider;
