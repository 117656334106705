import { css, cx } from '@emotion/css';
import { LabelHTMLAttributes, ReactNode } from 'react';
import useTheme from './Theme/useTheme';

type Props = {
  children?: ReactNode;
} & LabelHTMLAttributes<HTMLLabelElement>;

function Label({ children, className, ...props }: Props) {
  const theme = useTheme();
  const classes = cx(
    className,
    css({
      marginBottom: `${theme.spacing.sm}`,
      display: 'block',
    })
  );

  return (
    <label {...props} className={classes}>
      {children}
    </label>
  );
}

export default Label;
