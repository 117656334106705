import { useModelEntity } from 'hooks';
import { Member } from 'model';
import Skeleton from 'react-loading-skeleton';

function MemberCrumb() {
  const { entity, loading } = useModelEntity<Member>();
  const name = `${entity?.givenName} ${entity?.surname}`;

  return loading ? <Skeleton height="1rem" width={100} /> : <span>{name}</span>;
}

export default MemberCrumb;
