import { useAuth0 } from '@auth0/auth0-react';
import { MedsocUser } from 'core';
import { useEffect, ReactNode, Fragment, ComponentType, createElement } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  disableChallenge?: boolean;
  roles?: string[];
  children?: ReactNode;
  as?: ComponentType;
};

function Authorize({ disableChallenge = false, roles, as: is, children }: Props) {
  const location = useLocation();
  const auth0 = useAuth0();
  const user = new MedsocUser(auth0.user);

  useEffect(() => {
    if (!auth0.isLoading && !auth0.isAuthenticated && !disableChallenge) {
      auth0.loginWithRedirect({
        appState: {
          returnTo: location.pathname,
        },
      }).catch(console.error);
    }
  }, [location, auth0, disableChallenge]);

  if (!auth0.isAuthenticated) {
    return null;
  } else if (roles && !user.hasRole(...roles)) {
    return is ? createElement(is) : null;
  }

  return <Fragment>{children}</Fragment>;
}

export default Authorize;
