import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth0ContextInterface } from '@auth0/auth0-react';

const GRAPH_ENDPOINT = process.env.REACT_APP_GRAPH_ENDPOINT;

function createTransportClient(context: Auth0ContextInterface) {
  const httpLink = createHttpLink({
    uri: GRAPH_ENDPOINT,
  });

  let link = httpLink;
  if (context.isAuthenticated) {
    const authLink = setContext(async (_, { headers }) => {
      const token = await context.getAccessTokenSilently({
        detailedResponse: true,
      });

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token.access_token}`,
        },
      };
    });

    link = authLink.concat(httpLink);
  }

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}

export default createTransportClient;
