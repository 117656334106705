import { css } from '@emotion/css';
import { Divider, Flex, Heading, SelectMemberEnrollment } from 'components';
import MemberDuesEnrollment from './MemberDuesEnrollment';

function MemberEnrollments() {
  const rootClass = css({
    height: '100%',
    flexDirection: 'column',
  });

  return (
    <Flex className={rootClass}>
      <Heading as='h2'>Enrollments</Heading>
      <SelectMemberEnrollment as={MemberDuesEnrollment} label='Member Type' type='MemberType' />
      <Divider />
      <SelectMemberEnrollment as={MemberDuesEnrollment} label='County' type='County' />
      <Divider />
      <SelectMemberEnrollment label='Doctor Group' type='DoctorGroup' />
      <Divider />
      <SelectMemberEnrollment label='Doctor Specialty' multiple type='DoctorSpecialty' />
      <Divider />
      <SelectMemberEnrollment label='Doctor Type' type='DoctorType' />
      <Divider />
      <SelectMemberEnrollment label='Board Certification' type='BoardCertification' />
      <Divider />
    </Flex>
  );
}

export default MemberEnrollments;
