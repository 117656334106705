import { ModelField } from 'components';
import { useFormikContext } from 'formik';
import { Batch } from 'model';
import { useEffect } from 'react';

function CheckNumberField() {
  const { values, setFieldValue } = useFormikContext<Batch>();

  useEffect(() => {
    if (values.method !== 'check' && values.checkNumber !== 0) {
      setFieldValue('checkNumber', 0);
    }
  }, [values, setFieldValue]);

  const disabled = values.method !== 'check';
  return <ModelField<Batch> type='number' label='Check #' name='checkNumber' disabled={disabled} />;
}

export default CheckNumberField;
