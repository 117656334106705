import React, { createContext } from 'react';
import { Actions, initTaggedSearchState, TaggedSearchState } from './TaggedSearchReducer/Types';

type SearchContextType = {
  state: TaggedSearchState;
  dispatch: React.Dispatch<Actions>;
};

const TaggedSearchContext = createContext<SearchContextType>({
  state: initTaggedSearchState,
  dispatch: () => {},
});

export default TaggedSearchContext;
