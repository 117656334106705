import { css } from '@emotion/css';
import { TableHTMLAttributes } from 'react';
import useTheme from '../Theme/useTheme';

interface Props extends TableHTMLAttributes<HTMLTableElement> {}

function Table({ className, ...props }: Props) {
  const theme = useTheme();

  const rootClass = css({
    maxWidth: '100%',
    overflowX: 'auto',
  });

  const tableClass = css({
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderWidth: 1,
    width: '100%',
    fontFamily: theme.fontFamily.mono,
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    border: `1px solid ${theme.colors['white-300']}`,
  });

  return (
    <div className={rootClass}>
      <table {...props} className={tableClass} />
    </div>
  );
}

export default Table;
