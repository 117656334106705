import { useLocation } from 'react-router';
import { ISearchContext, SearchContext } from '../../core';
import { ReactNode, useEffect, useState } from 'react';

interface RootSearchContextProviderProps {
  children?: ReactNode;
}

function RootSearchContextProvider({ children }: RootSearchContextProviderProps) {
  const location = useLocation();
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery('');
  }, [setQuery, location.pathname]);

  const value: ISearchContext = {
    query,
    set: setQuery,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default RootSearchContextProvider;
