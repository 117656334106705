import { useFormikContext } from 'formik';
import { IEntity } from '../../data';
import useSrOnlyStyle from '../../hooks/useSrOnlyStyle';

type Props<T extends IEntity> = {
  field: keyof T;
};

function ModelError<T extends IEntity>({ field }: Props<T>) {
  const { errors } = useFormikContext<T>();
  const rootClass = useSrOnlyStyle();

  if (!errors || !errors[field]) {
    return null;
  }

  return <span className={rootClass}>{field in errors ? errors[field] as string : null}</span>;
}

export default ModelError;
