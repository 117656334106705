import { css, cx } from '@emotion/css';
import { HTMLAttributes, ReactNode } from 'react';
import useTheme from '../Theme/useTheme';
import Heading from './Heading';

type Props = {
  heading?: ReactNode;
  fixedWidth?: boolean;
} & HTMLAttributes<HTMLDivElement>;

function Menu({ className, heading, children, fixedWidth, ...props }: Props) {
  const theme = useTheme();
  const fixedWithClass = css({
    width: 350,
  });

  const classes = cx(
    className,
    css({
      borderColor: theme.colors['white-300'],
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 5,
      boxShadow: theme.shadows[8],
      backgroundColor: theme.colors.white,
    }),
    {
      [fixedWithClass]: fixedWidth,
    }
  );

  return (
    <div {...props} className={classes}>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </div>
  );
}

export default Menu;
