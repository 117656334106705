import { css } from '@emotion/css';
import { Currency, Grid, GridArea, Heading, Input, Label, ModelField } from 'components';
import { useTheme } from 'components/hooks';
import { useFormikContext } from 'formik';
import { Batch, Ledger, QUERY_MEMBER_FORM } from 'model';
import { Fragment, useContext } from 'react';
import { titleCase } from 'title-case';
import { IDContext } from '../../../../../core';
import { useQuery } from '@apollo/client';

interface Props {
}

function MemberBatchTransactions(_: Props) {
  const { values } = useFormikContext<Batch>();
  const { id } = useContext(IDContext);
  const theme = useTheme();

  const { data } = useQuery(QUERY_MEMBER_FORM, {
    variables: { id },
  });

  const balanceClass = css({
    padding: `${theme.spacing.sm} 0`,
  });

  if (!data?.node.ledgers || !Array.isArray(data.node.ledgers) || !values.transactions) {
    return null;
  }

  let ledgers = data.node.ledgers as Ledger[];
  ledgers = ledgers.filter(l => Object.keys(values.transactions!).includes(l.name));

  const fields = ledgers.map((ledger) => {
    const label = titleCase(ledger.name);

    const balance = ledger.balance || 0;
    const disbursement = (values.transactions as any)![ledger.name] || 0;
    const difference = balance - disbursement;

    return (
      <Fragment key={ledger.id}>
        <GridArea area={ledger.name}>
          <ModelField
            label={label}
            as={Input}
            name={`transactions.${ledger.name}` as any}
            type='number'
          />
        </GridArea>
        <GridArea area={ledger.name + '-summary'}>
          <Label>{label} Change ($)</Label>
          <div className={balanceClass}>
            <code>
              <Currency value={balance} /> - <Currency value={disbursement} /> = <Currency value={difference} />
            </code>
          </div>
        </GridArea>
      </Fragment>
    );
  });

  const template = ledgers
    .map(ledger => `"${ledger.name} ${ledger.name}-summary"`)
    .concat('/ 1fr 1fr')
    .join('\n');

  return (
    <GridArea area='transactions'>
      <Heading as='h4'>Disbursements</Heading>
      <Grid gap={14} template={template}>
        {fields}
      </Grid>
    </GridArea>
  );
}

export default MemberBatchTransactions;
