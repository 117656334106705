import { css, cx } from '@emotion/css';
import { ReactElement } from 'react';
import { useLocation } from 'react-router';
import useTheme from '../Theme/useTheme';
import Header from './Header';
import Item from './Item';
import List from './List';
import { MenuLinkProps } from './MenuLink';

type Props = {
  home: string;
  title: string;
  children: ReactElement<MenuLinkProps> | Array<ReactElement<MenuLinkProps>>;
};

function Section({ home, title, children }: Props) {
  const location = useLocation();
  const theme = useTheme();

  const normal = css({
    paddingLeft: theme.spacing.sm,
  });

  const classes = cx(normal);
  const active = location.pathname.startsWith(home);

  return (
    <Item className={classes}>
      <Header active={active}>{title}</Header>
      <List>{children}</List>
    </Item>
  );
}

export default Section;
