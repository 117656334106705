import { css } from '@emotion/css';
import { ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useLinkStyle from '../../hooks/useLinkStyle';
import useTheme from '../Theme/useTheme';
import Item from './Item';

type Props = {
  to: string;
  children?: ReactNode;
};

function MenuLink({ to, children }: Props) {
  const location = useLocation();
  const theme = useTheme();

  const classes = useLinkStyle(
    css({
      display: 'block',
      width: '100%',
      opacity: 0.85,
      padding: theme.spacing.sm,
      color: theme.colors['black-500'],
      ':hover': {
        opacity: 1,
      },
    })
  );

  const active = css({
    opacity: 1,
    color: `${theme.colors.primary}`,
  });

  const activeClasses = `${classes} ${active}`;

  return (
    <Item active={location.pathname.startsWith(to)}>
      <NavLink to={to} className={({ isActive }) => (isActive ? activeClasses : classes)}>
        {children}
      </NavLink>
    </Item>
  );
}

export type MenuLinkProps = Props;
export default MenuLink;
