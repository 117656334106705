import { Record } from 'immutable';
import { useState } from 'react';

interface Strings {
  saveSuccess: string;
  saveFail: string;
  confirmDeleteRecord: string;
  requiredEntityNotFound: string;
  validationHelpText: string;
}

const StringsRecord = Record<Strings>({
  saveFail: 'Save operation failed',
  saveSuccess: 'Your changes have been saved',
  requiredEntityNotFound: 'A required entity is not found.',
  validationHelpText: 'Please review and resolve the following issues to save your changes.',
  confirmDeleteRecord:
    'Are you sure you want to delete this record? This operation cannot be undone.',
});

function useStrings() {
  // TODO: localize?
  const [state] = useState(StringsRecord());
  return state;
}

export default useStrings;
