import { css } from '@emotion/css';
import Flex from '../Flex';
import Grid from '../Grid';
import GridArea from '../GridArea';
import useTheme from '../Theme/useTheme';
import Notifications from './Notifications';
import User from './User';

const template = `
"search user" auto
/ 1fr 350px`;

function AppNav() {
  const theme = useTheme();
  const rootClass = css({
    height: 40,
  });

  const searchClass = css({
    alignItems: 'center',
    height: '100%',
    flex: 1,
  });

  const userClass = css({
    alignItems: 'center',
    flexShrink: 0,
    height: '100%',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.md,
    '> :not(:last-child)': {
      marginRight: theme.spacing.md,
    },
  });

  return (
    <Grid gap={20} className={rootClass} template={template}>
      <GridArea area="search">
        <Flex id="search" className={searchClass} />
      </GridArea>
      <GridArea area="user">
        <Flex className={userClass}>
          <Notifications />
          <User />
        </Flex>
      </GridArea>
    </Grid>
  );
}

export default AppNav;
