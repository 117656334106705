import { css } from '@emotion/css';
import { useContext } from 'react';
import Reset from './components/Reset';
import { ImportContext } from './context';

const container = css`
  background: #ff626259;
  border: 2px solid #d14747;
`;

const heading = css`
  color: #5b0909;
  margin-top: 0;
`;

const list = css`
  margin-top: 0;
`;

const FileErrors = () => {
  const {
    state: { loaded, error, accepted, rejected },
  } = useContext(ImportContext);

  if (!error && (!loaded || rejected?.length === 0)) {
    return null;
  }

  const items = rejected?.concat(accepted ?? []).map(f => <li key={f.name}>{f.name}</li>);
  return (
    <div className={container}>
      <h2 className={heading}>{error ?? 'Cannot upload the following files:'}</h2>
      {!error && <ul className={list}>{items}</ul>}
      <Reset />
    </div>
  );
};

export default FileErrors;
