import { Enrollment } from 'model';
import { murmerhash_32_gc } from 'shared';
import { EnrollmentType } from '.';

class ComparableEnrollment implements Enrollment {
  type: EnrollmentType;
  name: string;
  dues?: string | number;
  id?: string | undefined;
  updatedAt?: string | undefined;
  createdAt?: string | undefined;

  constructor(from: Enrollment) {
    this.type = from.type;
    this.name = from.name;
    this.dues = typeof from.dues === 'string' ? parseFloat(from.dues) : from.dues;
    this.id = from.id;
    this.updatedAt = from.updatedAt;
    this.createdAt = from.createdAt;
  }

  equals(other: ComparableEnrollment): boolean {
    return this.id === other.id;
  }

  hashCode(): number {
    return murmerhash_32_gc(JSON.stringify(this), 0x5787);
  }
}

export default ComparableEnrollment;
