import { Breadcrumb, Breadcrumbs, Heading } from 'components';
import { ReactNode } from 'react';

interface Props {
  memberName: ReactNode;
  children?: ReactNode;
}

function TransactionFormHeading({ memberName, children }: Props) {
  return (
    <Heading>
      Transaction
      <Breadcrumbs>
        <Breadcrumb to='/member'>Members</Breadcrumb>
        <Breadcrumb to='../..'>{memberName}</Breadcrumb>
        <Breadcrumb to='..'>Transactions</Breadcrumb>
        <Breadcrumb>{children}</Breadcrumb>
      </Breadcrumbs>
    </Heading>
  );
}

export default TransactionFormHeading;
