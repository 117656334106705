import { css } from '@emotion/css';
import { DashboardMetric, FlexStack, Grid, GridArea, Link } from 'components';
import { useTheme } from 'components/hooks';
import { IDContext } from 'core';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import MemberBalance from './MemberBalance';
import MemberCurrentNempac from './MemberCurrentNempac';
import MemberLastPayment from './MemberLastPayment';

const template = `
"balance last_payment nempac" 1fr
"link link link"
/1fr 1fr 1fr`;

function MemberDashboard() {
  const theme = useTheme();
  const { id } = useContext(IDContext);
  const location = useLocation();
  const url = location?.pathname;

  const rootClass = css({
    marginBottom: theme.spacing.lg,
    gridAutoFlow: 'column',
  });

  return (
    <Grid className={rootClass} template={template} gap={10}>
      <GridArea area='balance'>
        <DashboardMetric heading='Current Balance'>
          <MemberBalance id={id!} />
        </DashboardMetric>
      </GridArea>
      <GridArea area='last_payment'>
        <DashboardMetric heading='Last Payment Date'>
          <MemberLastPayment id={id!} />
        </DashboardMetric>
      </GridArea>
      <GridArea area='nempac'>
        <DashboardMetric heading={`NEMPAC Donation ${new Date().getFullYear()}`}>
          <MemberCurrentNempac id={id!} />
        </DashboardMetric>
      </GridArea>
      <GridArea area='link'>
        <FlexStack arrange='right'>
          <Link to={`${url}/transactions`}>View Transactions</Link>
        </FlexStack>
      </GridArea>
    </Grid>
  );
}

export default MemberDashboard;
