import MenuLink from './MenuLink';
import Section from './Section';
import List from './List';
import AppNavMenuContainer from './AppNavMenuContainer';
import Brand from './Brand';
import { css } from '@emotion/css';
import useTheme from '../Theme/useTheme';

function AppNavMenu() {
  const theme = useTheme();
  const classes = css({
    position: 'sticky',
    top: theme.spacing.sm,
    fontSize: 16,
  });

  return (
    <nav className={classes}>
      <Brand />
      <AppNavMenuContainer>
        <List>
          <Section home='/member' title='Membership'>
            <MenuLink to='/member'>Members</MenuLink>
            <MenuLink to='/rosters'>Rosters</MenuLink>
          </Section>
          <Section home='/reports' title='Reports'>
            <MenuLink to='/reports/dues'>Dues</MenuLink>
            <MenuLink to='/reports/roster'>Roster</MenuLink>
            <MenuLink to='/reports/statement'>Statements</MenuLink>
          </Section>
          <Section home='/setup' title='Setup'>
            <MenuLink to='/setup/county'>Counties</MenuLink>
            <MenuLink to='/setup/type'>Member Types</MenuLink>
          </Section>
          <Section home='/setup/doctor' title='Doctor'>
            <MenuLink to='/setup/doctor/group'>Group</MenuLink>
            <MenuLink to='/setup/doctor/specialty'>Specialty</MenuLink>
            <MenuLink to='/setup/doctor/type'>Type</MenuLink>
            <MenuLink to='/setup/doctor/certification'>Board Certifications</MenuLink>
          </Section>
        </List>
      </AppNavMenuContainer>
    </nav>
  );
}

export default AppNavMenu;
