import { css, cx } from '@emotion/css';
import { forwardRef, InputHTMLAttributes } from 'react';
import useInputStyle from '../hooks/useInputStyle';

type Props = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, Props>(function Input({ className, ...props }, ref) {
  const nonCheckboxStyle = css();

  const classes = useInputStyle(
    cx(
      {
        [nonCheckboxStyle]: props.type !== 'checkbox',
      },
      className
    )
  );

  return <input {...props} ref={ref} className={classes} />;
});

export default Input;
