import { css } from '@emotion/css';
import { Authorize, Flex, Link } from 'components';
import { useTheme } from 'components/hooks';
import { ReactComponent as Download } from 'icons/download-light.svg';
import { useQuery } from '@apollo/client';
import { QUERY_MEMBER_FORM } from '../../../model';
import { useContext } from 'react';
import { IDContext } from '../../../core';

interface Props {
  home: string;
}

function MemberControls({ home }: Props) {
  const theme = useTheme();
  const { id } = useContext(IDContext);
  const query = useQuery(QUERY_MEMBER_FORM, {
    variables: {
      id,
    },
  });

  if (query.loading) {
    return null;
  }

  const rootClass = css({
    '> :not(:last-child)': {
      marginRight: theme.spacing.sm,
    },
  });

  return (
    <Flex className={rootClass}>
      <Link to={process.env.REACT_APP_GRAPH_HOST + query.data?.node.statementUrl}
            context='info' target='_blank' button>
        <Download height={16} />&nbsp;Statement Export
      </Link>
      <Authorize roles={['manager']}>
        <Link to={`${home}/payment/new`} c2a button context='primary'>
          Process Payment
        </Link>
      </Authorize>
    </Flex>
  );
}

export default MemberControls;
