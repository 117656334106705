import { SearchPortal } from 'components';
import { NotFoundPage } from 'pages';
import { Route, Routes } from 'react-router-dom';
import MemberEditIndex from './Edit';
import NewMemberPage from './New';
import MemberTablePage from './Table';
import { Fragment } from 'react';

function MemberIndexPage() {
  return (
    <Fragment>
      <SearchPortal />
      <Routes>
        <Route index element={<MemberTablePage home={'/member'} />} />
        <Route path='new' element={<NewMemberPage home={'/member'} />} />
        <Route path=':id/*' element={<MemberEditIndex />} />
        <Route path='*' Component={NotFoundPage} />
      </Routes>
    </Fragment>
  );
}

export default MemberIndexPage;
