import { css } from '@emotion/css';
import { ComponentType } from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Fieldset, FlexStack } from 'components';
import { useTheme } from 'components/hooks';
import { IEntity, ModelNormalize, ModelSchema } from 'data';
import { useStrings, useModelEntity, useToast, useUser } from 'hooks';

import ModelField, { ModelFieldProps } from './ModelField';
import ModelSave from './ModelSave';
import ValidationSummary from './ValidationSummary';
import ModelFormSkeleton from './Skeleton';
import { FormUpdater } from './FormUpdater';

type Props<T extends IEntity> = {
  home: string;
  schema?: ModelSchema<T>;
  normalize?: ModelNormalize<T>;
  waitForLoad?: boolean;
  insertRoles?: string[];
  hideNew?: boolean;
  initialValues: T;
  disabled?: boolean;
  children?: (ModelField: ComponentType<ModelFieldProps<T>>) => JSX.Element;
};

function ModelForm<T extends IEntity>(props: Props<T>) {
  const { initialValues, normalize, home, hideNew, insertRoles = ['admin', 'manager'] } = props;

  const navigate = useNavigate();
  const strings = useStrings();
  const context = useModelEntity<T>();
  const toast = useToast();
  const theme = useTheme();
  const user = useUser();

  const entity = context.entity;
  const exists = Boolean(entity?.createdAt);
  const showNew = !hideNew && exists && user.hasRole(...insertRoles);

  if (props.waitForLoad && context.loading) {
    return <ModelFormSkeleton />;
  }

  const onSubmit = async (values: T) => {
    try {
      let result: T;

      const { id, ...set } = normalize?.egress(values) || values;
      result = await context.merge(id!, set as any);

      if (!exists) {
        if (result) {
          navigate(`${props.home}/${result.id}`);
        }
      }

      if (result) {
        toast(strings.saveSuccess);
      }
    } catch (err) {
      console.error(err);
      toast(strings.saveFail, {
        context: 'danger',
      });
    }
  };

  const formik: FormikConfig<T> = {
    onSubmit,
    validationSchema: props.schema,
    initialValues: entity ? normalize?.ingress(entity) || initialValues : initialValues,
    validateOnBlur: true,
  };

  const validationSummaryContainerClass = css({
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: theme.spacing.md,
  });

  return (
    <Formik {...formik}>
      <Form>
        {entity && <FormUpdater values={normalize?.ingress(entity) || entity} />}
        <Fieldset disabled={context.merging}>
          {exists && <ModelField name='id' type='hidden' value={entity?.id} disabled readOnly />}
          {props.children?.(ModelField)}
          <FlexStack className={validationSummaryContainerClass}>
            <ValidationSummary />
            <ModelSave
              disabled={props.disabled || context.loading}
              showNewLink={showNew}
              home={home}
            />
          </FlexStack>
        </Fieldset>
      </Form>
    </Formik>
  );
}

export type ModelFormProps<T extends IEntity> = Props<T>;
export default ModelForm;
