import { useAuth0 } from '@auth0/auth0-react';
import { Menu } from '../Menu';
import { useAppNavIconStyle } from 'hooks';
import { ReactComponent as UserGuy } from '../../icons/user-circle-light.svg';
import { Fragment } from 'react';
import usePopover from '../../hooks/usePopover';
import Button from '../Button';
import Clickout from '../Clickout';
import Popover from '../Popover';
import Link from '../Link';
import useMenuLinkStyles from '../Menu/useMenuLinkStyle';

function User() {
  const { user } = useAuth0();
  const { show, activator, clickOut, popover } = usePopover();
  const menuButtonClass = useMenuLinkStyles();
  const iconClass = useAppNavIconStyle();

  if (!user) {
    return null;
  }

  const heading = (
    <Fragment>
      Signed in as <strong>{user.name}</strong>
    </Fragment>
  );

  return (
    <div>
      <Button link {...activator}>
        <UserGuy className={iconClass} width="24" />
      </Button>
      {show && (
        <Clickout {...clickOut}>
          <Popover placement="bottom-end" {...popover}>
            <Menu heading={heading}>
              <Link className={menuButtonClass} to="/logout">
                Logout
              </Link>
            </Menu>
          </Popover>
        </Clickout>
      )}
    </div>
  );
}

export default User;
