import { css, cx } from '@emotion/css';
import Color from 'color';
import { useMemo } from 'react';
import useTheme from '../components/Theme/useTheme';
import useTransition from './useTransition';

function useLinkStyle(className?: string, active?: boolean) {
  const theme = useTheme();
  const transition = useTransition('color');

  const styles = useMemo(() => {
    const activeStyles = css({
      color: theme.colors.primary,
    });

    return cx(
      css({
        ...transition,
        color: theme.colors.primary,
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'none',
        fontWeight: 600,
        padding: 0,
        ':hover': {
          color: Color(theme.colors.primary).darken(0.1).hex(),
          cursor: 'pointer',
        },
      }),
      className,
      {
        [activeStyles]: active,
      }
    );
  }, [theme, className, transition, active]);

  return styles;
}

export default useLinkStyle;
