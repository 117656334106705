import { css } from '@emotion/css';
import useTheme from 'components/Theme/useTheme';

function Divider() {
  const theme = useTheme();
  const rootClass = css({
    margin: `0 ${theme.spacing.sm}`,
  });

  return <span className={rootClass}>/</span>;
}

export default Divider;
