import { Authorize } from 'components';
import NotFoundPage from '../404';

function AdminIndexPage() {
  return (
    <Authorize roles={['admin']} as={NotFoundPage} disableChallenge>
      <div>Admin Only Page</div>
    </Authorize>
  );
}

export default AdminIndexPage;
