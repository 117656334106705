import { Flex, Loading, Menu, MenuContent } from 'components';
import TransportError from 'components/TransportError';
import { SearchContext } from 'core';
import { useQuery } from 'hooks';
import { Set } from 'immutable';
import { ComparableEnrollment, Enrollment, EnrollmentType } from 'model';
import { useContext } from 'react';
import EnrollmentFilter from './EnrollmentFilter';
import EnrollmentSelect from './EnrollmentSelect';
import gql from 'graphql-tag';

interface Props {
  type: EnrollmentType;
  selected: Set<Enrollment>;
  multiple?: boolean;
  label?: string;
  onSelect?: (enrollment: ComparableEnrollment) => void;
}

function EnrollmentMenu({ type, selected, onSelect, label }: Props) {
  const { query } = useContext(SearchContext);

  const enrollments = useQuery(gql`
    query GetEnrollments($query: String!) {
      list: enrollments(query: $query, first: 5) {
        edges {
          node {
            id
            ... on Enrollment {
              name
              dues
            }
          }
        }
      }
    }
  `, {
    variables: { query: `(type:${type}) ${query}` },
  });

  const items = enrollments.data?.list.edges
    .map((x: any) => new ComparableEnrollment(x.node))
    .map((e: ComparableEnrollment) => (
      <EnrollmentSelect key={e.id!} selected={selected.has(e)} onSelect={onSelect} entity={e} />
    ));

  const empty = !items || items.length === 0;

  return (
    <Menu fixedWidth heading={label || type}>
      <MenuContent>
        <EnrollmentFilter />
      </MenuContent>
      {enrollments.loading ? (
        <MenuContent>
          <Loading />
        </MenuContent>
      ) : (
        empty && (
          <MenuContent>
            <Flex>
              <TransportError error={enrollments.error} /> None available
            </Flex>
          </MenuContent>
        )
      )}
      {items}
    </Menu>
  );
}

export default EnrollmentMenu;
