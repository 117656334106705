import { Heading, Authorize, Breadcrumbs, Breadcrumb } from 'components';
import { ModelHelpers } from 'shared';

interface Props {
  home: string;
}

function PositionTableHeading({ home }: Props) {
  return (
    <Heading>
      Positions
      <Authorize roles={['admin', 'manager']}>
        <Breadcrumbs>
          <Breadcrumb to={ModelHelpers.newPath(home)}>Create new</Breadcrumb>
        </Breadcrumbs>
      </Authorize>
    </Heading>
  );
}

export default PositionTableHeading;
