import { css, cx } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLElement>;

function Section({ className, children, ...props }: Props) {
  const theme = useTheme();

  const sectionClass = cx(
    css({
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing.xl,
      borderWidth: 5,
      border: `1px solid ${theme.colors['white-300']}`,
      padding: `${theme.spacing.md} 0`,
      textAlign: 'center',
      width: 450,
    }),
    className
  );

  return (
    <section {...props} className={sectionClass}>
      {children}
    </section>
  );
}

export default Section;
