import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import useTheme from '../Theme/useTheme';

function useMenuLinkStyles(className?: string, primed?: boolean) {
  const theme = useTheme();
  const style = useMemo(() => {
    const activeStyles = {
      cursor: 'pointer',
      color: theme.colors['primary-contrast'],
      backgroundColor: theme.colors.primary,
    };

    const activeClass = css(activeStyles);

    return cx(
      className,
      css({
        outline: 'none',
        border: 'none',
        padding: `${theme.spacing.sm} ${theme.spacing.md}`,
        display: 'block',
        width: '100%',
        backgroundColor: theme.colors.white,
        textAlign: 'left',
        ':hover, :active, :focus': activeStyles,
        ':last-child': {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }),
      {
        [activeClass]: primed,
      }
    );
  }, [theme, className, primed]);

  return style;
}

export default useMenuLinkStyles;
