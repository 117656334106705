import { useAuth0 } from '@auth0/auth0-react';
import { MedsocUser } from 'core';
import { useMemo } from 'react';

function useUser() {
  const context = useAuth0();
  const user = useMemo(() => new MedsocUser(context.user), [context]);
  return user;
}

export default useUser;
