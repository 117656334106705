import { IDContext, IModelEntityContext, ModelEntityContext } from '../../../../../core';
import { Batch, MUTATION_PROCESS_PAYMENT, QUERY_MEMBER_FORM } from '../../../../../model';
import { ReactNode, useContext } from 'react';
import { useMutation } from '@apollo/client';

interface BatchEntityProviderProps {
  batch: Batch;
  children?: ReactNode;
}

function BatchEntityProvider({ batch, children }: BatchEntityProviderProps) {
  const { id } = useContext(IDContext);
  const [processPayment, { loading }] = useMutation(MUTATION_PROCESS_PAYMENT);

  const value: IModelEntityContext<Batch> = {
    entity: batch,
    loading: false,
    merging: loading,
    merge: async (_, input) => {
      // Hacky injection of the memberId here since for some reason Formik won't pass the updated
      // memberId field from its hidden input
      const variables = { input: { ...input, memberId: id } };
      const result = await processPayment({
        variables, refetchQueries: [{
          query: QUERY_MEMBER_FORM,
          variables: { id },
        }],
      });
      return result.data.node;
    },
    remove: () => Promise.reject('not implemented'),
  };
  return <ModelEntityContext.Provider value={value}>{children}</ModelEntityContext.Provider>;
}

export default BatchEntityProvider;
