import { useContext } from 'react';
import Button from '../Button';
import PageContext from '../../core/PageContext';
import { useModelPage } from '../../hooks';

type NextPageProps = {};

function NextPage(_: NextPageProps) {
  const paging = useContext(PageContext);
  const { pageInfo } = useModelPage();

  const handleClick = () => {
    paging.update(state => state.set('before', undefined).set('after', pageInfo?.endCursor));
  };

  return (
    <Button onClick={handleClick} disabled={!pageInfo?.hasNextPage}>
      Next
    </Button>
  );
}

export default NextPage;
