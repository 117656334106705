import { css, cx } from '@emotion/css';
import { createElement, forwardRef, TdHTMLAttributes } from 'react';
import Skeleton from 'react-loading-skeleton';
import useTransition from '../../hooks/useTransition';
import useTheme from '../Theme/useTheme';

interface Props extends TdHTMLAttributes<HTMLTableCellElement> {
  header?: boolean;
  borderless?: boolean;
  skeleton?: boolean;
}

const Cell = forwardRef<HTMLTableCellElement, Props>(function DataTableCell(
  { header, onClick, className, borderless, skeleton, ...rest },
  ref
) {
  const theme = useTheme();
  const transition = useTransition();

  const clickableClass = css(transition, {
    cursor: 'pointer',
    userSelect: 'none',
    ':hover': {
      color: theme.colors.black,
    },
  });

  const headerClass = css({
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    '*': {
      textTransform: 'uppercase',
    },
  });

  const borderlessClass = css({
    borderColor: 'transparent',
  });

  const rootClass = cx(
    css({
      padding: `${theme.spacing.sm} ${theme.spacing.md}`,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors['black-500'],
      borderBottomStyle: 'dashed',
    }),
    className,
    {
      [clickableClass]: Boolean(onClick),
      [headerClass]: header,
      [borderlessClass]: borderless,
    }
  );

  return skeleton ? (
    <td colSpan={999} className={rootClass}>
      <Skeleton height="1rem" />
    </td>
  ) : (
    createElement(header ? 'th' : 'td', {
      ...rest,
      className: rootClass,
      onClick,
      ref,
    })
  );
});

export default Cell;
