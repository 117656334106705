import { useAuth0 } from '@auth0/auth0-react';
import { GridArea } from 'components';

function NotFoundPage() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return null;
  }

  return (
    <GridArea area="content">
      Page not found <br />
      <code>404</code>
    </GridArea>
  );
}

export default NotFoundPage;
