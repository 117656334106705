import { Record } from 'immutable';
import IPageContext from './IPageContext';

type PageStateRecordType = Omit<IPageContext, 'update'>;

const PageStateRecord = Record<PageStateRecordType>({
  after: undefined,
  before: undefined,
  direction: undefined,
  sort: undefined,
  first: 10,
  query: undefined,
});

export default PageStateRecord;
