import { IEntity } from 'data';
import BatchMethod from './Method';
import BatchType from './Type';
import { LedgerName } from '../Ledger';
import gql from 'graphql-tag';
import { MEMBER_FORM_FIELDS } from '../Member';

export const MUTATION_PROCESS_PAYMENT = gql`
  ${MEMBER_FORM_FIELDS}
  mutation ProcessPaymentForMember($input: PaymentInput!) {
    node: processPaymentForMember(input: $input) {
      ...MemberFormFields
    }
  }
`;

export interface Batch extends IEntity {
  memberId: string;
  description?: string;
  processedAt?: string;
  depositSlipNumber?: number;
  checkNumber?: number;
  creditCardLastFour?: number | string;
  type: BatchType;
  method: BatchMethod;
  transactions?: Record<LedgerName, number> | Array<{ name: LedgerName, amount: number }>;
}

export function createBatchDefaultValues(): Batch {
  return {
    memberId: '',
    method: 'credit',
    type: 'digital',
    description: '',
    processedAt: new Date().toISOString(),
    checkNumber: 0,
    creditCardLastFour: 0,
    depositSlipNumber: 0,
    transactions: {
      State: 0,
      County: 0,
      Nempac: 0,
      AdoptStudent: 0,
      Other: 0,
    },
  };
}
