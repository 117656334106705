import { css, cx } from '@emotion/css';
import Button from 'components/Button';
import FlexStack from 'components/FlexStack';
import { useTheme } from 'components/hooks';
import { useErrorHandler, useModelEntity, useMutation, useStrings, useToast } from 'hooks';
import { ReactNode, useState } from 'react';
import gql from 'graphql-tag';
import { Member, QUERY_MEMBER_FORM } from '../../model';

interface Props {
  children?: ReactNode;
  id: string;
}

function MemberEnrollmentItem({ children, id }: Props) {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const onError = useErrorHandler();
  const { entity } = useModelEntity<Member>();
  const [removed, setRemoved] = useState(false);

  const [remove, { loading: removing }] = useMutation(gql`
    mutation RemoveEnrollment($id: String!, $enrollmentId: String!) {
      node: mergeMemberEnrollments(memberId: $id, adding: [], removing: [$enrollmentId]) {
        id
      }
    }`, {
    onError,
  });

  const doRemove = async () => {
    const result = await remove({
      variables: {
        id: entity?.id,
        enrollmentId: id,
      },
      refetchQueries: [{
        query: QUERY_MEMBER_FORM,
        variables: { id: entity?.id },
      }],
    });

    if (result?.data) {
      toast(strings.saveSuccess);
      setRemoved(true);
    }
  };

  if (removed) {
    return null;
  }

  const loadingClass = css({
    opacity: 0.5,
  });

  const rootClass = cx(
    css({
      marginBottom: theme.spacing.md,
      alignItems: 'flex-start',
    }),
    {
      [loadingClass]: removing,
    },
  );

  return (
    <FlexStack className={rootClass}>
      <span>{children}</span>
      <Button link onClick={doRemove} disabled={removing}>
        Remove
      </Button>
    </FlexStack>
  );
}

export default MemberEnrollmentItem;
