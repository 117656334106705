import { Field, FieldConfig, GenericFieldHTMLAttributes, useFormikContext } from 'formik';
import { titleCase } from 'title-case';
import { css, cx } from '@emotion/css';
import { IEntity } from '../../data';
import Label from '../Label';
import ModelError from './ModelError';
import useTheme from '../Theme/useTheme';
import Input from 'components/Input';
import { ReactComponent as Lock } from 'icons/lock-solid.svg';

type Props<T extends IEntity> = {
  className?: string;
  label?: string;
  name: keyof T & string;
} & GenericFieldHTMLAttributes &
  FieldConfig<T>;

function ModelField<T extends IEntity>({ className, as = Input, label, name, ...props }: Props<T>) {
  const { errors, touched } = useFormikContext<T>();
  const theme = useTheme();

  const labelContent = label || titleCase(name.replace('_', ' '));
  const errorClass = css({
    borderColor: theme.colors.danger,
    color: theme.colors.danger,
  });

  const rootClass = cx(
    css({
      gridArea: name,
    }),
    className,
  );

  const validationClass = cx({
    [errorClass]: Boolean(touched[name] && errors[name]),
  });

  const lockClass = css({
    height: 10,
    marginRight: theme.spacing.sm,
  });

  return (
    <div className={rootClass}>
      {props.type !== 'hidden' && (
        <Label className={validationClass} htmlFor={name}>
          {props.disabled && <Lock className={lockClass} />}
          {labelContent}
        </Label>
      )}
      <Field as={as} {...props} className={validationClass} name={name} id={name} />
      <ModelError<T> field={name} />
    </div>
  );
}

export type ModelFieldProps<T extends IEntity> = Props<T>;
export default ModelField;
