import { gql } from '@apollo/client';
import { AutoCompleteQuery, AutoCompleteResult } from 'core/TaggedSearchReducer/Types';

export const memberBaseQuery: AutoCompleteQuery = {
  query: gql`
    query GetMembersByName($value: String!) {
      members(query: $value) {
        edges {
          cursor
          node {
            id
            ...MemberTableView
          }
        }
      }
    }
  `,
  convert: (data: any): AutoCompleteResult[] =>
    data?.member.map((m: any) => ({
      id: m.id,
      caption: m.display_name,
      subcaption: m.member_id,
    })),
  column: 'display_name',
};

export const positionBaseQuery: AutoCompleteQuery = {
  query: gql`
    query GetPositionByName($value: String!) {
      position(where: {name: {_ilike: $value}}) {
        id
        name
        metric
      }
    }
  `,
  convert: (data: any): AutoCompleteResult[] =>
    data?.position.map((p: any) => ({
      id: p.id,
      caption: p.name,
      subcaption: p.metric,
    })),
  column: 'name',
};

export const enrollmentQuery = (type: string): AutoCompleteQuery => {
  return {
    query: gql`
      query GetEnrollment{
        enrollment(where: {type: {_eq: ${type}}}) {
          id
          name
          short_description
        }
      }
    `,
    convert: (data: any): AutoCompleteResult[] =>
      data?.enrollment.map((e: any) => ({
        id: e.id,
        caption: e.name,
        subcaption: e.short_description,
      })),
    column: 'name',
  };
};
