import {
  Button, Cell,
  Divider,
  Fieldset,
  Flex,
  Grid,
  GridArea,
  Heading,
  Input,
  Label, Row,
  Select,
  Table,
  ThemeBreakpoints,
} from '../../components';
import { css } from '@emotion/css';
import { Set } from 'immutable';
import useTheme from '../../components/Theme/useTheme';
import { useMutation, useQuery } from '@apollo/client';
import { EnrollmentTypeQuery, QUERY_ENROLLMENT_TYPES } from '../setup/components/EnrollmentForm';
import { QUERY_ENROLLMENT_TABLE } from '../setup/components/EnrollmentTable';
import { useEffect, useRef, useState } from 'react';
import { ComparableEnrollment, Enrollment } from '../../model';
import RosterEnrollment from '../../model/Enrollment/RosterEnrollment';
import gql from 'graphql-tag';
import { useNavigate } from 'react-router';
import { useStrings, useToast } from '../../hooks';
import DataHelpers from '../../core/DataHelpers';

function RosterForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();
  const strings = useStrings();
  const [name, setName] = useState('');
  const [query, setQuery] = useState('');
  const [type, setType] = useState('');
  const [included, setIncluded] = useState('true');
  const inputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<RosterEnrollment | undefined>();
  const [roster, setRoster] = useState(Set<RosterEnrollment>([]));

  const queryEnrollmentTypes = useQuery<EnrollmentTypeQuery>(QUERY_ENROLLMENT_TYPES);
  const enrollments = useQuery(QUERY_ENROLLMENT_TABLE, {
    variables: {
      first: roster.size + 5,
      query: `(type:${type}) ${query}`,
    },
    skip: !type,
  });

  // MUTATIONS
  const [merge] = useMutation(gql`
    mutation AddRoster($input: RosterInput!) {
      node: createRoster(input: $input) {
        id
      }
    }`);
  // MUTATIONS END

  useEffect(() => {
    if (!type) {
      setQuery('Please select a type...');
    } else {
      setQuery('');
    }
  }, [type, setQuery]);

  const queriedEnrollments = Set(enrollments.data?.page.edges.map((x: Record<'node', Enrollment>) => new ComparableEnrollment(x.node)) || []);
  const options = queryEnrollmentTypes.data?.types
    .map(x => <option key={x.id} value={x.id}>{x.name}</option>);

  // Lookup map so we can render type names
  const typeMap = queryEnrollmentTypes.data?.types.reduce((map, x) => {
    map[x.id] = x.name;
    return map;
  }, {} as Record<string, string>);

  return (
    <form autoComplete='off' onSubmit={e => {
      e.preventDefault();
      setRoster(x => x.add(selected!));
      setSelected(undefined);
      setQuery('');

      inputRef.current?.focus();
    }}>
      <Heading as='h5'>

      </Heading>
      <Fieldset>
        <div>
          <Label htmlFor='roster'>Roster</Label>
          <Input autoFocus id='roster' required value={name} onChange={e => setName(e.target.value)} />
        </div>
        <Divider />
        <Grid gap={theme.spacing.md}
              className={css({ marginTop: theme.spacing.lg })}
              template={`
              "type type inclusion"
              "enrollment enrollment add"
              / 1fr 150px auto`}
              responsive={{
                [ThemeBreakpoints.lg]: `
                "type inclusion enrollment add"
                / 180px 150px 1fr auto`,
              }}>
          <GridArea area='type'>
            <Label>Type</Label>
            <Select disabled={queryEnrollmentTypes.loading} value={type}
                    onChange={e => setType(e.target.value)}>
              <option>Select a type...</option>
              {options}
            </Select>
          </GridArea>
          <GridArea area='inclusion'>
            <Label htmlFor='inclusion'>Membership</Label>
            <Select disabled={!type} id='inclusion' value={included.toString()}
                    onChange={e => setIncluded(e.target.value)}>
              <option value='true'>Included</option>
              <option value='false'>Excluded</option>
            </Select>
          </GridArea>
          <GridArea area='enrollment' className={css({ position: 'relative' })}>
            <Label>Enrollment</Label>
            <Input id='enrollment' ref={inputRef} list='enrollment-items' disabled={!type}
                   value={query}
                   onChange={e => {
                     const enrollment = DataHelpers.findDataset(e);
                     if (enrollment) {
                       setSelected(new RosterEnrollment(DataHelpers.findDataset(e), included === 'true'));
                     }
                     setQuery(e.target.value);
                   }} />
            <datalist id='enrollment-items'>
              {queriedEnrollments.size > 0 && queriedEnrollments.subtract(roster).map((dataset: any) => {
                return <option key={dataset.id} value={dataset.name} {...DataHelpers.convertToDataset(dataset)} />;
              })}
            </datalist>
          </GridArea>
          <GridArea area='add'>
            <Flex className={css({ alignItems: 'flex-end', height: '100%' })}>
              <Button disabled={!selected}>Add</Button>
            </Flex>
          </GridArea>
        </Grid>
      </Fieldset>
      <Divider />
      <Table>
        <thead>
        <Row>
          <Cell header align='left'>Type</Cell>
          <Cell header align='left'>Name</Cell>
          <Cell header>Membership</Cell>
          <Cell header align='right'>Actions</Cell>
        </Row>
        </thead>
        <tbody>
        {roster.map(x => (
          <Row key={x.id}>
            <Cell>{typeMap?.[x.type]}</Cell>
            <Cell>{x.name}</Cell>
            <Cell align='center'>{x.included.toString()}</Cell>
            <Cell align='right'>
              <Button link onClick={_ => setRoster(y => y.remove(x))}>Remove</Button>
            </Cell>
          </Row>
        ))}
        </tbody>
      </Table>
      <Flex direction='column'
            className={css({ alignItems: 'flex-end', marginTop: theme.spacing.lg })}>
        <Button c2a disabled={roster.size === 0} type='button' onClick={_ => {
          const input = {
            name,
            enrollmentIds: roster.filter(e => e.included).map(e => e.id),
            excludedEnrollmentIds: roster.filter(e => !e.included).map(e => e.id),
          };
          merge({ variables: { input } })
            .then(_ => {
              toast(strings.saveSuccess);
              navigate('..');
            })
            .catch(e => {
              console.error(e);
              toast(strings.saveFail, { context: 'danger' });
            });
        }}>Save</Button>
      </Flex>
    </form>
  );
}

export default RosterForm;
