import { css } from '@emotion/css';
import { Flex, Tag } from 'components';
import { useTheme } from 'components/hooks';
import { Enrollment } from 'model';

interface Props {
  enrollment?: Enrollment;
}

function MemberDuesEnrollment({ enrollment }: Props) {
  const theme = useTheme();

  if (!enrollment) {
    return null;
  }

  const rootClass = css({
    flexDirection: 'column',
  });

  const tagsClass = css({
    marginTop: theme.spacing.sm,
  });

  return (
    <Flex className={rootClass}>
      {enrollment.name}
      <Flex className={tagsClass}>
        <Tag>${enrollment.dues}</Tag>
      </Flex>
    </Flex>
  );
}

export default MemberDuesEnrollment;
