import { css } from '@emotion/css';
import { ReactNode } from 'react';
import Flex from './Flex';
import Heading from './Heading';
import useTheme from './Theme/useTheme';

type Props = {
  heading: string;
  title?: string;
  children?: ReactNode;
};

function DashboardMetric({ heading, title, children }: Props) {
  const theme = useTheme();
  const rootClass = css({
    alignItems: 'center',
    backgroundColor: theme.colors.secondary,
    border: `1px solid ${theme.colors['secondary-400']}`,
    borderRadius: 5,
    flexDirection: 'column',
    padding: theme.spacing.lg,
    height: '100%',
  });

  const metricClass = css({
    fontSize: '3em',
    fontWeight: 600,
    padding: theme.spacing.md,
  });

  return (
    <Flex className={rootClass}>
      <Heading as='h4' title={title}>
        {heading}
      </Heading>
      <div className={metricClass}>{children}</div>
    </Flex>
  );
}

export default DashboardMetric;
