import { DialogProvider } from 'components';
import TransportProvider from 'components/TransportProvider';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticateProvider from './components/AuthenticateProvider';
import ThemeProvider from './components/Theme/ThemeProvider';
import {
  Layout,
  AdminIndexPage,
  CallbackPage,
  LogoutPage,
  ImportIndexPage,
  MemberIndexPage,
  NotFoundPage,
  ReportIndexPage,
  RosterIndexPage,
  SetupIndexPage,
} from 'pages';

function App() {
  return (
    <ThemeProvider>
      <Toaster />
      <DialogProvider>
        <Router>
          <AuthenticateProvider>
            <TransportProvider>
              <Routes>
                <Route index element={<Navigate to='/member' />} />
                <Route path='logout' Component={LogoutPage} />
                <Route path='callback' Component={CallbackPage} />
                <Route path='*' element={<Layout />}>
                  <Route path='member/*' Component={MemberIndexPage} />
                  <Route path='reports/*' Component={ReportIndexPage} />
                  <Route path='rosters/*' Component={RosterIndexPage} />
                  <Route path='setup/*' Component={SetupIndexPage} />
                  <Route path='import/*' Component={ImportIndexPage} />
                  <Route path='admin/*' Component={AdminIndexPage} />
                  <Route path='*' Component={NotFoundPage} />
                </Route>
              </Routes>
            </TransportProvider>
          </AuthenticateProvider>
        </Router>
      </DialogProvider>
    </ThemeProvider>
  );
}

export default App;
