import { useCallback, useEffect, useMemo, useState } from 'react';

function usePopover() {
  const [reference, setReference] = useState<HTMLButtonElement | HTMLInputElement | HTMLElement | null>(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setShow(false);
          reference?.blur();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [show, setShow, reference]);

  const onClickout = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const onFocus = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const hide = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const activator = useMemo(() => ({ ref: setReference, onFocus }), [setReference, onFocus]);
  const popover = useMemo(() => ({ reference }), [reference]);
  const clickOut = useMemo(() => ({ detect: show, onClickout }), [show, onClickout]);

  return useMemo(() => {
    return {
      show,
      activator,
      popover,
      hide,
      clickOut,
    };
  }, [show, activator, popover, clickOut, hide]);
}

export default usePopover;
