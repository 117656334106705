import { Route, Routes } from 'react-router';
import RosterTable from './RosterTable';
import RosterForm from './RosterForm';
import { Fragment } from 'react';
import { GridArea, Heading } from '../../components';

function RosterIndexPage() {
  return (
    <Routes>
      <Route index element={<RosterTable />} />
      <Route path='new' element={<Fragment>
        <GridArea area='heading'>
          <Heading>Create Roster</Heading>
        </GridArea>
        <GridArea area='content'>
          <RosterForm />
        </GridArea>
      </Fragment>} />
      <Route path=':id' element={<div>Under construction</div>} />
    </Routes>
  );
}

export default RosterIndexPage;
