import { RouteIDContextProvider } from 'components';
import { NotFoundPage } from 'pages';
import { Route, Routes, useLocation } from 'react-router-dom';
import TransactionForm from './components/TransactionForm';
import TransactionTable from './components/TransactionTable';

interface Props {
}

function TransactionIndexPage(_: Props) {
  const location = useLocation();
  const url = location?.pathname;

  return (
    <Routes>
      <Route index element={<TransactionTable home={url} />}
      />
      <Route
        path=':id'
        element={
          <RouteIDContextProvider>
            <TransactionForm />
          </RouteIDContextProvider>
        }
      />
      <Route path='*' Component={NotFoundPage} />
    </Routes>
  );
}

export default TransactionIndexPage;
