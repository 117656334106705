import { Grid } from 'components';
import { ReactNode } from 'react';

const template = `
"name ."
"metric ."
/ 1fr 1fr`;

interface Props {
  children?: ReactNode;
}

function PositionFormTemplate({ children }: Props) {
  return (
    <Grid gap={14} template={template}>
      {children}
    </Grid>
  );
}

export default PositionFormTemplate;
