import qs from 'query-string';
import { diff } from 'deep-object-diff';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { IPageContext, PageContext, PageStateRecord, SearchContext } from 'core';

interface Props {
  children?: ReactNode;
}

function QueryPageContextProvider({ children }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { query } = useContext(SearchContext);
  const [state, update] = useState(PageStateRecord(_locationToState(location)));

  useEffect(() => {
    update(state => state.set('query', query).set('after', undefined).set('before', undefined));
  }, [query, update]);

  useEffect(() => {
    const parsedQuery = qs.parse(location.search, { parseNumbers: true });
    const current = state.toObject();

    if (JSON.stringify(diff(parsedQuery, current)) !== '{}') {
      const search = qs.stringify({ ...parsedQuery, ...current });
      navigate(`${location.pathname}?${search}`);
    }
  }, [navigate, location, state]);

  return (
    <PageContext.Provider value={{ ...state.toObject(), update }}>{children}</PageContext.Provider>
  );
}

function _locationToState(location: Location<unknown>): Omit<IPageContext, 'update'> {
  const parsed = qs.parse(location.search, { parseNumbers: true });
  return {
    ...parsed,
    first: typeof parsed.first === 'number' ? parsed.first : 10,
  };
}

export default QueryPageContextProvider;
