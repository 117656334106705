import { css } from '@emotion/css';
import { GridArea, Heading } from 'components';
import { SyntheticEvent, useCallback, useContext } from 'react';
import { ImportContext, ImportContextProvider, succeed, fail, loading } from './context';
import FileConfirm from './FileConfirm';
import FileErrors from './FileErrors';
import FileUploader from './FileUploader';
import FileSuccess from './FileSuccess';
import FileLoading from './FileLoading';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const form = css`
  width: 100%;

  & > div {
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    min-height: 25vh;
    justify-content: center;
    padding: 16px;
    width: 100%;
  }
`;

const FileForm = () => {
  const {
    state: { accepted },
    dispatch,
  } = useContext(ImportContext);

  const onSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!accepted || accepted.length === 0) {
        dispatch(fail('Only a single file is allowed'));
        return;
      }

      const url = new URL(`${API_ENDPOINT}/import`);
      const data = new FormData();
      data.append('file', accepted[0]);
      const options = {
        method: 'PUT',
        body: data,
      };

      dispatch(loading());

      fetch(url, options)
        .then(response => {
          if (!response.ok) {
            dispatch(fail('Failed to upload spreadsheet'));
            return;
          }
          dispatch(succeed());
        })
        .catch(() => dispatch(fail('Failed to submit form')));
    },
    [accepted, dispatch]
  );

  return (
    <form className={form} onSubmit={onSubmit}>
      <FileUploader />
      <FileLoading />
      <FileConfirm />
      <FileErrors />
      <FileSuccess />
    </form>
  );
};

const UploadPage = () => (
  <ImportContextProvider>
    <GridArea area="heading">
      <Heading>Import Data</Heading>
    </GridArea>
    <GridArea area="content">
      <FileForm />
    </GridArea>
  </ImportContextProvider>
);

export default UploadPage;
