import { css, cx } from '@emotion/css';
import { HTMLAttributes } from 'react';
import useTheme from '../Theme/useTheme';

type Props = {
  active?: boolean;
} & HTMLAttributes<HTMLElement>;

function Header({ children, className, active, ...props }: Props) {
  const theme = useTheme();

  const activeClass = css({
    opacity: 1,
  });

  const classes = cx(
    className,
    css({
      fontSize: 12,
      fontWeight: 600,
      marginTop: theme.spacing.md,
      padding: theme.spacing.sm,
      opacity: 0.45,
      textTransform: 'uppercase',
    }),
    {
      [activeClass]: active,
    }
  );

  return (
    <header {...props} className={classes}>
      {children}
    </header>
  );
}

export default Header;
