import { css, cx } from '@emotion/css';
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

function Text({ children, className, ...props }: Props) {
  const classes = cx(
    className,
    css({
      margin: 0,
      lineHeight: '1.5rem',
    })
  );

  return (
    <p {...props} className={classes}>
      {children}
    </p>
  );
}

export default Text;
