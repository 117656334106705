import { ButtonHTMLAttributes, useEffect, useRef } from 'react';
import useMenuLinkStyles from './useMenuLinkStyle';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  primed?: boolean;
}

function Button({ className, children, primed, ...props }: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const styles = useMenuLinkStyles(className, primed);

  useEffect(() => {
    if (primed) {
      const doKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
          ref.current?.click();
        }
      };

      window.addEventListener('keydown', doKeyDown);
      return () => window.removeEventListener('keydown', doKeyDown);
    }
  }, [primed]);

  return (
    <button {...props} ref={ref} className={styles}>
      {children}
    </button>
  );
}

export default Button;
