import { css, cx } from '@emotion/css';
import { HTMLAttributes } from 'react';
import useTheme from '../Theme/useTheme';

type Props = HTMLAttributes<HTMLDivElement>;

function Content({ children, className, ...props }: Props) {
  const theme = useTheme();
  const classes = cx(
    css({
      padding: theme.spacing.sm,
      ':not(:last-child)': {
        borderBottom: `1px solid ${theme.colors['white-300']}`,
      },
      '> :last-child': {
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
      },
    }),
    className
  );

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}

export default Content;
