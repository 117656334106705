import { css, keyframes } from '@emotion/css';
import { ReactComponent as Spinner } from 'icons/spinner-third-duotone.svg';
import Flex from './Flex';

function Loading() {
  const rootClass = css({
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  
  to {
    transform: rotate(360deg)
  }`;

  const spinnerClass = css({
    animation: `${rotate} 1s ease infinite`,
  });

  return (
    <Flex className={rootClass}>
      <Spinner height={30} className={spinnerClass} />
    </Flex>
  );
}

export default Loading;
