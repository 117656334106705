import { ModelTable } from 'components';
import { ModelColumnInfo } from 'data';
import { Position } from 'model';

interface Props {
  home: string;
}

function PositionTable({ home }: Props) {
  const columns: Array<ModelColumnInfo<Position>> = [
    {
      column: 'name',
    },
    {
      column: 'metric',
    },
  ];

  return <ModelTable home={home} columns={columns} />;
}

export default PositionTable;
