import { Button, Fieldset, Flex, Grid, GridArea, Heading, Input, Label, Loading } from '../../../../components';
import { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Record } from 'immutable';
import { Member, MemberEducation } from '../../../../model';
import { ModelHelpers } from '../../../../shared';
import { useModelEntity, useStrings, useToast } from '../../../../hooks';
import useCurrentYear from '../../../../hooks/useCurrentYear';
import { css } from '@emotion/css';
import { useTheme } from '../../../../components/Theme/hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface MemberEducationInput {
  internshipAt?: string;
  licensedYear?: number;
  meNumber?: string;
  residencyAt?: string;
  school?: string;
}

const MemberEducationInputRecord = Record<MemberEducationInput>({
  internshipAt: undefined,
  licensedYear: undefined,
  meNumber: undefined,
  residencyAt: undefined,
  school: undefined,
});

function initRecord(data: MemberEducation = {}): MemberEducationInput {
  return {
    internshipAt: ModelHelpers.dateFromTimestampZ(data?.internshipAt),
    residencyAt: ModelHelpers.dateFromTimestampZ(data?.residencyAt),
    licensedYear: data?.licensedYear || 1900,
    meNumber: data?.meNumber || '',
    school: data?.school?.name || '',
  };
}

interface MemberEducationFormProps {
}

function MemberEducationForm(_: MemberEducationFormProps) {
  const { entity, loading } = useModelEntity<Member>();
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const year = useCurrentYear();
  const [state, setState] = useState(MemberEducationInputRecord(initRecord(entity?.education)));

  useEffect(() => setState(x => x.merge(initRecord(entity?.education))), [entity, setState]);

  const field = useCallback((
    key: keyof MemberEducationInput,
    label: string,
    attrs: InputHTMLAttributes<HTMLInputElement> = {}) => {
    return (
      <GridArea area={key}>
        <Label htmlFor={key}>{label}</Label>
        <Input {...attrs} id={key} value={state.get(key)}
               onChange={e => setState(x => x.set(key, e.target.value))} />
      </GridArea>
    );
  }, [state, setState]);

  const [update, { loading: updating }] = useMutation(gql`
    mutation MergeMember($id: String!, $input: EducationInput!) {
      node: mergeMemberEducation(input: $input, memberId: $id) {
        id
      }
    }`);

  if (loading) {
    return <Loading />;
  }

  return (
    <form className={css({ marginTop: theme.spacing.xl })} onSubmit={e => {
      e.preventDefault();
      const input = state
        .update('residencyAt', y => ModelHelpers.timestampFromDate(y))
        .update('internshipAt', y => ModelHelpers.timestampFromDate(y))
        // @ts-ignore
        // eslint-disable-next-line eqeqeq
        .update('licensedYear', y => y != '1900' ? parseInt(y) : undefined)
        .toJS();

      console.log(input);

      update({ variables: { input, id: entity?.id } })
        .then(_ => toast(strings.saveSuccess))
        .catch(e => toast(e.message, { context: 'danger' }));
    }}>
      <Heading as={'h3'}>Education</Heading>
      <Fieldset disabled={updating}>
        <Grid gap={theme.spacing.md} template={`
        "meNumber licensedYear"
        "internshipAt internshipAt"
        "residencyAt residencyAt"
        "school school"
        / 1fr 1fr`}>
          {field('meNumber', 'ME Number')}
          {field('licensedYear', 'Licensed Year', {
            type: 'number', min: 1900, step: 1, max: year,
          })}
          {field('internshipAt', 'Internship Date', { type: 'date' })}
          {field('residencyAt', 'Residency Date', { type: 'date' })}
          {field('school', 'Medical School')}
        </Grid>
        <Flex direction={'column'} className={css({
          alignItems: 'flex-end',
          marginTop: theme.spacing.md,
        })}>
          <Button>Save</Button>
        </Flex>
      </Fieldset>
    </form>
  );
}

export default MemberEducationForm;
