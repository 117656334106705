import { css } from '@emotion/css';
import { Flex, FormatDate, Loading, TransportError } from 'components';
import { useQuery } from 'hooks';
import { QUERY_MEMBER_FORM } from '../../../model';

interface Props {
  id: string;
}

function MemberLastPayment(props: Props) {
  const result = useQuery(QUERY_MEMBER_FORM, {
    variables: props,
  });

  if (result.loading) {
    return <Loading />;
  }

  const rootClass = css({
    alignItems: 'center',
  });

  const value = result.data?.node.lastPaymentDate;

  return (
    <Flex className={rootClass}>
      <TransportError error={result.error} />
      <FormatDate value={value} format={'MMM yyyy'} />
    </Flex>
  );
}

export default MemberLastPayment;
