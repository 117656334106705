import { Grid, ThemeBreakpoints } from '../../../../components';
import { useTheme } from '../../../../components/Theme/hooks';
import { useModelEntity } from '../../../../hooks';
import { Member } from '../../../../model';
import MemberContactForm from './MemberContactForm';
import { css } from '@emotion/css';

function MemberContacts() {
  const { spacing } = useTheme();
  const { entity, loading } = useModelEntity<Member>();

  const home = entity?.contacts?.find(x => x.type === 'Home');
  const work = entity?.contacts?.find(x => x.type === 'Work');

  return (
    <Grid responsive={{
      [ThemeBreakpoints.md]: `auto / 1fr`,
      [ThemeBreakpoints.lg]: `auto / 1fr 1fr`,
    }} gap={spacing.md} className={css({ marginTop: spacing.xl })}>
      <MemberContactForm type='Home' data={home} loading={loading} />
      <MemberContactForm type='Work' data={work} loading={loading} />
    </Grid>
  );
}

export default MemberContacts;
