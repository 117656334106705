import { css, CSSInterpolation, CSSObject, cx } from '@emotion/css';
import { useMemo } from 'react';
import useTheme from '../components/Theme/useTheme';
import useTransition from './useTransition';

function useInputStyle(className?: string, imperativeFocus?: boolean, ...args: CSSInterpolation[]) {
  const theme = useTheme();
  const transition = useTransition('background-color');

  const styles = useMemo(() => {
    const activeStyles: CSSObject = {
      backgroundColor: theme.colors['white-300'],
    };

    const activeClass = css(activeStyles);

    return cx(
      css(
        {
          ...transition,
          borderColor: 'transparent',
          borderRadius: 5,
          borderStyle: 'solid',
          fontSize: 14,
          fontFamily: theme.fontFamily.default,
          flexShrink: 1,
          padding: `${theme.spacing.sm} ${theme.spacing.md}`,
          backgroundColor: theme.colors.background,
          display: 'inline-block',
          ':active': activeStyles,
          ':focus': activeStyles,
          ':disabled': {
            opacity: 0.75,
          },
        },
        args
      ),
      {
        [activeClass]: imperativeFocus,
      },
      className
    );
    // imperativeFocus should not be in dep array
    // it causes infinite re-renders.
    // eslint-disable-next-line
  }, [theme, className, args, transition]);

  return styles;
}

export default useInputStyle;
