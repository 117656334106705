import { Breadcrumb, Breadcrumbs, GridArea, Heading } from 'components';
import MemberCrumb from 'pages/member/components/MemberCrumb';
import MemberBatchForm from './components/MemberBatchForm';
import { Fragment } from 'react';

interface Props {
}

function MemberNewPaymentPage(_: Props) {
  return (
    <Fragment>
      <GridArea area='heading'>
        <Heading>
          Process Payment
          <Breadcrumbs>
            <Breadcrumb to='/member'>Members</Breadcrumb>
            <Breadcrumb to='../..'>
              <MemberCrumb />
            </Breadcrumb>
            <Breadcrumb>New Payment</Breadcrumb>
          </Breadcrumbs>
        </Heading>
      </GridArea>
      <GridArea area='content'>
        <MemberBatchForm />
      </GridArea>
    </Fragment>
  );
}

export default MemberNewPaymentPage;
