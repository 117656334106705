import { css, cx } from '@emotion/css';
import { LiHTMLAttributes } from 'react';
import useTheme from '../Theme/useTheme';

type Props = {
  active?: boolean;
} & LiHTMLAttributes<HTMLLIElement>;

function Item({ children, className, active, ...props }: Props) {
  const theme = useTheme();

  const activeClass = css({
    backgroundColor: theme.colors.background,
  });

  const classes = cx(
    css({
      borderRadius: 5,
    }),
    {
      [activeClass]: active,
    },
    className
  );

  return (
    <li {...props} className={classes}>
      {children}
    </li>
  );
}

export default Item;
