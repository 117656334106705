export enum ThemeBreakpoints {
  sm = '@media (min-width: 400px)',
  md = '@media (min-width: 900px)',
  lg = '@media (min-width: 1400px)',
  xl = '@media (min-width: 1700px)',
}

export type ThemeSizes = 'sm' | 'md' | 'lg' | 'xl';

export type ThemeColors = 'primary' | 'secondary' | 'white' | 'black';

export type ThemeFonts = 'default' | 'mono';

export type ShadowElevations = 1 | 2 | 3 | 4 | 6 | 8 | 12 | 16 | 24;

interface Theme {
  colors: Record<ThemeColors, string> & {
    background: string;

    'primary-300': string;
    'primary-400': string;
    'primary-contrast': string;

    'secondary-300': string;
    'secondary-400': string;

    'white-300': string;
    'white-400': string;
    'white-500': string;

    'black-300': string;
    'black-400': string;
    'black-500': string;

    danger: string;
    'danger-white': string;

    warning: string;
    'warning-white': string;
  };
  shadows: Record<ShadowElevations, string>;
  mode: 'light' | 'dark';
  spacing: Record<ThemeSizes, string>;
  fontFamily: Record<ThemeFonts, string>;
}

export default Theme;
