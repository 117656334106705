import { css } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { useMemo } from 'react';

function useDangerBorderStyle() {
  const theme = useTheme();
  const className = useMemo(
    () =>
      css({
        borderColor: theme.colors.danger,
      }),
    [theme]
  );

  return className;
}

export default useDangerBorderStyle;
