import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { ReactNode, useState } from 'react';
import { css } from '@emotion/css';

type Props = {
  children?: ReactNode;
  reference: HTMLElement | null;
  placement?: Placement;
};

function Popover({ children, reference, placement }: Props) {
  const [content, setContent] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(reference, content, {
    placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10], // TODO: pull media sensitive offsets from theme
        },
      },
    ],
  });

  const classes = css({
    zIndex: 1090,
  });

  return (
    <div {...attributes.popper} ref={setContent} className={classes} style={styles.popper}>
      {children}
    </div>
  );
}

export default Popover;
