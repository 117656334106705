import { ApolloClient, InMemoryCache, OnDataOptions, useMutation, useQuery, useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Fieldset, Flex, Grid, GridArea, Input, Label, ThemeBreakpoints } from '../../components';
import { useToast } from '../../hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Record } from 'immutable';
import { useTheme } from '../../components/Theme/hooks';
import { css } from '@emotion/css';
import DataHelpers from '../../core/DataHelpers';

type StatementEvent = {
  source: string,
  message: string,
  currentStatementPage: number,
  statementPageCount: number,
}

const SUBSCRIBE_STATEMENT_EVENTS = gql`
  subscription OnEvents {
    events {
      source
      ...on RosterStatementEvent {
        currentStatementPage
        message
        statementPageCount
      }
    }
  }`;

type RosterFields = {
  id: string
  name: string
  createdAt: string
}
const QUERY_ROSTER_PAGE = gql`query Rosters($query: String!) {
  rosters(query: $query, first: 20) {
    edges {
      node {
        id
        ... on Roster {
          name
          createdAt
        }
      }
    }
  }
}`;

const StatementEventRecord = Record<StatementEvent>({
  source: '',
  message: '',
  currentStatementPage: 0,
  statementPageCount: 0,
});

function StatementNew() {
  const toast = useToast();
  const theme = useTheme();
  const [query, setQuery] = useState('');
  const [token, setToken] = useState('');
  const [state, setState] = useState(StatementEventRecord());
  const [selectedRoster, setSelectedRoster] = useState<RosterFields | undefined>();
  const auth0 = useAuth0();

  const client = useMemo(() => {
    if (!token) {
      return undefined;
    }

    const link = new GraphQLWsLink(createClient({
      url: process.env.REACT_APP_WSS_GRAPH_ENDPOINT!,
      connectionParams: {
        authToken: token,
      },
    }));

    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }, [token]);

  const onData = useCallback((options: OnDataOptions) => {
    setState(x => x.merge(options.data.data.events));
  }, [setState]);

  useEffect(() => {
    auth0.getAccessTokenSilently().then(setToken);
  }, [auth0, setToken]);

  useSubscription(SUBSCRIBE_STATEMENT_EVENTS, { skip: !client, client, onData });

  const { data } = useQuery(QUERY_ROSTER_PAGE, {
    variables: { query },
  });

  const [run, { loading }] = useMutation(gql`mutation RunStatements($id: String!) {
    guid: printRosterStatements(rosterReportId: $id)
  }`);

  const enrollments = data?.rosters.edges.map((x: any) =>
    <option key={x.node.id} value={x.node.name} {...DataHelpers.convertToDataset(x.node)} />);

  return (
    <form autoComplete={'off'} onSubmit={e => {
      e.preventDefault();
      if (!selectedRoster) {
        return;
      }

      run({ variables: { id: selectedRoster.id } })
        .then(e => {
          toast(`Statement run ${e.data.guid} underway!`);
          setSelectedRoster(undefined);
          setQuery('');
        })
        .catch(e => {
          console.error(e);
          toast('Failed to begin statement run', { context: 'danger' });
        });
    }}>
      <p>
        This will generate a set of statements for any member belonging to the given roster who
        has a non-zero balance. It will <b>NOT</b> perform an accounting rollup or create annual
        billing.
      </p>
      <Fieldset>
        <Grid gap={theme.spacing.md} template={`
        "roster"
        "create"
        "info"
        / 1fr auto`}
              responsive={{
                [ThemeBreakpoints.lg]: `
          "roster create"
          "info info"
          / 1fr auto`,
              }}>
          <GridArea area='roster'>
            <Label htmlFor='roster'>Roster</Label>
            <Input id='roster' list='enrollments' required value={query} onChange={e => {
              setQuery(e.target.value);
              setSelectedRoster(DataHelpers.findDataset(e));
            }} />
            <datalist id='enrollments'>
              {enrollments}
            </datalist>
          </GridArea>
          <GridArea area='create'>
            <Flex className={css({ alignItems: 'flex-end', height: '100%' })}>
              <Button disabled={loading || state.statementPageCount > state.currentStatementPage || !selectedRoster}>
                Create statements
              </Button>
            </Flex>
          </GridArea>
          <GridArea area='info'>
            {state.statementPageCount > 0 && (
              <Flex direction='column' className={css({ alignItems: 'center' })}>
                Statement Generation in progress <br />
                {state.message} <br />
                <progress title={`${state.currentStatementPage} / ${state.statementPageCount}`}
                          value={state.currentStatementPage / state.statementPageCount} />
              </Flex>
            )}
          </GridArea>
        </Grid>
      </Fieldset>
    </form>
  );
}

export default StatementNew;
