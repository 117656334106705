import { ModelSchema } from 'data';
import { number, object, string } from 'yup';
import Position from './Position';

function useSchema(): ModelSchema<Position> {
  return object({
    name: string().required(),
    metric: number().default(100),
  });
}

export default useSchema;
