import { Button, Fieldset, Flex, Grid, GridArea, Input, Label, Select } from 'components';
import { EnrollmentType } from 'model';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Loading } from '../../../components';
import { Record } from 'immutable';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IDContext } from '../../../core';
import { useStrings, useToast } from '../../../hooks';
import { ReactComponent as Lock } from '../../../icons/lock-solid.svg';
import { css } from '@emotion/css';
import useTheme from '../../../components/Theme/useTheme';
import { useNavigate } from 'react-router';

interface Props {
  type: EnrollmentType;
  hasDues?: boolean;
  waitForLoad?: boolean;
}

interface EnrollmentInput {
  dues?: number;
  name: string;
  type: string;
}

const EnrollmentInputRecord = Record<EnrollmentInput>({
  dues: undefined,
  name: '',
  type: '',
});

export const QUERY_ENROLLMENT_BY_ID = gql`
  query GetEnrollmentById($id: String!) {
    node(id: $id) {
      id
      ...on Enrollment {
        dues
        name
        type
      }
    }
  }
`;

export type EnrollmentTypeFields = { id: string, name: string }
export type EnrollmentTypeQuery = { types: EnrollmentTypeFields[] }
export const QUERY_ENROLLMENT_TYPES = gql`
  query GetAllEnrollmentTypes {
    types: enrollmentTypes {
      id
      name
    }
  }
`;

function EnrollmentForm({ type, hasDues }: Props) {
  const { id } = useContext(IDContext);
  const { saveSuccess, saveFail } = useStrings();
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();

  type Enrollment = {
    id: string,
    name: string
    type: string
    dues?: number
  }
  type QueryResult = { node: Enrollment }
  const { data, loading } = useQuery<QueryResult>(QUERY_ENROLLMENT_BY_ID,
    { skip: !id, variables: { id } });

  const { data: typesData, loading: typesLoading } = useQuery<EnrollmentTypeQuery>(
    QUERY_ENROLLMENT_TYPES);

  const initInput = useCallback((enrollment?: Partial<Enrollment>): EnrollmentInput => {
    const input: EnrollmentInput = {
      dues: enrollment?.dues,
      name: enrollment?.name || '',
      type: enrollment?.type || '',
    };

    const currentType = typesData?.types?.find((x: any) => x.name === type);
    if (currentType) {
      input.type = currentType.id;
    }

    return input;
  }, [type, typesData]);

  const [state, setState] = useState(EnrollmentInputRecord(initInput({ type })));

  const [merge, mergeEnrollment] = useMutation(gql`
    mutation MergeEnrollment($input: EnrollmentInput!, $id: String) {
      node: mergeEnrollment(id: $id, input: $input) {
        id
      }
    }
  `, {
    refetchQueries: [{
      query: QUERY_ENROLLMENT_BY_ID,
      variables: { id },
    }],
  });

  useEffect(() => {
    setState(x => x.merge(initInput(data?.node)));
  }, [setState, initInput, data]);

  if (typesLoading || loading) {
    return <Loading />;
  }

  const options = typesData?.types.map((x: EnrollmentTypeFields) =>
    <option key={x.id} value={x.id}>{x.name}</option>);

  return (
    <form onSubmit={e => {
      e.preventDefault();
      merge({ variables: { input: state.toJS(), id } })
        .then(result => {
          toast(saveSuccess);
          navigate('../' + result.data.node.id);
        })
        .catch(e => {
          console.error(e);
          toast(saveFail, { context: 'danger' });
        });
    }}>
      <Fieldset disabled={mergeEnrollment.loading || typesLoading} key={state.get('type')}>
        <Grid gap={theme.spacing.md} template={`
        "name type"
        ". dues"
        / 1fr auto`}>
          <GridArea area='type'>
            <Label htmlFor='type'>
              <Lock className={css({
                height: 10,
                marginRight: theme.spacing.sm,
              })} /> Type
            </Label>
            <Select defaultValue={state.get('type')} aria-readonly disabled id='type'>
              {options}
            </Select>
          </GridArea>
          <GridArea area='name'>
            <Label htmlFor='name'>Name</Label>
            <Input id='name' autoFocus value={state.get('name')}
                   onChange={e => setState(x => x.set('name', e.target.value))} />
          </GridArea>
          {hasDues && (
            <GridArea area='dues'>
              <Label htmlFor='dues'>Dues</Label>
              <Input type='number' step={0.01} min={0} value={state.get('dues')}
                     onChange={e => setState(x => x.set('dues', parseFloat(e.target.value)))} />
            </GridArea>
          )}
        </Grid>
        <Flex direction={'column'}
              className={css({ alignItems: 'flex-end', marginTop: theme.spacing.lg })}>
          <Button>Save</Button>
        </Flex>
      </Fieldset>
    </form>
  );
}

export default EnrollmentForm;
