import { HTMLAttributes } from 'react';
import { cx, css } from '@emotion/css';

type Props = HTMLAttributes<HTMLUListElement>;

function List({ className, children, ...props }: Props) {
  const classes = cx(
    className,
    css({
      listStyle: 'none',
      padding: 0,
    })
  );
  return <ul className={classes}>{children}</ul>;
}

export default List;
