type SetFilesAction = {
  type: 'SET FILES';
  accepted: File[];
  rejected: File[];
};

export const setFiles = (accepted: File[], rejected: File[]): SetFilesAction => ({
  type: 'SET FILES',
  accepted,
  rejected,
});

type LoadingAction = {
  type: 'LOADING';
};

export const loading = (): LoadingAction => ({
  type: 'LOADING',
});

type SucceedAction = {
  type: 'SUCCEED';
};

export const succeed = (): SucceedAction => ({
  type: 'SUCCEED',
});

type FailAction = {
  type: 'FAIL';
  error: string;
};

export const fail = (error: string): FailAction => ({
  type: 'FAIL',
  error,
});

type ResetAction = {
  type: 'RESET';
};

export const reset = (): ResetAction => ({
  type: 'RESET',
});

// Combine all actions into a single type
export type ImportAction =
  | SetFilesAction
  | LoadingAction
  | SucceedAction
  | FailAction
  | ResetAction;
