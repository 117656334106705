import { TaggedSearchState } from 'core/TaggedSearchReducer/Types';
import { init, taggedSearchReducer } from 'core/TaggedSearchReducer/index';
import { PropsWithChildren, useReducer } from 'react';
import TaggedSearchContext from '../../core/TaggedSearchContext';
import { decodeState } from 'core/TaggedStateEncoder';
import { useQuery } from 'hooks/useQuery';

const TaggedSearchContextProvider = ({
  children,
  ...initTaggedState
}: PropsWithChildren<TaggedSearchState>) => {
  let query = useQuery();
  const s = query.get('s');
  const initialState = s ? decodeState(s) : initTaggedState;
  const [state, dispatch] = useReducer(taggedSearchReducer, initialState, init);

  return (
    <TaggedSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </TaggedSearchContext.Provider>
  );
};

export default TaggedSearchContextProvider;
