import { GridArea, Heading, ModelEntityProvider } from 'components';
import { MemberDocuments } from 'schema';
import MemberForm from './components/MemberForm';

interface Props {
  home: string;
}

function NewMemberPage({ home }: Props) {
  return (
    <ModelEntityProvider documents={MemberDocuments.entity}>
      <GridArea area='heading'>
        <Heading>New Member</Heading>
      </GridArea>
      <GridArea area='content'>
        <MemberForm home={home} waitForLoad={false} />
      </GridArea>
    </ModelEntityProvider>
  );
}

export default NewMemberPage;
