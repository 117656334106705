import { Authorize, Breadcrumb, Breadcrumbs, Heading } from 'components';
import { ReactNode } from 'react';
import { ModelHelpers } from 'shared';

interface Props {
  children?: ReactNode;
  home: string;
}

function EnrollmentTableHeading({ home, children }: Props) {
  return (
    <Heading>
      {children}
      <Authorize roles={['admin', 'manager']}>
        <Breadcrumbs>
          <Breadcrumb to={ModelHelpers.newPath(home)}>Create new</Breadcrumb>
        </Breadcrumbs>
      </Authorize>
    </Heading>
  );
}

export default EnrollmentTableHeading;
