import page from './page.graphql';
import id from './id.graphql';
import merge from './merge.graphql';
import remove from './remove.graphql';

import balance from './dashboard/balance.graphql';
import lastPayment from './dashboard/lastPayment.graphql';
import currentNempacDonation from './dashboard/currentNempacDonation.graphql';

export const TransactionDocuments = {
  list: {
    page,
  },
  entity: {
    id,
    merge,
    remove,
  },
  dashboards: {
    balance,
    lastPayment,
    currentNempacDonation,
  },
};
