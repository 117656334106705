import { css, cx } from '@emotion/css';
import { AnchorHTMLAttributes, HTMLAttributes, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import useTheme from '../Theme/useTheme';

type Props = {
  to?: string;
} & HTMLAttributes<HTMLTableRowElement> & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target'>

function Row({ to, onClick, role, className, target = '_self', ...props }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick: MouseEventHandler<HTMLTableRowElement> = e => {
    onClick?.(e);

    if (to && target === '_self') {
      navigate(to);
    } else if (to) {
      window.open(to, target);
    }
  };

  const clickableClass = css({
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors['white-300'],
    },
  });

  const rootClass = cx(
    {
      [clickableClass]: Boolean(to),
    },
    className,
  );

  return (
    <tr
      {...props}
      role={role || to ? 'button' : 'row'}
      className={rootClass}
      onClick={handleClick}
    />
  );
}

export default Row;
