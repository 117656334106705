import { css } from '@emotion/css';
import { ReactNode, ReactNodeArray } from 'react';
import { List } from 'immutable';

import Flex from 'components/Flex';

import Divider from './Divider';

interface Props {
  children: ReactNodeArray | ReactNode;
}

function Breadcrumbs({ children }: Props) {
  const rootClass = css({
    alignItems: 'center',
    fontSize: '1rem',
  });

  const crumbs = Array.isArray(children) ? children : [children];
  const dividers = List(Array(crumbs.length)
    .fill(null)
    .map((_, i) => <Divider key={`divider-${i}`} />));
  const items = List(crumbs).interleave(dividers).butLast();

  return <Flex className={rootClass}>{items}</Flex>;
}

export default Breadcrumbs;
