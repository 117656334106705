import { css } from '@emotion/css';
import Notification from './Notification';
import { Button, UnreadBullet, Clickout, Popover, Menu } from 'components';
import { useAppNavIconStyle, useModelPage, usePopover } from 'hooks';
import { ReactComponent as BellIcon } from 'icons/bell-light.svg';
import { UserNotification } from 'model';

function Notifications() {
  const { show, activator, clickOut, popover } = usePopover();
  const { edges, pageInfo } = useModelPage<UserNotification>();
  const iconClass = useAppNavIconStyle();

  const rootClass = css({
    position: 'relative',
  });

  const notifications = edges?.map(entity => {
    return <Notification key={entity.node.id} entity={entity.node} />;
  });

  const heading = 'Notifications' + (pageInfo?.totalCount ? ` · ${pageInfo?.totalCount}` : '');

  return (
    <div className={rootClass}>
      <Button link {...activator}>
        <UnreadBullet show={(pageInfo?.totalCount || 0) > 0} />
        <BellIcon className={iconClass} width='20' />
      </Button>
      {show && (
        <Clickout {...clickOut}>
          <Popover {...popover} placement='bottom-end'>
            <Menu heading={heading}>{notifications}</Menu>
          </Popover>
        </Clickout>
      )}
    </div>
  );
}

export default Notifications;
