import { enrollmentQuery } from 'core/AutoComplete/AutoCompleteQueries';
import { NotFoundPage } from 'pages';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import EnrollmentIndexPage from '../components/EnrollmentIndexPage';

function DoctorIndexPage() {
  const location = useLocation();
  const url = location?.pathname;

  return (
    <Routes>
      <Route index element={<Navigate to='/setup/doctor/group' />} />
      <Route
        path='group/*'
        element={
          <EnrollmentIndexPage
            type='DoctorGroup'
            name='Doctor Group'
            baseURL={url}
            baseQuery={enrollmentQuery('group')}
          />
        }
      />
      <Route
        path='specialty/*'
        element={
          <EnrollmentIndexPage
            type='DoctorSpecialty'
            name='Doctor Specialty'
            baseURL={url}
            baseQuery={enrollmentQuery('specialty')}
          />
        }
      />
      <Route
        path='type/*'
        element={
          <EnrollmentIndexPage
            type='DoctorType'
            name='Doctor Type'
            baseURL={url}
            baseQuery={enrollmentQuery('type')}
          />
        }
      />
      <Route
        path='certification/*'
        element={
          <EnrollmentIndexPage
            type='BoardCertification'
            name='Board Certification'
            baseURL={url}
            baseQuery={enrollmentQuery('certification')}
          />
        }
      />
      <Route path='*' Component={NotFoundPage} />
    </Routes>
  );
}

export default DoctorIndexPage;
