import { css, cx } from '@emotion/css';
import { FieldsetHTMLAttributes, ReactNode } from 'react';
import UIContext from '../shared/UIContext';
import useTheme from './Theme/useTheme';

type Props = {
  children?: ReactNode;
  context?: UIContext;
} & FieldsetHTMLAttributes<HTMLFieldSetElement>;

function Fieldset({ children, className, context, ...props }: Props) {
  const theme = useTheme();

  let contextColor;
  switch (context) {
    case 'danger':
      contextColor = theme.colors.danger;
      break;
    default:
      contextColor = theme.colors['white-300'];
  }

  const classes = cx(
    css({
      backgroundColor: theme.colors.white,
      borderColor: contextColor,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 5,
      margin: 0,
      padding: theme.spacing.md,
    }),
    className
  );

  return (
    <fieldset className={classes} {...props}>
      {children}
    </fieldset>
  );
}

export default Fieldset;
