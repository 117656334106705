import { ReactNode, useState } from 'react';

import { ISearchContext, SearchContext } from 'core';
import { EnrollmentType } from 'model';

interface Props {
  children?: ReactNode;
  type: EnrollmentType;
}

function EnrollmentSearchContextProvider({ children }: Props) {
  const [query, setQuery] = useState('');
  const value: ISearchContext = {
    query,
    set: setQuery,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default EnrollmentSearchContextProvider;
