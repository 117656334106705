import { IDContext, IModelEntityContext, ModelEntityContext } from 'core';
import { IEntity } from 'data';
import { DocumentNode } from 'graphql';
import { useErrorHandler, useMutation, useQuery } from 'hooks';
import { ReactNode, useContext } from 'react';

interface Props {
  documents: Record<'id' | 'merge' | 'remove', DocumentNode>;
  children?: ReactNode;
}

function ModelEntityProvider<T extends IEntity>({ children, documents }: Props) {
  const context = useContext(IDContext);
  const onError = useErrorHandler();

  const entity = useQuery(documents.id, {
    variables: context,
  });

  const [MergeEntity, { loading: merging }] = useMutation(documents.merge, { onError });
  const [RemoveEntity] = useMutation(documents.remove, { onError });

  const value: IModelEntityContext<T> = {
    loading: entity.loading,
    merging,
    entity: entity.data?.node,

    async merge(id, set) {
      const result = await MergeEntity({
        variables: {
          id,
          set,
        },
        refetchQueries: 'all',
      });

      return result?.data?.node;
    },

    async remove(id) {
      const result = await RemoveEntity({
        variables: { id },
      });

      return result?.data?.node;
    },
  };

  return <ModelEntityContext.Provider value={value}>{children}</ModelEntityContext.Provider>;
}

export default ModelEntityProvider;
