import { HTMLAttributes } from 'react';
import { css, CSSInterpolation, cx } from '@emotion/css';
import { ThemeBreakpoints } from './Theme';
import { Property } from 'csstype';

type Props = {
  template?: string;
  gap?: Property.Gap<string | number>;
  responsive?: Partial<Record<ThemeBreakpoints, string>>;
} & HTMLAttributes<HTMLDivElement>;

function Grid({ className, template, responsive, gap, ...props }: Props) {
  const responsiveTemplate =
    typeof responsive !== 'undefined'
      ? Object.keys(responsive).reduce(
          (css: Partial<Record<string, CSSInterpolation>>, bp: string) => {
            css[bp] = {
              gridTemplate: responsive[bp as ThemeBreakpoints],
            };
            return css;
          },
          {}
        )
      : {
          gridTemplate: template,
        };

  const classes = cx(
    className,
    css(
      {
        gap,
        gridTemplate: template,
        display: 'grid',
        width: '100%',
      },
      responsiveTemplate
    )
  );

  return <div {...props} className={classes} />;
}

export type GridProps = Props;
export default Grid;
