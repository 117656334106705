import { css, cx } from '@emotion/css';
import { HTMLAttributes } from 'react';
import { Property } from 'csstype';

type Props = {
  direction?: Property.FlexDirection
} & HTMLAttributes<HTMLDivElement>;

function Flex({ className, direction, children, ...props }: Props) {
  const rootClass = cx(
    css({
      display: 'flex',
      flexDirection: direction || 'row',
    }),
    className,
  );
  return (
    <div {...props} className={rootClass}>
      {children}
    </div>
  );
}

export default Flex;
