import { MemberContact, QUERY_MEMBER_FORM } from '../../../../model';
import {
  Button,
  Fieldset,
  Flex, FlexStack,
  Grid,
  GridArea,
  Heading,
  Input,
  Label,
  Loading,
  ThemeBreakpoints,
} from '../../../../components';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Record } from 'immutable';
import { css } from '@emotion/css';
import { useTheme } from '../../../../components/Theme/hooks';
import { useModelEntity, useStrings, useToast } from '../../../../hooks';

interface MemberContactInput {
  attentionOf?: string;
  city?: string;
  email?: string;
  fax?: string;
  isPrimary: boolean;
  phone?: string;
  state?: string;
  street?: string;
  type: string;
  zip?: string;
}

function flattenData(type: 'Home' | 'Work', data?: MemberContact): MemberContactInput {
  return {
    street: data?.address.street || '',
    attentionOf: data?.address.attentionOf || '',
    city: data?.address.city || '',
    state: data?.address.state || '',
    zip: data?.address.zip || '',
    email: data?.email || '',
    phone: data?.phone || '',
    fax: data?.fax || '',
    isPrimary: data?.isPrimary || false,
    type: data?.type || type,
  };
}

interface MemberContactFormProps {
  type: 'Home' | 'Work';
  data?: MemberContact;
  loading: boolean;
}

function MemberContactForm({ type, loading, data }: MemberContactFormProps) {
  const { spacing } = useTheme();
  const { entity: { id = '' } = {} } = useModelEntity();
  const toast = useToast();
  const strings = useStrings();
  const [state, setState] = useState(Record(flattenData(type, data)));
  const [update, { loading: updating }] = useMutation(gql`
    mutation mergeContact($id: String!, $input: MemberContactInput!) {
      node: mergeMemberContact(memberId: $id, input: $input) {
        id
      }
    }`);

  const [toggle, { loading: toggling }] = useMutation(gql`
    mutation toggleContact($id: String!, $memberId: String!) {
      node: togglePrimaryContact(contactId: $id, memberId: $memberId) {
        id
      }
    }
  `);

  useEffect(() => {
    setState(x => x.merge(flattenData(type, data)));
  }, [data, setState, type]);

  const field = useCallback((
    key: keyof MemberContactInput,
    label: string, attrs: InputHTMLAttributes<HTMLInputElement> = {}) => {
    const inputId = `${type}-${key}`;
    return (
      <GridArea area={key}>
        <Label htmlFor={inputId}>{label}</Label>
        <Input {...attrs} id={inputId} value={state.get(key) as any}
               onChange={e => setState(x => x.set(key, e.target.value))} />
      </GridArea>
    );
  }, [state, type]);

  if (loading) {
    return <Loading />;
  }

  return (
    <form onSubmit={x => {
      x.preventDefault();
      update({ variables: { id, input: state.toJS() } })
        .then(_ => toast(strings.saveSuccess))
        .catch(x => toast(x.message, { context: 'danger' }));
    }}>
      <Heading as={'h3'}>
        <FlexStack>
          <span>{type} Address</span>
          {data?.isPrimary ? (
            <span className={css({
              opacity: .5,
              fontSize: '.8em',
            })}>
              Primary
            </span>
          ) : (
            <Button link type='button' onClick={_ => {
              const variables = { id: data?.id, memberId: id };
              toggle({ variables, refetchQueries: [QUERY_MEMBER_FORM] })
                .then(_ => toast(strings.saveSuccess))
                .catch(e => toast(e.message, { context: 'danger' }));
            }}>Make Primary</Button>
          )}
        </FlexStack>
      </Heading>
      <Fieldset disabled={updating || toggling}>
        <Grid template={`
          "email email"
          "phone phone"
          "fax fax"
          "street street"
          "attentionOf attentionOf"
          "city city"
          "state zip"
          `} responsive={{
          [ThemeBreakpoints.xl]: `
          "email email email email"
          "phone phone fax fax"
          "street street street street"
          "attentionOf attentionOf attentionOf attentionOf"
          "city city state zip"
          / 1fr 1fr 1fr 1fr`,
        }} gap={spacing.md}>

          {field('email', 'Email')}
          {field('phone', 'Phone', { type: 'tel' })}
          {field('fax', 'Fax')}
          {field('street', 'Address 1')}
          {field('attentionOf', 'Address 2')}
          {field('city', 'City')}
          {field('state', 'State')}
          {field('zip', 'Zip')}

        </Grid>
        <Flex direction={'column'} className={css({
          marginTop: spacing.md,
          alignItems: 'flex-end',
        })}>
          <Button type={'submit'}>Save</Button>
        </Flex>
      </Fieldset>
    </form>
  );
}

export default MemberContactForm;
