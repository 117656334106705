import { DateTime } from 'luxon';

interface Props {
  value?: string | number;
  format?: string;
}

function FormatDate({ value, format = 'MM/yyyy' }: Props) {
  if (!value) {
    return <span>-</span>;
  }

  const dateTime = typeof value === 'string' ? DateTime.fromISO(value) : DateTime.fromMillis(value);
  return <span title={dateTime.toLocaleString()}>{dateTime.toFormat(format)}</span>;
}

export default FormatDate;
