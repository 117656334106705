import { IEntity } from 'data';
import { createContext } from 'react';
import IModelEntityContext from './IModelEntityContext';

const ModelEntityContext = createContext<IModelEntityContext<IEntity>>({
  entity: undefined,
  loading: false,
  merging: false,
  merge(_) {
    return Promise.reject('not implemented');
  },
  remove(_) {
    return Promise.reject('not implemented');
  },
});

export default ModelEntityContext;
