import { useInputStyle } from 'hooks';
import { SelectHTMLAttributes } from 'react';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {}

function Select({ className, ...props }: Props) {
  const classes = useInputStyle(className, false, {
    height: 34,
    width: '100%',
  });

  return <select {...props} className={classes} />;
}

export default Select;
