import { AutoCompleteQuery } from 'core/TaggedSearchReducer/Types';
import { EnrollmentType } from 'model';
import pluralize from 'pluralize';
import { Route, Routes, useLocation } from 'react-router-dom';
import EnrollmentForm from './EnrollmentForm';
import EnrollmentFormHeading from './EnrollmentFormHeading';
import EnrollmentTable from './EnrollmentTable';
import {
  AdminContent,
  DeleteModel,
  EnrollmentSearchContextProvider,
  GridArea,
  Heading,
  QueryPageContextProvider,
  RouteIDContextProvider,
  SearchPortal,
} from 'components';
import { Fragment } from 'react';

interface Props {
  baseQuery: AutoCompleteQuery;
  name: string;
  pluralName?: string;
  baseURL: string;
  type: EnrollmentType;
}

function DuesIndexPage({ type, name, pluralName }: Props) {
  const location = useLocation();
  const path = location?.pathname || '';
  const plural = pluralName || pluralize(name);

  return (
    <EnrollmentSearchContextProvider type={type}>
      <SearchPortal />
      <Routes>
        <Route
          index
          element={
            <QueryPageContextProvider>
              <EnrollmentTable type={type} home={path} heading={plural} />
            </QueryPageContextProvider>
          }
        />
        <Route
          path='new'
          element={
            <Fragment>
              <GridArea area='heading'>
                <Heading>New {name}</Heading>
              </GridArea>
              <GridArea area='content'>
                <EnrollmentForm type={type} hasDues />
              </GridArea>
            </Fragment>
          }
        />
        <Route
          path=':id'
          element={
            <RouteIDContextProvider>
              <GridArea area='heading'>
                <EnrollmentFormHeading home={path} homeCaption={plural}>
                  {name}
                </EnrollmentFormHeading>
              </GridArea>
              <GridArea area='content'>
                <EnrollmentForm type={type} waitForLoad hasDues />
              </GridArea>
              <AdminContent>
                <DeleteModel home={path} />
              </AdminContent>
            </RouteIDContextProvider>
          }
        />
      </Routes>
    </EnrollmentSearchContextProvider>
  );
}

export default DuesIndexPage;
