import gql from 'graphql-tag';
import { IEntity } from '../../data';
import { PAGE_INFO_FIELDS } from '../PageInfo';

export const TRANSACTION_TABLE_FIELDS = gql`
  fragment TransactionTableFields on Transaction {
    id
    updatedAt
    createdAt
    paymentAt
    enteredAt
    description
    amount
    ledger
    type
  }`;

export const QUERY_TRANSACTION_TABLE_PAGE = gql`
  ${TRANSACTION_TABLE_FIELDS}
  ${PAGE_INFO_FIELDS}
  query QueryTransactionTablePage($query: String = "", $sort: TransactionSortColumnsEnumeration = TRANSACTION_PAYMENT_AT, $direction: SortDirection = DESC, $first: Int, $before: String, $after: String) {
    page: transactions(query: $query, sort: $sort, direction: $direction, first: $first, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...TransactionTableFields
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }`;

export interface TransactionTableView extends IEntity {
  paymentAt?: string;
  enteredAt?: string;
  description?: string;
  amount: number;
  ledger: string;
  type: string;
}

