import TaggedSearchContextProvider from 'components/SearchContext/TaggedSearchContextProvider';
import { AutoCompleteQuery, TaggedSearchState } from 'core/TaggedSearchReducer/Types';
import { DELETE_NODE, EnrollmentType } from 'model';
import pluralize from 'pluralize';
import { Route, Routes, useLocation } from 'react-router-dom';
import EnrollmentForm from './EnrollmentForm';
import EnrollmentFormHeading from './EnrollmentFormHeading';
import EnrollmentTable from './EnrollmentTable';
import {
  ModelEntityProvider,
  GridArea,
  Heading,
  RouteIDContextProvider,
  AdminContent,
  DeleteModel,
  QueryPageContextProvider,
  SearchPortal,
} from 'components';
import gql from 'graphql-tag';

interface Props {
  baseQuery: AutoCompleteQuery;
  baseURL: string;
  type: EnrollmentType;
  name: string;
  pluralName?: string;
}

const ENROLLMENT_MERGE = gql`
  mutation MergeEnrollment($id: String, $input: EnrollmentInput!) {
    node: mergeEnrollment(input: $input, id: $id) {
      id
    }
  }`;

const ENROLLMENT_BY_ID = gql`
  query EnrollmentById($id: String!) {
    node(id: $id) {
      id
      ...on Enrollment {
        name
        dues
      }
    }
  }`;

const ENROLLMENT_NODE_DOCS = {
  id: ENROLLMENT_BY_ID,
  merge: ENROLLMENT_MERGE,
  remove: DELETE_NODE,
};

function EnrollmentIndexPage({ type, name, pluralName, baseURL, baseQuery }: Props) {
  const location = useLocation();
  const url = location?.pathname;

  const plural = pluralName || pluralize(name);
  const initialState: TaggedSearchState = {
    activeTagIndex: null,
    baseQuery,
    baseURL,
    options: [],
    tags: [],
    typename__: type,
    value: '',
    where: { type: { _eq: type } },
  };

  return (
    <TaggedSearchContextProvider {...initialState}>
      <SearchPortal />
      <Routes>
        <Route
          index
          element={
            <QueryPageContextProvider>
              <EnrollmentTable type={type} home={url} heading={plural} />
            </QueryPageContextProvider>
          }
        />
        <Route
          path='new'
          element={
            <ModelEntityProvider documents={ENROLLMENT_NODE_DOCS}>
              <GridArea area='heading'>
                <Heading>New {name}</Heading>
              </GridArea>
              <GridArea area='content'>
                <EnrollmentForm type={type} />
              </GridArea>
            </ModelEntityProvider>
          }
        />
        <Route
          path=':id'
          element={
            <RouteIDContextProvider>
              <ModelEntityProvider documents={ENROLLMENT_NODE_DOCS}>
                <GridArea area='heading'>
                  <EnrollmentFormHeading home={url} homeCaption={plural}>
                    {name}
                  </EnrollmentFormHeading>
                </GridArea>
                <GridArea area='content'>
                  <EnrollmentForm type={type} waitForLoad />
                </GridArea>
                <AdminContent>
                  <DeleteModel home={url} />
                </AdminContent>
              </ModelEntityProvider>
            </RouteIDContextProvider>
          }
        />
      </Routes>
    </TaggedSearchContextProvider>
  );
}

export default EnrollmentIndexPage;
