import { IEntity, ModelNormalize, ModelSchema } from 'data';
import gql from 'graphql-tag';
import { array, number, object, string } from 'yup';
import { ModelHelpers } from '../../shared';

export const MEMBER_CONTACT_FIELDS = gql`
  fragment MemberContactFields on Contact {
    email
    fax
    phone
    isPrimary
    type
    id
    createdAt
    updatedAt
    address {
      street
      attentionOf
      city
      state
      zip
      updatedAt
    }
  }
`;

export interface MemberContact extends IEntity {
  email?: string;
  fax?: string;
  phone?: string;
  isPrimary: boolean;
  type: string;
  address: {
    street?: string
    attentionOf?: string
    city?: string
    state?: string
    zip?: string
    updatedAt?: string
  };
}

export const MEMBER_EDUCATION_FIELDS = gql`
  fragment MemberEducationFields on Education {
    licensedYear
    meNumber
    residencyAt
    internshipAt
    school {
      name
    }
  }
`;

export interface MemberEducation {
  licensedYear?: number;
  meNumber?: string;
  residencyAt?: string;
  internshipAt?: string;
  school?: {
    name: string
  };
}

export const MEMBER_FORM_FIELDS = gql`
  ${MEMBER_CONTACT_FIELDS}
  ${MEMBER_EDUCATION_FIELDS}
  fragment MemberFormFields on Member {
    id
    createdAt
    updatedAt
    title
    givenName
    middleInitial
    surname
    memberNumber
    remarks
    birthdate
    joinedAt
    lastPaymentDate
    totalBalance
    currentNempacDonation
    statementUrl
    education {
      ...MemberEducationFields
    }
    contacts {
      ...MemberContactFields
    }
    enrollments {
      id
      createdAt
      updatedAt
      type
      name
      dues
    }
    ledgers {
      id
      name
      balance
    }
  }
`;

export const QUERY_MEMBER_FORM = gql`
  ${MEMBER_FORM_FIELDS}
  query QueryMemberForm($id: String!) {
    node(id: $id) {
      ...MemberFormFields
    }
  }`;

export interface Member extends IEntity {
  title?: string;
  givenName: string;
  middleInitial?: string;
  surname: string;
  memberNumber?: number | null;
  remarks?: string | null;
  birthdate?: string | null;
  joinedAt?: string | null;
  statementUrl?: string;
  contacts?: Array<MemberContact>;
  education?: MemberEducation;
}

export interface MemberWithImmutableFields extends Member {
  totalBalance: number;
  lastPaymentDate?: string;
  currentNempacDonation?: number;
}

export const MemberDefaultValues: Member = {
  givenName: '',
  surname: '',
  memberNumber: 0,
  remarks: '',
  birthdate: '',
  joinedAt: '',
};

export function useMemberSchema(): ModelSchema<Member> {
  return object({
    title: string(),
    givenName: string().min(2).required(),
    middleInitial: string(),
    surname: string().min(2).required(),
    memberNumber: number(),
    remarks: string(),
    birthdate: string(),
    joinedAt: string(),
    statementUrl: string(),
    contacts: array(),
    education: object(),
  });
}

export const MemberNormalize: ModelNormalize<Member> = {
  ingress({ id, givenName, surname, memberNumber, ...optional }) {
    return {
      id,
      givenName,
      surname,
      memberNumber,
      middleInitial: ModelHelpers.emptyStringIfNulldefined(optional.middleInitial),
      title: ModelHelpers.emptyStringIfNulldefined(optional.title),
      remarks: ModelHelpers.emptyStringIfNulldefined(optional.remarks),
      birthdate: ModelHelpers.dateFromTimestampZ(optional.birthdate),
      joinedAt: ModelHelpers.dateFromTimestampZ(optional.joinedAt),
    };
  },

  egress({ id, givenName, surname, memberNumber, ...optional }) {
    return {
      id,
      givenName,
      surname,
      memberNumber,
      remarks: ModelHelpers.undefinedWhenEmpty(optional.remarks),
      birthdate: ModelHelpers.timestampFromDate(optional.birthdate),
      joinedAt: ModelHelpers.timestampFromDate(optional.joinedAt),
      middleInitial: ModelHelpers.undefinedWhenEmpty(optional.middleInitial),
      title: ModelHelpers.undefinedWhenEmpty(optional.title),
    };
  },
};

export const CreateMemberNormalize: ModelNormalize<Member> = {
  ingress: MemberNormalize.ingress,
  egress: MemberNormalize.egress,
};
