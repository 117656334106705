import { css } from '@emotion/css';
import Fieldset from 'components/Fieldset';
import Loading from 'components/Loading';

function ModelFormSkeleton() {
  const wrapperClass = css({
    height: 250,
  });

  return (
    <Fieldset>
      <div className={wrapperClass}>
        <Loading />
      </div>
    </Fieldset>
  );
}

export default ModelFormSkeleton;
