import { css } from '@emotion/css';
import useTheme from './Theme/useTheme';

type Props = {
  show: boolean;
};

function UnreadBullet({ show }: Props) {
  const theme = useTheme();
  const rootClass = css({
    backgroundColor: theme.colors.primary,
    cursor: 'pointer',
    border: `2px ${theme.colors.white} solid`,
    borderRadius: '50%',
    height: 14,
    left: 8,
    position: 'absolute',
    top: -3,
    width: 14,
  });

  return show ? <div className={rootClass} /> : null;
}

export default UnreadBullet;
