import { css } from '@emotion/css';
import { useContext } from 'react';
import Reset from './components/Reset';
import { ImportContext } from './context';

const container = css`
  background: #ffb84f59;
  border: 2px solid #e1921e;
`;

const heading = css`
  color: #6b4610;
  margin-top: 0;
  margin-bottom: 16px;
`;

const actions = css`
  display: flex;
  gap: 16px;
`;

const FileConfirm = () => {
  const {
    state: { loaded, loading, error, accepted, rejected, success },
    dispatch,
  } = useContext(ImportContext);

  if (
    error ||
    success ||
    loading ||
    !loaded ||
    !accepted ||
    accepted.length === 0 ||
    !rejected ||
    rejected.length > 0
  ) {
    return null;
  }

  if (accepted.length !== 1) {
    dispatch(fail('Only a single file is allowed.'));
    return null;
  }

  const file = accepted[0];
  return (
    <div className={container}>
      <h2 className={heading}>Are you sure you want to upload '{file.name}'?</h2>
      <div className={actions}>
        <Reset />
        <button type='submit'>Confirm</button>
      </div>
    </div>
  );
};

export default FileConfirm;
