import ModelColumnInfo from '../../data/ModelColumnInfo';
import { IEntity } from '../../data';
import { Cell, Table } from '../Table';
import Pagination from './Pagination';
import ModelTableBody from './ModelTableBody';
import ModelTableHead from './ModelTableHead';

type Props<T extends IEntity> = {
  home: string;
  columns: Array<ModelColumnInfo<T>>;
  to?: (entity: T) => string;
};

function ModelTable<T extends IEntity>(props: Props<T>) {
  const { columns, home, to } = props;

  return (
    <Table>
      <ModelTableHead columns={columns} />
      <ModelTableBody home={home} columns={columns} to={to} />
      <tfoot>
      <tr>
        <Cell borderless colSpan={columns.length}>
          <Pagination />
        </Cell>
      </tr>
      </tfoot>
    </Table>
  );
}

export type ModelTableProps<T extends IEntity> = Props<T>;
export default ModelTable;
