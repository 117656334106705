import { css } from '@emotion/css';
import { useModelPage } from 'hooks';
import Flex from '../Flex';
import FlexStack from '../FlexStack';
import Text from '../Text';
import useTheme from '../Theme/useTheme';
import BackPage from './BackPage';
import NextPage from './NextPage';
import PageSize from './PageSize';

function Pagination() {
  const theme = useTheme();
  const { pageInfo } = useModelPage();

  const pageSizeContainer = css({
    width: 200,
    alignItems: 'center',
  });

  const pageSizeClass = css({
    marginRight: theme.spacing.md,
  });

  const backButtonClass = css({
    marginRight: theme.spacing.sm,
  });

  const countClass = css({
    flexShrink: 0,
    marginLeft: theme.spacing.md,
  });

  return (
    <FlexStack>
      <Flex className={pageSizeContainer}>
        <PageSize className={pageSizeClass} title='Page size' />
        {pageInfo?.totalCount && <Text className={countClass}>{pageInfo.totalCount} records</Text>}
      </Flex>
      <Flex>
        <BackPage className={backButtonClass} />
        <NextPage />
      </Flex>
    </FlexStack>
  );
}

export default Pagination;
