import { Button, Fieldset, Flex, Grid, GridArea, Heading, Input, Label, Link } from '../../components';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { css } from '@emotion/css';
import { useTheme } from '../../components/Theme/hooks';
import { useToast } from '../../hooks';

function DuesReportPage() {
  const [query, setQuery] = useState('All Members');
  const [dirty, setDirty] = useState(false);
  const today = useMemo(() =>
      DateTime.now().toFormat('yyyy-MM-dd')
    , []);

  const [start, setStart] = useState(today);
  const [end, setEnd] = useState(today);
  const toaster = useToast();
  const theme = useTheme();

  const { data } = useQuery(gql`query RosterEnrollments($query: String!) {
    rosters(query: $query, first: 20) {
      edges {
        node {
          id
          ... on Roster {
            name
            createdAt
          }
        }
      }
    }
  }`, {
    variables: { query },
  });


  const [update, { data: preAuth, loading }] = useMutation(gql`mutation CreatePreAuthRosterLink(
    $query: String!, $start: String!, $end: String!) {
    url: preAuthDuesReportUrl(roster: $query, startDate: $start, endDate: $end)
  }`);

  const enrollments = data?.rosters.edges.map((x: any) =>
    <option key={x.node.id} value={x.node.name}>
      {DateTime.fromISO(x.node.createdAt).toLocal().toFormat('MM/dd/yyyy hh:mm a')}
    </option>);

  return (
    <form onSubmit={e => {
      e.preventDefault();
      setDirty(false);
      update({ variables: { query, start, end } }).catch(e => {
        console.error(e.message);
        toaster(`Failed to generate the report. ${e.message}`, {
          context: 'danger',
        });
      });
    }}>
      <Heading>
        Dues Report
      </Heading>
      <Fieldset onChange={_ => setDirty(true)}>
        <Grid template={`
        "start end quick"
        "roster roster roster"
        "actions . ."
        /1fr 1fr 80px`} gap={16}>
          <GridArea area='quick'>
            <Flex className={css({
              alignItems: 'flex-end',
              height: '100%',
            })}>
              <Button link type='button' className={css({
                margin: `${theme.spacing.sm} 0`,
              })} onClick={_ => {
                setStart(today);
                setEnd(today);
              }}>
                Today
              </Button>
            </Flex>
          </GridArea>
          <GridArea area='start'>
            <Label htmlFor='start'>Start Date</Label>
            <Input type='date' id='start' value={start} onChange={e => setStart(e.target.value)} />
          </GridArea>
          <GridArea area='end'>
            <Label htmlFor='end'>End Date</Label>
            <Input type='date' id='end' value={end} onChange={e => setEnd(e.target.value)} />
          </GridArea>
          <GridArea area='roster'>
            <Label htmlFor='roster'>Roster</Label>
            <Input id='roster' list='enrollments' required value={query} onChange={e => setQuery(e.target.value)} />
            <datalist id='enrollments' onChange={alert}>
              {enrollments}
            </datalist>
          </GridArea>
          <GridArea area='actions'>
            <Flex>
              <Button type='submit' disabled={loading}>
                Generate
              </Button>
              {!dirty && preAuth && (
                <Link className={css({
                  marginLeft: theme.spacing.sm,
                })} to={process.env.REACT_APP_GRAPH_HOST + preAuth.url} target='_blank' button>
                  Download
                </Link>
              )}
            </Flex>
          </GridArea>
        </Grid>
      </Fieldset>
    </form>
  );
}

export default DuesReportPage;
