import { IEntity } from 'data';

interface Position extends IEntity {
  name: string;
  metric: number;
}

export const PositionDefaultValues: Position = {
  name: '',
  metric: 100,
};

export default Position;
