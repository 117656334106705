import { ModelEntityProvider, RouteIDContextProvider } from 'components';
import { Route, Routes } from 'react-router-dom';
import MemberEditHomePage from './Home';
import MemberPaymentIndexPage from './Payment';
import TransactionIndexPage from './Transactions';
import { QUERY_MEMBER_FORM } from '../../../model';
import { MemberDocuments } from '../../../schema';

function MemberEditIndex() {
  return (
    <RouteIDContextProvider>
      <ModelEntityProvider documents={{
        id: QUERY_MEMBER_FORM,
        merge: MemberDocuments.entity.merge,
        remove: MemberDocuments.entity.remove,
      }}>
        <Routes>
          <Route index Component={MemberEditHomePage} />
          <Route path='transactions/*' element={<TransactionIndexPage />} />
          <Route path='payment/*' element={<MemberPaymentIndexPage />} />
        </Routes>
      </ModelEntityProvider>
    </RouteIDContextProvider>
  );
}

export default MemberEditIndex;
