import {
  BreadcrumbSkeleton,
  Currency, Divider, Fieldset,
  Grid,
  GridArea,
  Input, Label,
  Loading, ThemeBreakpoints,
} from 'components';
import { useModelEntity } from 'hooks';
import { Member } from 'model';
import TransactionFormHeading from './TransactionFormHeading';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useContext, Fragment } from 'react';
import { IDContext } from '../../../../core';
import { css } from '@emotion/css';
import { useTheme } from '../../../../components/Theme/hooks';
import { ReactComponent as Lock } from '../../../../icons/lock-solid.svg';
import { ModelHelpers } from '../../../../shared';

interface Props {
}

function TransactionForm(_: Props) {
  const { id } = useContext(IDContext);
  const { loading: memberLoading, entity } = useModelEntity<Member>();
  const theme = useTheme();
  const { data, loading } = useQuery(gql`
    query getTransactionById($id: String!) {
      node(id: $id) {
        ...on Transaction {
          id
          createdAt
          ledger
          checkNumber
          depositSlipNumber
          ccLastFourDigits
          description
          amount
          enteredAt
          paymentAt
        }
      }
    }
  `, { variables: { id } });

  const memberName = memberLoading ? <BreadcrumbSkeleton /> : `${entity?.givenName} ${entity?.surname}`;
  const lockClass = css({
    height: 10,
    marginRight: theme.spacing.sm,
  });

  return (
    <Fragment>
      <GridArea area='heading'>
        <TransactionFormHeading memberName={memberName}>
          {loading ? <BreadcrumbSkeleton /> : (
            <span>
              <Currency value={data?.node?.amount} />
            </span>
          )}
        </TransactionFormHeading>
      </GridArea>
      <GridArea area='content'>
        {loading && <Loading />}
        {data?.node && (
          <Fieldset>
            <Grid gap={theme.spacing.md} template={`
            "ledger ledger"
            "checkNumber depositSlipNumber"
            "description description"
            ". enteredAt"
            "divider divider"
            "amount amount"
            `} responsive={{
              [ThemeBreakpoints.lg]: `
              "ledger ledger checkNumber depositSlipNumber"
              "description description enteredAt enteredAt"
              "divider divider divider divider"
              ". . . amount"
              /1fr 1fr`,
            }}>
              <GridArea area='ledger'>
                <Label htmlFor='ledger'>
                  <Lock className={lockClass} /> Ledger
                </Label>
                <Input name='ledger' id='ledger' defaultValue={data.node.ledger} disabled readOnly />
              </GridArea>
              <GridArea area='checkNumber'>
                <Label htmlFor='checkNumber'>
                  <Lock className={lockClass} /> Check Number
                </Label>
                <Input name='checkNumber' id='checkNumber' defaultValue={data.node.checkNumber} disabled readOnly />
              </GridArea>
              <GridArea area='depositSlipNumber'>
                <Label htmlFor='depositSlipNumber'>
                  <Lock className={lockClass} /> Deposit Slip Number
                </Label>
                <Input name='depositSlipNumber' id='depositSlipNumber' defaultValue={data.node.depositSlipNumber}
                       disabled readOnly />
              </GridArea>
              <GridArea area='description'>
                <Label htmlFor='description'>
                  <Lock className={lockClass} /> Description
                </Label>
                <Input name='description' id='description' defaultValue={data.node.description}
                       disabled readOnly />
              </GridArea>
              <GridArea area='enteredAt'>
                <Label htmlFor='enteredAt'>
                  <Lock className={lockClass} /> Entered On
                </Label>
                <Input name='enteredAt' id='enteredAt'
                       defaultValue={ModelHelpers.dateFromTimestampZ(data.node.enteredAt)}
                       disabled readOnly />
              </GridArea>
              <GridArea area={'divider'}>
                <Divider />
              </GridArea>
              <GridArea area='amount'>
                <Label htmlFor='amount'>
                  <Lock className={lockClass} /> Amount ($)
                </Label>
                <Input name='amount' id='amount' defaultValue={data.node.amount}
                       disabled readOnly />
              </GridArea>

            </Grid>
          </Fieldset>
        )}
      </GridArea>
    </Fragment>
  );
}

export default TransactionForm;
