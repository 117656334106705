import { css, cx } from '@emotion/css';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

function AppNavMenuContainer({ children, className, ...props }: Props) {
  const classes = cx(
    css(
      {
        maxHeight: 'calc(100vh - 56px)',
        overflowY: 'auto',
      },
      className
    )
  );

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}

export default AppNavMenuContainer;
