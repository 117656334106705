import { ReactNode } from 'react';

import Link from 'components/Link';

interface Props {
  to?: string;
  children?: ReactNode;
}

function Breadcrumb({ to, children }: Props) {
  return to ? <Link to={to}>{children}</Link> : <span>{children}</span>;
}

export type BreadcrumbProps = Props;
export default Breadcrumb;
