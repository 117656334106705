import gql from 'graphql-tag';

export * from './Batch';
export * from './Member';
export * from './Enrollment';
export * from './Ledger';
export * from './Position';
export * from './Dues';
export * from './MemberEnrollment';
export * from './Transaction';
export * from './UserNotification';
export * from './PageInfo';

export const DELETE_NODE = gql`
  mutation DeleteNode($id: String!) {
    node: remove(id: $id) {
      id
    }
  }`;

export type { default as Notification } from './Notification';
