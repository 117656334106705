import { ModelDocuments } from 'shared';
import id from './id.graphql';
import merge from './merge.graphql';
import remove from './remove.graphql';

export const BatchDocuments: Omit<ModelDocuments, 'list'> = {
  entity: {
    id,
    merge,
    remove,
  },
};
