import { IModelEntityContext, ModelEntityContext } from 'core';
import { IEntity } from 'data';
import { useContext } from 'react';

function useModelEntity<T extends IEntity>() {
  const context = useContext(ModelEntityContext);
  return context as IModelEntityContext<T>;
}

export default useModelEntity;
