import { css } from '@emotion/css';
import useTheme from 'components/Theme/useTheme';
import { useMemo } from 'react';

function useModalContentStyle() {
  const theme = useTheme();
  const className = useMemo(
    () =>
      css({
        padding: theme.spacing.md,
      }),
    [theme]
  );

  return className;
}

export default useModalContentStyle;
