import resolve from './resolve.graphql';
import list from './list.graphql';

export const MemberEnrollmentDocuments = {
  /**
   * Resolve the given enrollments
   * @param add An array of `MemberEnrollments` to add.
   * @param remove An array of `uuid` of enrollments to remove.
   * @param ended_at An ISO UTC timestamp of when the `remove` enrollments are ending.
   * @returns `added` and `removed` properties each with a `count` of the number of rows changed.
   */
  resolve,

  /**
   * Subscribe to the enrollments of a given type for the given member_id.
   * @param {string} member_id The member uuid the enrollments belong to.
   * @param {type} type The type of enrollment to subscribe to, eg. "County"
   * @returns MemberEnrollment array called `nodes`
   */
  list,
};
