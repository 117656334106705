import { Breadcrumb, Breadcrumbs, Heading } from 'components';
import MemberCrumb from 'pages/member/components/MemberCrumb';

interface Props {
}

function TransactionTableHeading(_: Props) {
  return (
    <Heading>
      Transactions
      <Breadcrumbs>
        <Breadcrumb to='/member'>Members</Breadcrumb>
        <Breadcrumb to='..'>
          <MemberCrumb />
        </Breadcrumb>
        <Breadcrumb>Transactions</Breadcrumb>
      </Breadcrumbs>
    </Heading>
  );
}

export default TransactionTableHeading;
