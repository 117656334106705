import { TextareaHTMLAttributes } from 'react';
import useInputStyle from '../hooks/useInputStyle';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement>;

function TextArea({ className, ...props }: Props) {
  const classes = useInputStyle(className, false, {
    resize: 'none',
    width: '100%',
  });

  return <textarea {...props} className={classes} />;
}

export default TextArea;
