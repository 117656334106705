import { useModelPage } from 'hooks';
import { createElement, useContext } from 'react';
import PageContext from '../../core/PageContext';
import { IEntity, ModelColumnInfo } from '../../data';
import ModelEmpty from '../ModelEmpty';
import { Cell, Row } from '../Table';

interface Props<T extends IEntity> {
  home: string;
  columns: Array<ModelColumnInfo<T>>;
  to?: (entity: T) => string;
}

function ModelTableBody<T extends IEntity>({ columns, home, to }: Props<T>) {
  const paging = useContext(PageContext);
  const context = useModelPage<T>();

  const rows = context.loading ? (
    Array(paging.first)
      .fill(null)
      .map((_, idx) => (
        <Row key={idx}>
          <Cell skeleton />
        </Row>
      ))
  ) : context.edges && context.edges.length > 0 ? (
    context.edges.map(entity => (
      <Row key={entity.node.id} target={typeof to === 'function' ? '_blank' : '_self'}
           to={to?.(entity.node) || `${home}/${entity.node.id}`}>
        {columns.map((info, idx) => (
          <Cell key={idx}>
            {info.as ? createElement(info.as, { value: entity.node[info.column] }) : entity.node[info.column] as string}
          </Cell>
        ))}
      </Row>
    ))
  ) : (
    <Row>
      <Cell borderless colSpan={columns.length}>
        <ModelEmpty />
      </Cell>
    </Row>
  );

  return <tbody>{rows}</tbody>;
}

export default ModelTableBody;
