import { css } from '@emotion/css';
import classnames from 'classnames';
import { useCallback, useContext } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ImportContext, setFiles } from './context';

const dropzone = css`
  background: rgba(112, 138, 175, 0.1);
  border: 2px dashed #b4c5dd;
`;

const activeDZ = css`
  background: rgba(112, 138, 175, 0.35);
  border: 2px solid #708aaf !important;
`;

const heading = css`
  margin-top: 0;
  margin-bottom: 16px;
`;

function FileUploader() {
  const {
    dispatch,
    state: { loaded, error },
  } = useContext(ImportContext);

  const onDrop = useCallback(
    (accepted: File[], rejected: FileRejection[]) => {
      dispatch(
        setFiles(
          accepted,
          rejected.map(r => r.file),
        ),
      );
    },
    [dispatch],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const classes = classnames(dropzone, { [activeDZ]: isDragActive });

  if (error || loaded) {
    return null;
  }

  return (
    <div {...getRootProps({ className: classes })}>
      <input {...getInputProps()} />
      <h2 className={heading}>Drag and drop a single spreadsheet here</h2>
      <p
        className={css`
          margin: 0;
        `}
      >
        or click to select spreadsheet
      </p>
    </div>
  );
}

export default FileUploader;
