import { DocumentNode } from "@apollo/client";
import * as Options from '../AutoComplete/Options';
import { KeyboardEvent } from 'react';

export type OptionID = keyof typeof Options;

export type AutoCompleteResult = {
  id?: string;
  caption: string;
  subcaption?: string;
}

export type AutoCompleteQuery = {
  query: DocumentNode;
  convert: (data: any) => Array<AutoCompleteResult>;
  column?: string
}

export type TagType = {
  optionID: OptionID,
  value?: string,
};

export type SearchOptions = {
  isField: boolean,
  inputType?: string,
  operator: string,
};

export type Option = {
  property: string,
  caption: string,
  searchOptions: SearchOptions,
  hint: string,
  query?: AutoCompleteQuery,
};

export type TaggedSearchState = {
  typename__: string,
  value: string,
  options: OptionID[],
  activeTagIndex: number | null,
  tags: TagType[],
  where?: any,
  baseURL: string
  baseQuery?: AutoCompleteQuery,
};

export const initTaggedSearchState: TaggedSearchState = {
  typename__: '',
  value: '',
  options: [],
  activeTagIndex: null,
  tags: [] as TagType[],
  where: undefined,
  baseURL: '',
  baseQuery: undefined,
};

export interface Action<T extends string> {
  type: T;
}

export interface ActivateTag extends Action<"ActivateTag"> {
  activeTagIndex: number;
}

export interface Nothing extends Action<'Nothing'> { }

export interface BlurActiveTag extends Action<"BlurActiveTag"> { };

export interface SelectTag extends Action<"SelectTag"> {
  tag: TagType
}

export interface DeleteTag extends Action<"DeleteTag"> {
  optionID: OptionID,
};

export interface ValueChange extends Action<"ValueChange"> {
  value: string
};

export interface TagValueChange extends Action<"TagValueChange"> {
  value: string,
  index: number
};

export interface InitOptions extends Action<"InitState"> {
  initState: TaggedSearchState
};

export interface ClearState extends Action<"ClearState"> { };

export interface SetActiveIndex extends Action<"SetActiveIndex"> {
  index: number | null;
};

export interface Tab extends Action<'Tab'> {
  backward: boolean;
  event: KeyboardEvent<HTMLInputElement>;
}

export interface ArrowAction extends Action<'ArrowLeft' | 'ArrowRight'> { }

export type Actions = ActivateTag
  | BlurActiveTag
  | DeleteTag
  | SelectTag
  | ValueChange
  | TagValueChange
  | InitOptions
  | ClearState
  | SetActiveIndex
  | Tab
  | ArrowAction
  | Nothing;
