import { css } from '@emotion/css';
import { useMemo } from 'react';

function useSrOnlyStyle() {
  const style = useMemo(() => {
    return css({
      display: 'none',
    });
  }, []);

  return style;
}

export default useSrOnlyStyle;
