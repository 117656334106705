import TaggedSearchContextProvider from 'components/SearchContext/TaggedSearchContextProvider';
import { AutoCompleteQuery, TaggedSearchState } from 'core/TaggedSearchReducer/Types';
import { NotFoundPage } from 'pages';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PositionDocuments } from 'schema';
import { ModelHelpers } from 'shared';
import PositionEditHeading from './components/PositionEditHeading';
import PositionForm from './components/PositionForm';
import PositionTable from './components/PositionTable';
import PositionTableHeading from './components/PositionTableHeading';
import {
  GridArea,
  Heading,
  ModelEntityProvider,
  ModelPageProvider,
  QueryPageContextProvider,
  RouteIDContextProvider,
  SearchPortal,
} from 'components';

interface Props {
  baseQuery: AutoCompleteQuery;
  baseURL: string;
  type: string;
}

function CommitteePositionIndexPage({ type, baseURL, baseQuery }: Props) {
  const location = useLocation();
  const url = location?.pathname;

  const initialState: TaggedSearchState = {
    activeTagIndex: null,
    baseQuery,
    baseURL,
    options: [],
    tags: [],
    typename__: type,
    value: '',
  };

  return (
    <TaggedSearchContextProvider {...initialState}>
      <SearchPortal />
      <Routes>
        <Route
          index
          element={
            <QueryPageContextProvider>
              <ModelPageProvider documents={PositionDocuments.list}>
                <GridArea area="heading">
                  <PositionTableHeading home={url} />
                </GridArea>
                <GridArea area="content">
                  <PositionTable home={url} />
                </GridArea>
              </ModelPageProvider>
            </QueryPageContextProvider>
          }
        />
        <Route
          path="new"
          element={
            <>
              <GridArea area="heading">
                <Heading>New Position</Heading>
              </GridArea>
              <GridArea area="content">
                <PositionForm home={url} />
              </GridArea>
            </>
          }
        />
        <Route
          path={ModelHelpers.uuidParam}
          element={
            <RouteIDContextProvider>
              <ModelEntityProvider documents={PositionDocuments.entity}>
                <GridArea area="heading">
                  <PositionEditHeading home={url} />
                </GridArea>
                <GridArea area="content">
                  <PositionForm home={url} waitForLoad />
                </GridArea>
              </ModelEntityProvider>
            </RouteIDContextProvider>
          }
        />
        <Route path="*" Component={NotFoundPage} />
      </Routes>
    </TaggedSearchContextProvider>
  );
}

export default CommitteePositionIndexPage;
