import { FlexStack, Heading, Breadcrumbs, Breadcrumb } from 'components';
import { ReactNode, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@apollo/client';
import { QUERY_ENROLLMENT_BY_ID } from './EnrollmentForm';
import { IDContext } from '../../../core';

interface Props {
  home: string;
  children?: ReactNode;
  homeCaption: string;
}

function EnrollmentFormHeading({ homeCaption, children }: Props) {
  const ctx = useContext(IDContext);
  const { data, loading } = useQuery(QUERY_ENROLLMENT_BY_ID, { skip: !ctx.id, variables: ctx });

  const name = loading ? <Skeleton height='1rem' width={100} /> : data?.node?.name;

  return (
    <FlexStack>
      <Heading>
        {children}
        <Breadcrumbs>
          <Breadcrumb to='..'>{homeCaption}</Breadcrumb>
          <Breadcrumb>{name}</Breadcrumb>
        </Breadcrumbs>
      </Heading>
    </FlexStack>
  );
}

export default EnrollmentFormHeading;
