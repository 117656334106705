import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Search from './AppNav/Search';

function SearchPortal() {
  const [search, setSearch] = useState<HTMLElement | null>();

  useEffect(() => {
    const elem = document.getElementById('search');
    setSearch(elem);
  }, [setSearch]);

  return <>{search && createPortal(<Search />, search)}</>;
}

export default SearchPortal;
