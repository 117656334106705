import { css } from '@emotion/css';
import { ReactNode } from 'react';
import Grid from './Grid';
import useTheme from './Theme/useTheme';
import { GridArea, ThemeBreakpoints } from './index';

type Props = {
  children?: ReactNode;
};

const largeScreenTemplate = `
"heading heading" auto
"content context" auto
"admin context" 1fr
/ 1fr 250px`;

const template = `
"heading"
"content"
"context"
"admin"`;

function MainContent({ children }: Props) {
  const theme = useTheme();

  const rootClass = css({
    backgroundColor: theme.colors.background,
    padding: theme.spacing.lg,
    borderRadius: 10,
  });

  return (
    <Grid template={`"root" auto / 1fr`} responsive={{
      [ThemeBreakpoints.xl]: `
        "root ." auto
        / 1fr 300px`,
    }} gap={20}>
      <GridArea area='root'>
        <div className={rootClass}>
          <Grid template={template} responsive={{ [ThemeBreakpoints.lg]: largeScreenTemplate }} gap={20}>
            {children}
          </Grid>
        </div>
      </GridArea>
    </Grid>
  );
}

export default MainContent;
