import { Routes, Route } from 'react-router-dom';
import { NotFoundPage } from 'pages';
import { Fragment } from 'react';
import {
  Breadcrumb,
  Breadcrumbs,
  GridArea,
  Heading,
  QueryPageContextProvider,
  SearchPortal,
} from '../../components';
import DuesReportPage from './Dues';
import StatementNew from './StatementNew';
import StatementTable from './StatementTable';
import RosterReportPage from './Roster';

function ReportIndexPage() {
  return (
    <Routes>
      <Route path='dues/*' Component={DuesReportPage} />
      <Route path='roster/*' Component={RosterReportPage} />
      <Route path='statement' element={
        <QueryPageContextProvider>
          <SearchPortal />
          <GridArea area='heading'>
            <Heading>
              Statements
              <Breadcrumbs>
                <Breadcrumb to='new'>New statement run</Breadcrumb>
              </Breadcrumbs>
            </Heading>
          </GridArea>
          <GridArea area='content'>
            <StatementTable />
          </GridArea>
        </QueryPageContextProvider>
      } />
      <Route path='statement/new' element={
        <Fragment>
          <GridArea area='heading'>
            <Heading>
              New Statement Run
              <Breadcrumbs>
                <Breadcrumb to='../statement'>Statements</Breadcrumb>
                <Breadcrumb>Run</Breadcrumb>
              </Breadcrumbs>
            </Heading>
          </GridArea>
          <GridArea area='content'>
            <StatementNew />
          </GridArea>
        </Fragment>
      } />
      <Route index Component={NotFoundPage} />
    </Routes>
  );
}

export default ReportIndexPage;
