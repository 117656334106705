import { GridArea, ModelPageProvider, ModelTable } from 'components';
import { ModelColumnInfo } from 'data';
import { Enrollment, EnrollmentType, PAGE_INFO_FIELDS } from 'model';
import EnrollmentTableHeading from './EnrollmentTableHeading';
import gql from 'graphql-tag';
import EnrollmentPageContextProvider from '../../../components/EnrollmentPageContextProvider';

interface Props {
  home: string;
  heading: string;
  type: EnrollmentType;
}

const DuesEnrollmentTypes: Array<EnrollmentType> = ['MemberType', 'County', 'Nempac'];
export const QUERY_ENROLLMENT_TABLE = gql`
  ${PAGE_INFO_FIELDS}
  query QueryEnrollments($query: String!, $first: Int, $sort: EnrollmentSortColumnsEnumeration = ENROLLMENT_DUES, $direction: SortDirection = DESC, $before: String, $after: String) {
    page: enrollments(query: $query, first: $first, sort: $sort, direction: $direction, before: $before, after: $after) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        node {
          id
          ...on Enrollment {
            name
            type
            updatedAt
            dues
          }
        }
      }
    }
  }`;

function EnrollmentTable({ home, heading, type }: Props) {
  const columns: Array<ModelColumnInfo<Enrollment>> = [
    {
      column: 'name',
      enumValue: 'ENROLLMENT_NAME',
    },
    {
      column: 'updatedAt',
      label: 'Updated At',
      enumValue: 'ENROLLMENT_UPDATED_AT',
    },
  ];

  if (DuesEnrollmentTypes.includes(type)) {
    columns.push({
      column: 'dues',
      enumValue: 'ENROLLMENT_DUES',
    });
  }

  return (
    <EnrollmentPageContextProvider type={type}>
      <ModelPageProvider documents={{
        page: QUERY_ENROLLMENT_TABLE,
      }}>
        <GridArea area='heading'>
          <EnrollmentTableHeading home={home}>{heading}</EnrollmentTableHeading>
        </GridArea>
        <GridArea area='content'>
          <ModelTable home={home} columns={columns} />
        </GridArea>
      </ModelPageProvider>
    </EnrollmentPageContextProvider>
  );
}

export default EnrollmentTable;
