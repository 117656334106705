import {
  ButtonHTMLAttributes,
  createElement,
  forwardRef,
  Fragment,
  FunctionComponent,
  ReactNode,
  SVGProps,
} from 'react';
import useButtonStyle from '../hooks/useButtonStyle';
import useLinkStyle from '../hooks/useLinkStyle';
import UIContext from '../shared/UIContext';

type Props = {
  inline?: boolean;
  children?: ReactNode;
  context?: UIContext;
  link?: boolean;
  active?: boolean;
  c2a?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { children, className, link, inline, active, c2a, icon, context = 'info', ...props },
  ref
) {
  const linkStyle = useLinkStyle(className, active);
  const buttonStyle = useButtonStyle(className, context, inline, c2a);

  return (
    <button ref={ref} {...props} className={link ? linkStyle : buttonStyle}>
      {icon ? (
        <Fragment>
          {createElement(icon, { height: 16 })}
          <span>{children}</span>
        </Fragment>
      ) : (
        children
      )}
    </button>
  );
});

export type ButtonProps = Props;
export default Button;
