import { css, cx } from '@emotion/css';
import { ErrorMessage, useFormikContext } from 'formik';
import { Fragment } from 'react';
import { IEntity } from '../../data';
import { useStrings } from '../../hooks';
import Heading from '../Heading';
import useTheme from '../Theme/useTheme';

function ValidationSummary<T extends IEntity>() {
  const { errors, touched } = useFormikContext<T>();
  const theme = useTheme();
  const strings = useStrings();
  const dangerClass = css({
    color: theme.colors.danger,
  });

  const rootClass = cx(
    css({
      flexShrink: 0,
      width: '100%',
    }),
    dangerClass
  );

  const errorMessages = Object.keys(touched)
    .filter(field => Boolean(touched[field as keyof T]) && Boolean(errors[field as keyof T]))
    .map(name => {
      return (
        <li key={name}>
          <ErrorMessage name={name} />
        </li>
      );
    });

  return (
    <div className={rootClass}>
      {errorMessages.length > 0 && Object.keys(errors).length > 0 && (
        <Fragment>
          <Heading as="h4" className={dangerClass}>
            {strings.validationHelpText}
          </Heading>
          <ul>{errorMessages}</ul>
        </Fragment>
      )}
    </div>
  );
}

export default ValidationSummary;
