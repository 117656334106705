import { Heading, Breadcrumbs, Breadcrumb } from 'components';
import { ReactNode } from 'react';
import { ModelHelpers } from 'shared';

interface Props {
  home: string;
  children?: ReactNode;
}

function MemberTableHeading({ children, home }: Props) {
  return (
    <Heading>
      {children}
      <Breadcrumbs>
        <Breadcrumb to={ModelHelpers.newPath(home)}>Create new</Breadcrumb>
      </Breadcrumbs>
    </Heading>
  );
}

export default MemberTableHeading;
