import { css } from '@emotion/css';
import { ReactNode } from 'react';

import { usePopover } from 'hooks';
import { Clickout, CogButton, Popover } from 'components';
import { useTheme } from 'components/hooks';
import DropdownContext from './DropdownContext';

type Props = {
  label: string;
  onClickout?: () => Promise<any>;
  children: ReactNode;
};

function Dropdown({ children, label, onClickout }: Props) {
  const { popover, clickOut, activator, show, hide } = usePopover();
  const theme = useTheme();
  const options = css({
    position: 'relative',
    marginBottom: theme.spacing.sm,
  });

  const handleClickout = () => {
    if (onClickout) {
      onClickout().finally(clickOut.onClickout);
    } else {
      clickOut.onClickout();
    }
  };

  return (
    <div className={options}>
      <CogButton {...activator} label={label} />
      {show && (
        <DropdownContext.Provider value={{ hide }}>
          <Clickout detect={show} onClickout={handleClickout}>
            <Popover {...popover} placement="bottom-end">
              {children}
            </Popover>
          </Clickout>
        </DropdownContext.Provider>
      )}
    </div>
  );
}

export type DropdownProps = Props;
export default Dropdown;
