import { camelCase, kebabCase } from 'lodash';
import { ChangeEvent } from 'react';

class DataHelpers {
  static convertToDataset(value: any): Record<string, any> {
    return Object.keys(value).reduce((map, key) => {
      const normalized = `data-${kebabCase(key)}`;
      map[normalized] = value[key];
      return map;
    }, {} as Record<string, any>);
  }

  static findDataset(e: ChangeEvent<HTMLInputElement>): any | undefined {
    if (e.target instanceof HTMLInputElement && e.target.list) {
      let dataset: any = undefined;
      const found = Array.from(e.target.list.children).some(option => {
        if (option instanceof HTMLOptionElement) {
          dataset = option.dataset;
          return option.value === e.target.value;
        }

        return false;
      });

      if (found && dataset) {
        dataset = Object.keys(dataset).reduce((map, key) => {
          const normalized = camelCase(key);
          map[normalized] = dataset[key];
          return map;
        }, {} as Record<string, any>);
      }

      return found ? dataset : undefined;
    }
  }
}

export default DataHelpers;
