import { Grid } from 'components';
import { ReactNode } from 'react';

const template = `
"method type checkNumber depositSlipNumber"
"description description processedAt processedAt"
"divider divider divider divider"
"transactions transactions . total"
/1fr 1fr`;

interface Props {
  children?: ReactNode;
}

function MemberBatchFormTemplate({ children }: Props) {
  return (
    <Grid gap={14} template={template}>
      {children}
    </Grid>
  );
}

export default MemberBatchFormTemplate;
