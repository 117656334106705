import { ReactNode, useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import { titleCase } from 'title-case';

import { useToggle } from 'hooks';
import { UIContext } from 'shared';
import { Button, Heading, Text } from 'components';
import { ReactComponent as Icon } from 'icons/exclamation-circle-light.svg';

import DialogContext from './DialogContext';
import DialogOptions from './Options';
import useModalFooterStyle from './useModalFooterStyle';
import useModalHeaderStyle from './useModalHeaderStyle';
import useModalContentStyle from './useModalContentStyle';
import useModalStyle from './useModalStyle';

interface Props {
  children?: ReactNode;
}

type State = Record<'message' | 'heading', string> & Record<'context', UIContext>;

function DialogProvider({ children }: Props) {
  const callback = useRef((_ok: boolean) => {});

  const { toggled, set } = useToggle();
  const [values, setValues] = useState<State>({
    heading: '',
    message: '',
    context: 'info',
  });

  const reset = () => {
    set(false);
  };

  const show = useCallback(
    (message: string, options: DialogOptions = { context: 'info', onClosed: _ => {} }) => {
      set(true);
      setValues({
        message,
        heading: options.heading || titleCase(options.context),
        context: options.context,
      });

      callback.current = options.onClosed;
    },
    [set, setValues]
  );

  const doResponse = (ok: boolean) => () => {
    callback.current(ok);
    reset();
  };

  const modalClass = useModalStyle(values.context);
  const headerClass = useModalHeaderStyle(values.context);
  const modalContent = useModalContentStyle();
  const footerClass = useModalFooterStyle();

  return (
    <DialogContext.Provider value={{ show }}>
      <Modal className={modalClass} isOpen={toggled}>
        <header className={headerClass}>
          <Icon height="1rem" />
          <Heading dense as="h4">
            {values.heading}
          </Heading>
        </header>
        <main className={modalContent}>
          <Text>{values.message}</Text>
        </main>
        <footer className={footerClass}>
          <Button link onClick={doResponse(false)}>
            Cancel
          </Button>
          <Button context={values.context} onClick={doResponse(true)}>
            OK
          </Button>
        </footer>
      </Modal>
      {children}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
