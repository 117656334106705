import { ReactNode, useEffect, useRef } from 'react';

type Props = {
  onClickout: () => void;
  detect?: boolean;
  children?: ReactNode;
};

function Clickout({ onClickout, detect, children }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const doMouseDown = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node)) {
        onClickout();
      }
    };

    const doFocusChange = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && !ref.current?.contains(document.activeElement)) {
        onClickout();
      }
    };

    if (detect) {
      window.addEventListener('keyup', doFocusChange);
      window.addEventListener('mousedown', doMouseDown);

      return () => {
        window.removeEventListener('keyup', doFocusChange);
        window.removeEventListener('mousedown', doMouseDown);
      };
    }
  }, [onClickout, detect]);

  return <div ref={ref}>{children}</div>;
}

export default Clickout;
