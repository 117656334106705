import { css, cx } from '@emotion/css';
import Color from 'color';
import { useTheme } from 'components/Theme/hooks';
import { useMemo } from 'react';
import { UIContext } from 'shared';
import useTransition from './useTransition';

function useButtonStyle(
  className?: string,
  context: UIContext = 'info',
  inline: boolean = false,
  c2a: boolean = false
) {
  const theme = useTheme();
  const transition = useTransition('background-color');

  const style = useMemo(() => {
    const inlineStyle = css({
      padding: 0,
    });

    const c2aStyle = css({
      backgroundColor: theme.colors.primary,
      borderColor: Color(theme.colors.primary).darken(0.1).hex(),
      color: theme.colors['primary-contrast'],
      ':hover:not(:disabled)': {
        backgroundColor: Color(theme.colors.primary).darken(0.1).hex(),
        color: theme.colors.white,
      },
    });

    const infoStyle = css({
      backgroundColor: Color(theme.colors.background).darken(0.05).hex(),
      borderColor: Color(theme.colors.background).darken(0.1).hex(),
      ':hover': {
        backgroundColor: Color(theme.colors.background).darken(0.1).hex(),
      },
    });

    const primaryStyle = css({
      borderColor: theme.colors['white-300'],
      color: theme.colors.primary,
      ':hover:not(:disabled)': {
        color: theme.colors['primary-contrast'],
        backgroundColor: theme.colors.primary,
      },
    });

    const dangerStyle = css({
      borderColor: theme.colors['white-300'],
      color: theme.colors.danger,
      ':hover:not(:disabled)': {
        backgroundColor: theme.colors.danger,
        borderColor: theme.colors.danger,
        color: theme.colors['danger-white'],
      },
    });

    return cx(
      className,
      css({
        ...transition,
        alignItems: 'center',
        backgroundColor: theme.colors.background,
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        display: 'flex',
        fontWeight: 600,
        outline: 'none',
        padding: `${theme.spacing.sm} ${theme.spacing.md}`,
        '> :not(:last-child)': {
          marginRight: theme.spacing.sm,
        },
        ':hover:not(:disabled)': {
          cursor: 'pointer',
        },
        ':disabled': {
          cursor: 'not-allowed',
          opacity: 0.5,
        },
      }),
      {
        [dangerStyle]: context === 'danger',
        [primaryStyle]: context === 'primary',
        [infoStyle]: context === 'info',
        [c2aStyle]: c2a,
        [inlineStyle]: inline,
      }
    );
  }, [theme, transition, className, context, inline, c2a]);

  return style;
}

export default useButtonStyle;
