import { EnrollmentDropdown, EnrollmentDropdownProps } from 'components/Enrollment';
import Flex from 'components/Flex';
import TransportError from 'components/TransportError';
import { IDContext } from 'core';
import { useMutation, useQuery, useStrings, useToast } from 'hooks';
import { Set } from 'immutable';
import { ComponentType, createElement, Fragment, useCallback, useContext, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import MemberEnrollmentItem from './MemberEnrollmentItem';

import {
  ComparableEnrollment,
  Enrollment,
  EnrollmentType,
  MemberEnrollment, QUERY_MEMBER_FORM,
} from 'model';
import gql from 'graphql-tag';

interface Props {
  type: EnrollmentType;
  label: string;
  multiple?: boolean;
  as?: ComponentType<MemberEnrollment>;
}

function SelectMemberEnrollment({ type, multiple, label, as }: Props) {
  const context = useContext(IDContext);
  const toast = useToast();
  const strings = useStrings();

  const [update, { loading: updating }] = useMutation(gql`
    mutation MergeMemberEnrollments($id: String!, $adding: [String], $removing: [String]) {
      node: mergeMemberEnrollments(memberId: $id, adding: $adding, removing: $removing) {
        id
      }
    }`);

  const { data, error, loading } = useQuery(QUERY_MEMBER_FORM, {
    variables: {
      id: context.id,
    },
  });

  const selected = useMemo(() => Set<Enrollment>(
    data?.node.enrollments
      .filter((x: Enrollment) => x.type === type) || [],
  ), [data, type]);

  const comparable = useMemo(() => selected
    .map((me: Enrollment) => new ComparableEnrollment(me)), [selected]);

  const doFinished = useCallback(
    async (set: Set<ComparableEnrollment>) => {
      if (loading || updating) {
        return;
      }

      const adding = set.subtract(comparable).map(x => x.id).toArray();
      const removing = comparable.subtract(set).map(x => x.id).toArray();

      // const variables = resolveMemberEnrollments(data.node.enrollments, selected, context.id);
      if (adding.length > 0 || removing.length > 0) {
        const result = await update({
          variables: { id: context.id, adding, removing },
          refetchQueries: [{
            query: QUERY_MEMBER_FORM,
            variables: { id: context.id },
          }],
        });
        if (result.data) {
          toast(strings.saveSuccess);
        } else {
          console.error(result.errors);
          toast(strings.saveFail, {
            context: 'danger',
          });
        }
      }
    },
    [comparable, loading, context, strings, toast, update, updating],
  );

  const dropdownProps: EnrollmentDropdownProps = {
    label,
    selected: comparable,
    multiple,
    type,
    onFinished: doFinished,
  };

  const items = selected.map((me: Enrollment) => (
    <MemberEnrollmentItem key={me.id!} id={me.id!}>
      {as ? createElement(as, { enrollment: me } as any) : me.name}
    </MemberEnrollmentItem>
  ));

  const empty = !items || items.count() === 0;
  const emptyContent = loading ? (
    <Skeleton height='1rem' width={140} />
  ) : (
    <span>No enrollment selected</span>
  );

  return (
    <Fragment>
      <EnrollmentDropdown {...dropdownProps} />
      {items}
      {empty && (
        <Flex>
          <TransportError error={error} />
          {emptyContent}
        </Flex>
      )}
    </Fragment>
  );
}

export default SelectMemberEnrollment;
