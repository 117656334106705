import { IDContext } from 'core';
import { ReactNode, useCallback } from 'react';
import { useParams } from 'react-router';

interface Props {
  children?: ReactNode;
}

function RouteIDContextProvider({ children }: Props) {
  const set = useCallback((_id: string) => {
    throw Error('invalid operation');
  }, []);

  const { id } = useParams();
  if (!id) {
    throw Error('invalid operation');
  }
  const value = { set, id };

  return <IDContext.Provider value={value}>{children}</IDContext.Provider>;
}

export default RouteIDContextProvider;
