import { Grid } from 'components';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

function MemberFormTemplate({ children }: Props) {
  const template = `
"memberNumber . ."
"givenName surname middleInitial"
"birthdate joinedAt title"
"secondary secondary secondary"
/1fr 1fr .5fr`;

  return (
    <Grid template={template} gap={14}>
      {children}
    </Grid>
  );
}

export default MemberFormTemplate;
