import { ModelForm } from 'components';
import { NormalizePosition, PositionDefaultValues } from 'model';
import { usePositionSchema } from 'model/hooks';
import PositionFormTemplate from './PositionFormTemplate';

interface Props {
  home: string;
  waitForLoad?: boolean;
}

function PositionForm({ home, waitForLoad }: Props) {
  const schema = usePositionSchema();
  const props = {
    home,
    schema,
    waitForLoad,
    initialValues: PositionDefaultValues,
    normalize: NormalizePosition,
  };

  return (
    <ModelForm {...props}>
      {ModelField => (
        <PositionFormTemplate>
          <ModelField name="name" />
          <ModelField name="metric" />
        </PositionFormTemplate>
      )}
    </ModelForm>
  );
}

export default PositionForm;
