import { useContext, useCallback } from 'react';
import { ImportContext, reset } from '../context';

interface Props {
  label?: string;
}

const Reset = ({ label = 'Retry' }: Props) => {
  const { dispatch } = useContext(ImportContext);
  const onReset = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);
  return <button onClick={onReset}>{label}</button>;
};

export default Reset;
