import { css } from '@emotion/css';
import { ReactNode } from 'react';
import Authorize from './Authorize';
import GridArea from './GridArea';
import useTheme from './Theme/useTheme';

type Props = {
  children?: ReactNode;
};

function AdminContent({ children }: Props) {
  const theme = useTheme();
  const classes = css({
    marginTop: theme.spacing.lg,
  });

  return (
    <Authorize roles={['admin']} disableChallenge>
      <GridArea className={classes} area="admin">
        {children}
      </GridArea>
    </Authorize>
  );
}

export default AdminContent;
