import { css } from '@emotion/css';
import { HTMLAttributes } from 'react';
import useTheme from '../Theme/useTheme';

type Props = HTMLAttributes<HTMLDivElement>;

function Heading({ children, ...props }: Props) {
  const theme = useTheme();
  const classes = css({
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: theme.spacing.sm,
    borderBottom: `solid 1px ${theme.colors['white-300']}`,
  });

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}

export default Heading;
