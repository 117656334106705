import { IEntity } from 'data';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface Props {
  values: IEntity;
}

export function FormUpdater({ values }: Props) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
}
