import { ModelField } from 'components';
import { useFormikContext } from 'formik';
import { Batch } from 'model';
import { useEffect } from 'react';

function DepositSlipNumberField() {
  const { values, setFieldValue } = useFormikContext<Batch>();

  useEffect(() => {
    if (values.method !== 'check' && values.depositSlipNumber !== 0) {
      setFieldValue('depositSlipNumber', 0);
    }
  }, [values, setFieldValue]);

  const disabled = values.method !== 'check';
  return (
    <ModelField<Batch>
      name='depositSlipNumber'
      label='Deposit Slip #'
      disabled={disabled}
    />
  );
}

export default DepositSlipNumberField;
