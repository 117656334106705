import { css, cx } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { useMemo } from 'react';
import useModalContentStyle from './useModalContentStyle';

function useModalFooterStyle() {
  const theme = useTheme();
  const modalContentClass = useModalContentStyle();
  const className = useMemo(
    () =>
      cx(
        modalContentClass,
        css({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${theme.colors['white-300']}`,
          '> :not(:last-child)': {
            marginRight: theme.spacing.sm,
          },
        })
      ),
    [theme, modalContentClass]
  );

  return className;
}

export default useModalFooterStyle;
