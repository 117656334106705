import { Routes, Route } from 'react-router';
import UploadPage from './upload';
import StatusPage from './status';

const ImportIndexPage = () => (
  <Routes>
    <Route index Component={UploadPage} />
    <Route path="status/:id" Component={StatusPage} />
  </Routes>
);

export default ImportIndexPage;
