import { css, cx } from '@emotion/css';
import useTheme from 'components/Theme/useTheme';
import { useDangerColorStyle } from 'hooks';
import { useMemo } from 'react';
import { UIContext } from 'shared';
import useModalContentStyle from './useModalContentStyle';

function useModalHeaderStyle(context: UIContext) {
  const theme = useTheme();
  const dangerColorClass = useDangerColorStyle();
  const modalContentClass = useModalContentStyle();

  const className = useMemo(
    () =>
      cx(
        modalContentClass,
        css({
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors['white-300']}`,
          display: 'flex',
          '> :first-child': {
            marginRight: theme.spacing.sm,
          },
        }),
        {
          [dangerColorClass]: context === 'danger',
        }
      ),
    [theme, dangerColorClass, modalContentClass, context]
  );

  return className;
}

export default useModalHeaderStyle;
