import { css } from '@emotion/css';
import Input from '../Input';
import { useContext } from 'react';
import { SearchContext } from '../../core';

function Search() {
  const { query, set } = useContext(SearchContext);
  const rootClass = css({
    position: 'relative',
    width: '100%',
  });

  return (
    <div className={rootClass}>
      <Input placeholder='Search...' value={query} onChange={e => set(e.target.value)} />
    </div>
  );
}

export default Search;
