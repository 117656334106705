import { Input, Label } from '../../components';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

interface RosterFieldProps {
  query: string
  setQuery: (query: string) => void,
}

function RosterField({ query, setQuery }: RosterFieldProps) {
  const { data } = useQuery(gql`query RosterEnrollments($query: String!) {
    rosters(query: $query, first: 20) {
      edges {
        node {
          id
          ... on Roster {
            name
            createdAt
          }
        }
      }
    }
  }`, {
    variables: { query },
  });

  const enrollments = data?.rosters.edges.map((x: any) =>
    <option key={x.node.id} value={x.node.name}>
      {DateTime.fromISO(x.node.createdAt).toLocal().toFormat('MM/dd/yyyy hh:mm a')}
    </option>);

  return (
    <>
      <Label htmlFor='roster'>Roster</Label>
      <Input id='roster' list='enrollments' required value={query} onChange={e => setQuery(e.target.value)} />
      <datalist id='enrollments' onChange={alert}>
        {enrollments}
      </datalist>
    </>
  );
}

export default RosterField;
