import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';
import { UIContext } from 'shared';
import useTheme from './Theme/useTheme';

interface Props {
  children?: ReactNode;
  context?: UIContext;
}

function Tag({ children, context }: Props) {
  const theme = useTheme();

  const dangerClass = css({
    borderColor: theme.colors.danger,
    backgroundColor: theme.colors['danger-white'],
  });

  const rootClass = cx(
    css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors['white-500']}`,
      borderRadius: '1rem',
      padding: `.15rem .65rem`,
      marginRight: theme.spacing.sm,
    }),
    {
      [dangerClass]: context === 'danger',
    }
  );

  return <span className={rootClass}>{children}</span>;
}

export default Tag;
