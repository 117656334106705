import { MenuButton } from 'components';
import { ComparableEnrollment } from 'model';
import { ReactComponent as Check } from 'icons/check-regular.svg';

import FlexStack from 'components/FlexStack';

interface Props {
  entity: ComparableEnrollment;
  selected: boolean;
  onSelect?: (enrollment: ComparableEnrollment) => void;
}

function EnrollmentSelect({ entity, onSelect, selected }: Props) {
  const doClick = () => {
    onSelect?.(entity);
  };

  return (
    <MenuButton onClick={doClick}>
      <FlexStack>
        {entity.name}
        {selected && <Check height='.85rem' />}
      </FlexStack>
    </MenuButton>
  );
}

export default EnrollmentSelect;
