import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Divider, Link } from 'components';
import { useEffect } from 'react';
import Section from './_shared/Section';

function LogoutPage() {
  const auth0 = useAuth0();

  useEffect(() => {
    if (auth0.isAuthenticated) {
      auth0.logout({
        returnTo: window.location.origin + '/logout',
      });
    }
  }, [auth0]);

  if (auth0.isLoading) {
    return null;
  }

  return (
    <Flex>
      <Section>
        <h2>{auth0.isAuthenticated ? 'Logging you out' : 'Logout complete'} </h2>
        <p>It is a good idea to close your browser after the logout process is complete.</p>
        {!auth0.isAuthenticated && <p>You have been logged out!</p>}
        <Divider />
        <p>
          <Link button context="primary" to="/">
            Login
          </Link>
        </p>
      </Section>
    </Flex>
  );
}

export default LogoutPage;
