import { css } from '@emotion/css';
import Text from './Text';

function ModelEmpty() {
  const rootClass = css({
    textAlign: 'center',
  });

  return <Text className={rootClass}>No records found.</Text>;
}

export default ModelEmpty;
