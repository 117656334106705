import { useMemo } from 'react';
import { CSSInterpolation } from '@emotion/css';
import { PropertiesHyphen } from 'csstype';

function useTransition(...properties: Array<keyof PropertiesHyphen>) {
  const transition: CSSInterpolation = useMemo(() => {
    return {
      transitionProperty: properties.join(' '),
      transitionDuration: '125ms',
      transitionTimingFunction: 'ease-in-out',
    };
  }, [properties]);

  return transition;
}

export default useTransition;
