import { css } from '@emotion/css';
import useTheme from './Theme/useTheme';

function Divider() {
  const theme = useTheme();
  const classes = css({
    backgroundColor: theme.colors['white-300'],
    margin: `${theme.spacing.lg} 0`,
    height: 1,
  });

  return <div className={classes} />;
}

export default Divider;
