import { ModelNormalize } from 'data';
import { ModelHelpers } from 'shared';
import { Batch } from './Batch';

const BatchNormalize: ModelNormalize<Batch> = {
  egress: ({ method, type, id, memberId, transactions, ...optional }) => ({
    method,
    type,
    transactions: Object.keys(transactions!).reduce((arr, ledger) => {
      const amount = (transactions as any)[ledger] as number;
      if (amount) {
        arr.push({
          ledger,
          amount,
        });
      }

      return arr;
    }, [] as Array<any>),
    memberId,
    processedAt: optional.processedAt ? new Date(Date.parse(optional.processedAt)).toISOString() : undefined,
    description: ModelHelpers.undefinedWhenEmpty(optional.description),
    checkNumber: ModelHelpers.undefinedWhenZero(optional.checkNumber),
    creditCardLastFour: optional.creditCardLastFour ? parseInt(optional.creditCardLastFour as string) : undefined,
    depositSlipNumber: ModelHelpers.undefinedWhenZero(optional.depositSlipNumber),
  }),
  ingress: ({ method, type, id, memberId, transactions, createdAt, updatedAt, ...optional }) => ({
    method,
    type,
    transactions,
    memberId,
    description: ModelHelpers.emptyStringIfNulldefined(optional.description),
    processedAt: ModelHelpers.dateFromTimestampZ(optional.processedAt),
    checkNumber: ModelHelpers.zeroWhenUndefined(optional.checkNumber),
    creditCardLastFour: optional.creditCardLastFour ? optional.creditCardLastFour.toString() : '',
    depositSlipNumber: ModelHelpers.zeroWhenUndefined(optional.depositSlipNumber),
  }),
};

export default BatchNormalize;
