import { ChangeEventHandler, SelectHTMLAttributes, useContext } from 'react';
import useInputStyle from '../../hooks/useInputStyle';
import PageContext from '../../core/PageContext';

type Props = SelectHTMLAttributes<HTMLSelectElement>;

function PageSize({ className, ...props }: Props) {
  const inputClass = useInputStyle(className);
  const paging = useContext(PageContext);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = e => {
    paging.update(state => state.set('first', parseInt(e.target.value)));
  };

  return (
    <select {...props} className={inputClass} onChange={handleChange} value={paging.first}>
      <option value='10'>10</option>
      <option value='25'>25</option>
      <option value='50'>50</option>
    </select>
  );
}

export default PageSize;
