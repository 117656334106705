import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Heading, Text } from 'components';
import Section from './_shared/Section';

function CallbackPage() {
  const auth0 = useAuth0();
  if (auth0.isLoading || !auth0.error) {
    return null;
  }

  return (
    <Flex>
      <Section>
        <Heading as="h2">Login error</Heading>
        <Text>{auth0.error.message}</Text>
      </Section>
    </Flex>
  );
}

export default CallbackPage;
