import { FormatDate } from 'components';

interface Props {
  value: any;
}

function DateCell({ value }: Props) {
  return <FormatDate value={value as string} format="MM/dd/yyyy" />;
}

export default DateCell;
