import { ModelDocuments } from 'shared';
import page from './page.graphql';
import id from './id.graphql';
import merge from './merge.graphql';
import remove from './remove.graphql';

export const PositionDocuments: ModelDocuments = {
  list: {
    page,
  },
  entity: {
    id,
    merge,
    remove,
  },
};
