import { css, CSSInterpolation, cx } from '@emotion/css';
import { createElement } from 'react';
import { ReactNode } from 'react';
import { Property } from 'csstype';
import { ThemeBreakpoints } from './Theme';

type Props = {
  area: string;
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  show?: ThemeBreakpoints[];
  className?: string;
  alignSelf?: Property.AlignSelf;
};

function GridArea({
  area,
  children,
  as = 'div',
  alignSelf = 'start',
  className,
  show = [ThemeBreakpoints.sm],
}: Props) {
  const classes = cx(
    className,
    css(
      {
        alignSelf,
        display: 'none',
        gridArea: area,
        height: '100%',
      },
      show.reduce(
        (css: Partial<Record<ThemeBreakpoints, CSSInterpolation>>, val: ThemeBreakpoints) => {
          css[val] = {
            display: 'initial',
          };
          return css;
        },
        {}
      )
    )
  );

  return createElement(as, { className: classes }, children);
}

export type GridAreaProps = Props;
export default GridArea;
