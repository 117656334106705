import { ModelPageProvider, ModelTable } from '../../components';
import gql from 'graphql-tag';
import { PAGE_INFO_FIELDS } from '../../model';

type StatementFields = {
  id: string,
  name: string,
  downloadUrl: string,
  createdAt: string,
}

const QUERY_STATEMENT_PAGE = gql`
  ${PAGE_INFO_FIELDS}
  query QueryStatementPage($query: String!, $first: Int, $sort: StatementSortColumnsEnumeration, $direction: SortDirection = DESC, $after: String, $before: String) {
    page: statements(query: $query, first: $first, sort: $sort, direction: $direction, after: $after, before: $before) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        node {
          id
          ...on Statement {
            name
            createdAt
            downloadUrl
          }
        }
      }
    }
  }`;

function StatementTable() {
  return (
    <ModelPageProvider documents={{ page: QUERY_STATEMENT_PAGE }}>
      <ModelTable<StatementFields>
        home={'../statement'}
        to={x => process.env.REACT_APP_GRAPH_HOST + x.downloadUrl}
        columns={[{
          column: 'name',
          enumValue: 'STATEMENT_NAME',
        }, {
          column: 'createdAt',
          label: 'Created At',
          enumValue: 'STATEMENT_CREATED_AT',
        }]} />
    </ModelPageProvider>
  );
}

export default StatementTable;
