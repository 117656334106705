import { css } from '@emotion/css';
import FlexStack from '../FlexStack';
import Link from '../Link';
import useTheme from '../Theme/useTheme';
import logo from './logo100.png';

function Brand() {
  const theme = useTheme();
  const classes = css({
    display: 'flex',
    height: 40,
    alignItems: 'center',
    padding: `0 ${theme.spacing.sm}`,
  });

  const titleClass = css({
    marginLeft: theme.spacing.md,
  });

  return (
    <div className={classes}>
      <Link to="/" role="banner">
        <FlexStack arrange="left">
          <img width="30" height="30" src={logo} alt="New Mexico Medical Society" />
          <span className={titleClass}>NMMS</span>
        </FlexStack>
      </Link>
    </div>
  );
}

export default Brand;
