import { cx } from '@emotion/css';
import { DateTime } from 'luxon';
import { TimeHTMLAttributes } from 'react';

type Props = {
  value: string;
} & TimeHTMLAttributes<HTMLElement>;

type TimeUnits = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';

const units: Array<TimeUnits> = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

function Time({ value, className, ...props }: Props) {
  const dateTime = DateTime.fromISO(value);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find(unit => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });

  const classes = cx(className);

  return (
    <time {...props} className={classes} dateTime={value} title={dateTime.toLocaleString()}>
      {relativeFormatter.format(Math.trunc(diff.as(unit)), unit)}
    </time>
  );
}

export default Time;
