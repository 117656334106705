import { FormatDate, GridArea, ModelPageProvider, ModelTable, QueryPageContextProvider } from 'components';
import { ModelColumnInfo } from 'data';
import { MemberTableView, QUERY_MEMBER_TABLE_PAGE } from 'model';
import MemberTableHeading from './components/MemberTableHeading';

interface Props {
  home: string;
}

function MemberTablePage({ home }: Props) {
  const columns: Array<ModelColumnInfo<MemberTableView>> = [
    {
      column: 'memberNumber',
      label: 'Member Number',
      disableSort: true,
    },
    {
      column: 'surname',
      label: 'Last Name',
      enumValue: 'MEMBER_SURNAME',
    },
    {
      column: 'givenName',
      label: 'First Name',
      enumValue: 'MEMBER_GIVEN_NAME',
    },
    {
      column: 'joinedAt',
      label: 'Joined At',
      enumValue: 'MEMBER_JOINED_AT',
      as: FormatDate,
    },
    {
      column: 'totalBalance',
      label: 'Total Balance',
      enumValue: 'MEMBER_BALANCE',
    },
  ];

  return (
    <QueryPageContextProvider>
      <ModelPageProvider documents={{ page: QUERY_MEMBER_TABLE_PAGE }}>
        <GridArea area='heading'>
          <MemberTableHeading home={home}>Members</MemberTableHeading>
        </GridArea>
        <GridArea area='content'>
          <ModelTable columns={columns} home={home} />
        </GridArea>
      </ModelPageProvider>
    </QueryPageContextProvider>
  );
}

export default MemberTablePage;
