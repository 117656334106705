import { DocumentNode } from 'graphql';
import { ReactNode, useContext } from 'react';
import { ModelPageContext, PageContext } from 'core';
import { useQuery } from 'hooks';

interface Props {
  documents: Record<'page', DocumentNode>;
  children?: ReactNode;
}

function ModelPageProvider({ children, documents }: Props) {
  const { update, ...variables } = useContext(PageContext);

  const page = useQuery(documents.page, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const value = {
    loading: page.loading,
    merging: false,
    edges: page.data?.page.edges,
    pageInfo: page.data?.page?.pageInfo,
  };

  return <ModelPageContext.Provider value={value}>{children}</ModelPageContext.Provider>;
}

export default ModelPageProvider;
