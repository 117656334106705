import NotFoundPage from 'pages/404';
import { Routes, Route } from 'react-router-dom';
import MemberNewPaymentPage from './New';

interface Props {
}

function MemberPaymentIndexPage(_: Props) {
  return (
    <Routes>
      <Route
        path='/new'
        element={
          <MemberNewPaymentPage />
        }
      />
      <Route path='/refund' element={<>Refund Batch</>} />
      <Route element={<NotFoundPage />} />
    </Routes>
  );
}

export default MemberPaymentIndexPage;
