import { css } from '@emotion/css';
import { Currency, Flex, Loading, TransportError } from 'components';
import { useQuery } from 'hooks';
import { QUERY_MEMBER_FORM } from '../../../model';

interface Props {
  id: string;
}

function MemberBalance(props: Props) {
  const { data, loading, error } = useQuery(QUERY_MEMBER_FORM, {
    variables: props,
  });

  if (loading) {
    return <Loading />;
  }

  const rootClass = css({
    alignItems: 'center',
  });

  return (
    <Flex className={rootClass}>
      <TransportError error={error} />
      <Currency value={data?.node.totalBalance as number} />
    </Flex>
  );
}

export default MemberBalance;
