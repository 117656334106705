import { ReactNode, useContext, useMemo } from 'react';
import { IDContext, SearchContext } from '../../../../../core';

interface TransactionSearchContextProviderProps {
  children?: ReactNode;
}

function TransactionSearchContextProvider({ children }: TransactionSearchContextProviderProps) {
  const { query, set } = useContext(SearchContext);
  const { id } = useContext(IDContext);
  const taggedQuery = useMemo(() => {
    return `(id:${id}) ${query}`;
  }, [query, id]);

  return (
    <SearchContext.Provider value={{ query: taggedQuery, set }}>
      {children}
    </SearchContext.Provider>
  );
}

export default TransactionSearchContextProvider;
