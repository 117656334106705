import { AnchorHTMLAttributes, ReactNode } from 'react';
import useLinkStyle from '../hooks/useLinkStyle';

type Props = { children: ReactNode } & AnchorHTMLAttributes<HTMLAnchorElement>;

function ExternalLink({ className, children, ...props }: Props) {
  const styles = useLinkStyle(className);
  return (
    <a {...props} className={styles}>
      {children}
    </a>
  );
}

export default ExternalLink;
