import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { localStoragePush, localStorageRead } from 'core/LocalStorageArray';
import { ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const auth0Config: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID!,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: 'profile openid api',
  useRefreshTokens: true,
};

type Props = { children?: ReactNode };

function AuthenticateProvider(props: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.location.href.includes('/callback') && !window.location.href.includes('/logout')) {
      localStoragePush(window.location.href, 'returnTo');
    }
  });

  const onRedirect = useCallback(
    (state?: AppState) => {
      const returnTo = localStorageRead('returnTo');
      let path = state?.returnTo;
      if (returnTo.length) {
        const url = new URL(returnTo[0]);
        path = url.pathname + url.search;
      }
      localStorage.removeItem('returnTo');
      navigate(path || '/');
    },
    [navigate],
  );

  return (
    <Auth0Provider
      {...auth0Config}
      {...props}
      onRedirectCallback={onRedirect}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    />
  );
}

export default AuthenticateProvider;
