import { IModelPageContext, ModelPageContext } from 'core';
import { IEntity } from 'data';
import { useContext } from 'react';

function useModelPage<T extends IEntity>() {
  const context = useContext(ModelPageContext);
  return context as IModelPageContext<T>;
}

export default useModelPage;
