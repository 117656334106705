import gql from 'graphql-tag';

export const PAGE_INFO_FIELDS = gql`
  fragment PageInfoFields on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
    totalCount
  }
`;

export interface PageInfo {
  startCursor?: string;
  endCursor?: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalCount: number;
}
