import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';

import { createTransportClient } from 'core';

interface Props {
  children?: ReactNode;
}

function TransportProvider({ children }: Props) {
  const context = useAuth0();
  const [client, update] = useState(createTransportClient(context));

  useEffect(() => {
    update(createTransportClient(context));
  }, [context, update]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default TransportProvider;
