import { css } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { useMemo } from 'react';

function useAppNavIconStyle() {
  const theme = useTheme();

  const style = useMemo(
    () =>
      css({
        color: theme.colors.black,
      }),
    [theme]
  );

  return style;
}

export default useAppNavIconStyle;
