import { GridArea, AdminContent, DeleteModel } from 'components';
import MemberDashboard from '../components/MemberDashboard';
import MemberEditHeader from '../components/MemberEditHeader';
import MemberEnrollments from '../components/MemberEnrollments';
import MemberForm from '../components/MemberForm';
import { Fragment } from 'react';
import MemberContacts from './components/MemberContacts';
import MemberEducationForm from './components/MemberEducationForm';

function MemberEditHomePage() {
  return (
    <Fragment>
      <GridArea area='heading'>
        <MemberEditHeader />
      </GridArea>
      <GridArea area='content'>
        <MemberDashboard />
        <MemberForm home='/member' />
        <MemberEducationForm />
        <MemberContacts />
      </GridArea>
      <GridArea alignSelf='start' area='context'>
        <MemberEnrollments />
      </GridArea>
      <AdminContent>
        <DeleteModel home='/member' />
      </AdminContent>
    </Fragment>
  );
}

export default MemberEditHomePage;
