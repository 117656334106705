import { css, cx } from '@emotion/css';
import { MouseEventHandler, TdHTMLAttributes, useContext } from 'react';
import { titleCase } from 'title-case';
import PageContext from '../../core/PageContext';
import { IEntity, ModelColumnInfo } from '../../data';
import ModelHelpers from '../../shared/ModelHelpers';
import Button from '../Button';
import FlexStack from '../FlexStack';
import { Cell } from '../Table';
import useTheme from '../Theme/useTheme';

import { ReactComponent as CaretDown } from '../../icons/angle-down-light.svg';
import { ReactComponent as CaretUp } from '../../icons/angle-up-light.svg';

interface Props<T extends IEntity> extends TdHTMLAttributes<HTMLTableCellElement> {
  info: ModelColumnInfo<T>;
}

function ColumnHeader<T extends IEntity>({ info, align = 'left', ...props }: Props<T>) {
  const theme = useTheme();
  const paging = useContext(PageContext);

  const order = ModelHelpers.pagingToAriaSort<T>(info, paging);
  const sort = info.enumValue || info.column;

  const handleClick: MouseEventHandler<HTMLButtonElement> = _ => {
    paging.update(state => {
      if (state.sort === sort) {
        state = state.set('direction', state.direction === 'ASC' ? 'DESC' : 'ASC');
      }

      return state.set('after', undefined).set('before', undefined).set('sort', sort);
    });
  };

  const iconClass = css({
    height: 14,
    margin: '-3px 0',
    opacity: 0.25,
  });

  const activeSortIconClass = css({
    opacity: 1,
  });

  const ascendingClass = cx(iconClass, {
    [activeSortIconClass]: order === 'ascending',
  });

  const descendingClass = cx(iconClass, {
    [activeSortIconClass]: order === 'descending',
  });

  const headingClass = css({
    flex: 1,
  });

  const iconGroupClass = css({
    flex: 0,
    flexDirection: 'column',
    marginLeft: theme.spacing.sm,
  });

  const label = info.label || titleCase(info.column.replace('_', ' '));

  return (
    <Cell {...props} align={align} header aria-sort={order}>
      {info.disableSort ? (
        label
      ) : (
        <Button link onClick={handleClick}>
          <FlexStack arrange='left'>
            <div className={headingClass}>{label}</div>
            <FlexStack className={iconGroupClass}>
              <CaretUp className={ascendingClass} />
              <CaretDown className={descendingClass} />
            </FlexStack>
          </FlexStack>
        </Button>
      )}
    </Cell>
  );
}

export type ColumnHeaderProps<T extends IEntity> = Props<T>;
export default ColumnHeader;
