import gql from 'graphql-tag';
import { PAGE_INFO_FIELDS } from '../PageInfo';
import { IEntity } from '../../data';

export const MEMBER_TABLE_FIELDS = gql`
  fragment MemberTableFields on Member {
    id
    createdAt
    updatedAt
    joinedAt
    memberNumber
    givenName
    surname
    totalBalance
  }`;

export const QUERY_MEMBER_TABLE_PAGE = gql`
  ${PAGE_INFO_FIELDS}
  ${MEMBER_TABLE_FIELDS}
  query QueryMemberTablePage($query: String = "", $sort: MemberSortColumnsEnumeration = MEMBER_SURNAME, $direction: SortDirection, $first: Int, $before: String, $after: String) {
    page: members(query: $query, sort: $sort, direction: $direction, first: $first, after: $after, before: $before) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        node {
          ...MemberTableFields
        }
      }
    }
  }`;

export interface MemberTableView extends IEntity {
  joinedAt: string;
  memberNumber: number;
  givenName: string;
  surname: string;
  totalBalance: number;
}
