import { useState } from 'react';
import { Button, Fieldset, Flex, Grid, GridArea, Heading, Link } from '../../components';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useToast } from '../../hooks';
import { css } from '@emotion/css';
import RosterField from './RosterField';
import useTheme from '../../components/Theme/useTheme';

function RosterReportPage() {
  const [query, setQuery] = useState('All Members');
  const [dirty, setDirty] = useState(false);
  const theme = useTheme();
  const toaster = useToast();

  const [update, { data: preAuth, loading }] = useMutation(gql`
    mutation CreatePreAuthRosterLink(
      $query: String!) {
      url: preAuthRosterReportUrl(roster: $query)
    }`);

  return (
    <form onSubmit={e => {
      e.preventDefault();
      setDirty(false);
      update({ variables: { query } }).catch(e => {
        console.error(e.message);
        toaster(`Failed to generate the report. ${e.message}`, {
          context: 'danger',
        });
      });
    }}>
      <Heading>Roster Report</Heading>
      <Fieldset onChange={_ => setDirty(true)}>
        <Grid template={`
        "roster roster roster"
        "actions . ."
        /1fr 1fr 80px`} gap={16}>
          <GridArea area='roster'>
            <RosterField query={query} setQuery={setQuery} />
          </GridArea>
          <GridArea area='actions'>
            <Flex>
              <Button type='submit' disabled={loading}>
                Generate
              </Button>
              {!dirty && preAuth && (
                <Link className={css({
                  marginLeft: theme.spacing.sm,
                })} to={process.env.REACT_APP_GRAPH_HOST + preAuth.url} target='_blank' button>
                  Download
                </Link>
              )}
            </Flex>
          </GridArea>
        </Grid>
      </Fieldset>
    </form>
  );
}

export default RosterReportPage;
