import { css } from '@emotion/css';
import useSrOnlyStyle from '../../hooks/useSrOnlyStyle';
import { UserNotification } from '../../model';
import Button from '../Button';
import Divider from '../Divider';
import Flex from '../Flex';
import FlexStack from '../FlexStack';
import Heading from '../Heading';
import Text from '../Text';
import useTheme from '../Theme/useTheme';
import Time from '../Time';
import { ReactComponent as Check } from '../../icons/check-regular.svg';
import { useModelEntity } from 'hooks';

type Props = {
  entity: UserNotification;
};

function Notification({ entity }: Props) {
  const theme = useTheme();
  const srOnly = useSrOnlyStyle();
  const { merge } = useModelEntity<UserNotification>();

  const handleClick = async () => {
    await merge(entity.id!, { is_read: true });
  };

  const rootClass = css({
    flexDirection: 'column',
    padding: theme.spacing.md,
    width: 350,
  });

  const timeClass = css({
    paddingTop: theme.spacing.sm,
  });

  const contentClass = css({
    paddingTop: theme.spacing.md,
  });

  return (
    <Flex className={rootClass}>
      <Heading dense as="h3">
        <FlexStack>
          <span>{entity.notification.title}</span>
          <Button link onClick={handleClick}>
            <Check width="24" />
            <span className={srOnly}>Mark as read</span>
          </Button>
        </FlexStack>
      </Heading>
      <Time className={timeClass} value={entity.createdAt!} />
      <Text className={contentClass}>{entity.notification.content}</Text>
      <Divider />
    </Flex>
  );
}

export default Notification;
