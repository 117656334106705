import { css } from '@emotion/css';
import { useFormikContext } from 'formik';
import Button from '../Button';
import FlexStack from '../FlexStack';
import Link from '../Link';
import useTheme from '../Theme/useTheme';

type Props = {
  showNewLink?: boolean;
  disabled?: boolean;
  home: string;
};

function ModelSave({ showNewLink, disabled, home }: Props) {
  const { isValid } = useFormikContext();
  const theme = useTheme();

  return (
    <FlexStack arrange="right">
      {showNewLink && (
        <Link to={`${home}/new`} className={css({ marginRight: theme.spacing.md })}>
          New
        </Link>
      )}
      <Button disabled={disabled || !isValid} type="submit" context="primary">
        Save
      </Button>
    </FlexStack>
  );
}

export default ModelSave;
