import Skeleton from 'react-loading-skeleton';

import { Breadcrumb, Breadcrumbs, Heading } from 'components';
import { useModelEntity } from 'hooks';
import { Position } from 'model';

interface Props {
  home: string;
}

function PositionEditHeading({ home }: Props) {
  const { entity, loading } = useModelEntity<Position>();
  const content = loading ? <Skeleton height="1rem" width={100} /> : entity?.name;

  return (
    <Heading>
      Position
      <Breadcrumbs>
        <Breadcrumb to={home}>Positions</Breadcrumb>
        <Breadcrumb>{content}</Breadcrumb>
      </Breadcrumbs>
    </Heading>
  );
}

export default PositionEditHeading;
