import { css, cx } from '@emotion/css';
import { createElement, HTMLAttributes, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import useTheme from './Theme/useTheme';

type Elements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = {
  as?: Elements;
  dense?: boolean;
  children?: ReactNode;
  skeleton?: boolean;
} & HTMLAttributes<HTMLHeadingElement>;

function Heading({ as = 'h1', className, children, skeleton, dense, ...props }: Props) {
  const theme = useTheme();
  const denseStyles = css({
    marginBottom: 0,
  });

  const heights: Record<Elements, string> = {
    h1: '1.8rem',
    h2: '1.4rem',
    h3: '1.15rem',
    h4: '.95rem',
    h5: '.87rem',
    h6: '.8rem',
  };

  const classes = cx(
    css({
      color: theme.colors.black,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      marginBottom: theme.spacing.md,
    }),
    className,
    {
      [denseStyles]: dense,
    }
  );

  if (skeleton) {
    return <Skeleton height={heights[as]} width={80} className={classes} />;
  }

  return createElement(as, { className: classes, ...props }, children);
}

export default Heading;
