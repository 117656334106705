import { Fragment, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog, useStrings, useToast } from '../../hooks';
import Button from '../Button';
import Fieldset from '../Fieldset';
import Grid from '../Grid';
import GridArea from '../GridArea';
import Heading from '../Heading';
import Text from '../Text';
import { IDContext } from '../../core';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface Props {
  home: string;
}

function DeleteModel(_: Props) {
  const { id } = useContext(IDContext);
  const strings = useStrings();
  const dialog = useDialog();
  const toast = useToast();
  const navigate = useNavigate();

  const [remove] = useMutation(gql`
    mutation RemoveNode($id: String!) {
      node: remove(id: $id) {
        id
      }
    }`);

  const doClosed = useCallback(
    async (ok: boolean) => {
      if (ok) {
        try {
          const result = await remove({
            variables: { id },
            refetchQueries: ['active'],
          });
          if (result) {
            navigate('..');
          }
        } catch (err: any) {
          toast(err.message, { context: 'danger' });
        }
      }
    },
    [id, remove, navigate, toast],
  );

  if (!id) {
    return null;
  }

  const onDelete = async () => {
    dialog(strings.confirmDeleteRecord, {
      context: 'danger',
      onClosed: doClosed,
    });
  };

  return (
    <Fragment>
      <Heading as='h3'>Danger Zone</Heading>
      <Fieldset context='danger'>
        <Grid template={`"description button" / 1fr auto`}>
          <GridArea area='description'>
            <Heading as='h4' dense>
              Delete Record
            </Heading>
            <Text>This operation cannot be undone.</Text>
          </GridArea>
          <GridArea alignSelf='center' area='button' as='span'>
            <Button onClick={onDelete} type='reset' context='danger'>
              Delete
            </Button>
          </GridArea>
        </Grid>
      </Fieldset>
    </Fragment>
  );
}

export default DeleteModel;
