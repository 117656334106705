import { TaggedSearchState, Actions, initTaggedSearchState } from "./Types";

export const init = (initialState: TaggedSearchState) => initialState;

function getNextIndex(
  current: number,
  lastIndex: number,
  direction: 'ArrowLeft' | 'ArrowRight'
): number {
  switch (direction) {
    case 'ArrowLeft':
      return Math.max(0, current - 1);
    case 'ArrowRight':
      return Math.min(lastIndex, current + 1);
  }
}

export function taggedSearchReducer(state: TaggedSearchState, action: Actions) {
  switch (action.type) {
    case 'ActivateTag':
      return state;
    case 'BlurActiveTag':
      return state;
    case 'SelectTag':
      return { ...state, tags: [...state.tags, action.tag], activeTagIndex: state.tags.length };
    case 'DeleteTag':
      return { ...state, tags: state.tags.filter(t => t.optionID !== action.optionID), activeTagIndex: null };
    case 'ValueChange':
      return { ...state, value: action.value };
    case 'TagValueChange':
      state.tags[action.index].value = action.value;
      return { ...state, activeTagIndex: null, value: '' };
    case 'SetActiveIndex':
      return { ...state, activeTagIndex: action.index };
    case 'InitState':
      return init(action.initState);
    case 'ClearState':
      return initTaggedSearchState;
    case 'Tab':
      const lastIndex = state.tags.length - 1;
      // If we are tab navigating and we are on the edge in either direction, we should not update
      // state but rather allow the native tab behavior.
      if (state.activeTagIndex === null
        || (action.backward && state.activeTagIndex === 0)
        || (!action.backward && state.activeTagIndex === lastIndex)
      ) {
        return state;
      }

      // From here we capture the native tab behavior and imperatively tab through the tags.
      action.event.preventDefault();
      return {
        ...state,
        activeTagIndex: getNextIndex(
          state.activeTagIndex,
          lastIndex,
          action.backward ? 'ArrowLeft' : 'ArrowRight'
        ),
      };
    case 'ArrowRight':
    case 'ArrowLeft':
      if (state.activeTagIndex === null) {
        return state;
      }
      return {
        ...state,
        activeTagIndex: getNextIndex(state.activeTagIndex, state.tags.length - 1, action.type),
      };
    case 'Nothing':
    default:
      return state;
  }
}
