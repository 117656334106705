import { enrollmentQuery, positionBaseQuery } from 'core/AutoComplete/AutoCompleteQueries';
import { NotFoundPage } from 'pages';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import CommitteePositionIndexPage from './committee/position';
import DuesIndexPage from './components/DuesIndexPage';
import DoctorIndexPage from './doctor';

function SetupIndex() {
  const location = useLocation();
  const path = location?.pathname;

  return (
    <Routes>
      <Route index element={<Navigate to='/setup/county' />} />
      <Route
        path='county/*'
        element={
          <DuesIndexPage
            type='County'
            name='County'
            baseQuery={enrollmentQuery('County')}
            baseURL={path}
          />
        }
      />
      <Route
        path='type/*'
        element={
          <DuesIndexPage
            type='MemberType'
            name='Member Type'
            baseQuery={enrollmentQuery('type')}
            baseURL={path}
          />
        }
      />
      <Route
        path='position/*'
        element={
          <CommitteePositionIndexPage
            type='position'
            baseURL={path}
            baseQuery={positionBaseQuery}
          />
        }
      />
      <Route path='doctor/*' Component={DoctorIndexPage} />
      <Route path='*' Component={NotFoundPage} />
    </Routes>
  );
}

export default SetupIndex;
