import { useCallback, useState } from 'react';

function useToggle() {
  const [toggled, set] = useState(false);
  const toggle = useCallback(() => set(state => !state), [set]);

  return {
    toggled,
    toggle,
    set,
  };
}

export default useToggle;
