import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import { ImportAction } from './ImportActions';
import { ImportState, initialState, reducer } from './ImportReducer';

interface Props {}

export type ImportContextType = {
  state: ImportState;
  dispatch: Dispatch<ImportAction>;
};

const defaultContext: ImportContextType = {
  state: initialState,
  dispatch: () => {},
};

export const ImportContext: React.Context<ImportContextType> =
  createContext<ImportContextType>(defaultContext);

export const ImportContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ImportContext.Provider value={{ state, dispatch }}>{children}</ImportContext.Provider>;
};
