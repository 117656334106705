import { css, cx } from '@emotion/css';
import useTheme from 'components/Theme/useTheme';
import { useDangerBorderStyle } from 'hooks';
import { useMemo } from 'react';
import { UIContext } from 'shared';

function useModalStyle(context: UIContext) {
  const theme = useTheme();
  const dangerBorderClass = useDangerBorderStyle();
  const className = useMemo(
    () =>
      cx(
        css({
          backgroundColor: 'white',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors['white-300'],
          borderRadius: 5,
          boxShadow: theme.shadows[16],
          maxWidth: 400,
          left: '50%',
          top: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
          position: 'absolute',
        }),
        {
          [dangerBorderClass]: context === 'danger',
        }
      ),
    [theme, context, dangerBorderClass]
  );

  return className;
}

export default useModalStyle;
