import gql from 'graphql-tag';
import {
  Authorize, Breadcrumb, Breadcrumbs,
  FormatDate, GridArea,
  Heading,
  ModelPageProvider,
  ModelTable,
  QueryPageContextProvider, SearchPortal,
} from '../../components';
import { PAGE_INFO_FIELDS } from '../../model';

export const ROSTER_TABLE_FIELDS = gql`
  fragment RosterTableFields on Roster {
    id
    name
    createdAt
  }`;
export type RosterTableView = {
  id: string
  name: string
  createdAt: string
}

export const QUERY_ROSTER_TABLE = gql`
  ${PAGE_INFO_FIELDS}
  ${ROSTER_TABLE_FIELDS}
  query RosterEnrollments($query: String!, $first: Int = 20) {
    page: rosters(query: $query, first: $first) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        node {
          ...RosterTableFields
        }
      }
    }
  }`;

function RosterTable() {
  return (
    <QueryPageContextProvider>
      <SearchPortal />
      <ModelPageProvider documents={{ page: QUERY_ROSTER_TABLE }}>
        <GridArea area='heading'>
          <Heading>
            Rosters
            <Authorize roles={['admin', 'manager']}>
              <Breadcrumbs>
                <Breadcrumb to={'new'}>Create new</Breadcrumb>
              </Breadcrumbs>
            </Authorize>
          </Heading>
        </GridArea>
        <GridArea area='content'>
          <ModelTable<RosterTableView> home={'.'} columns={[{
            column: 'name',
            disableSort: true,
          }, {
            column: 'createdAt',
            label: 'Created At',
            disableSort: true,
            as: FormatDate,
          }]} />
        </GridArea>
      </ModelPageProvider>
    </QueryPageContextProvider>
  );
}

export default RosterTable;
