import { createContext } from 'react';
import ISearchContext from './ISearchContext';

const SearchContext = createContext<ISearchContext>({
  query: '',
  set: _ => {
  },
});

export default SearchContext;
