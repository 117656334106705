import { DateTime } from 'luxon';
import { AriaAttributes } from 'react';
import { IPageContext } from '../core';
import { IEntity, ModelColumnInfo } from '../data';

class ModelHelpers {
  static readonly emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  static readonly uuidParam: string = `:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`;

  static pagingToAriaSort<T extends IEntity>(
    info: ModelColumnInfo<T>,
    paging: IPageContext,
  ): AriaAttributes['aria-sort'] {
    const ariaSortMap: Record<'ASC' | 'DESC', AriaAttributes['aria-sort']> = {
      DESC: 'descending',
      ASC: 'ascending',
    };

    if (!paging.sort) {
      return 'none';
    }

    const order = paging.sort;
    if (info.enumValue === order) {
      return ariaSortMap[paging.direction || 'ASC'];
    }

    return 'none';
  }

  static newPath(path: string) {
    return `${path}/new`;
  }

  static dateFromTimestampZ(value?: string | null) {
    if (typeof value === 'string') {
      const maybe = DateTime.fromISO(value);
      return maybe.isValid ? maybe.toFormat('yyyy-MM-dd') : '';
    }

    return '';
  }

  static timestampFromDate(value?: string | null) {
    if (!value) {
      return undefined;
    }
    const maybe = DateTime.fromFormat(value, 'yyyy-MM-dd');
    return maybe.isValid ? maybe.toISO() : undefined;
  }

  static emptyStringIfNulldefined(value?: string | null) {
    return typeof value === 'string' ? value : '';
  }

  static zeroWhenUndefined(value?: number) {
    return typeof value === 'undefined' ? 0 : value;
  }

  static undefinedWhenZero(value?: number) {
    return value === 0 ? undefined : value;
  }

  static undefinedWhenEmpty(value?: string | null) {
    return value || undefined;
  }
}

export default ModelHelpers;
