import { User } from '@auth0/auth0-react';
import { OrderedSet, Set } from 'immutable';

const MedsocRoles = OrderedSet(['admin', 'manager', 'user']);

class MedsocUser {
  readonly kClaimsKey = 'https://msmem.nmms.org/roles';

  constructor(private user?: User) {
  }

  hasRole(...roles: string[]) {
    return this.getRoleSet().intersect(roles).count() > 0;
  }

  getDefaultRole() {
    return MedsocRoles.intersect(this.getRoleSet()).first();
  }

  getRoleSet() {
    const { user, kClaimsKey } = this;

    if (
      user &&
      kClaimsKey in user &&
      Array.isArray(user[kClaimsKey])
    ) {
      return Set<string>(user[kClaimsKey]);
    }

    return Set<string>([]);
  }
}

export default MedsocUser;
