import { useEffect, useState } from 'react';
import { Set } from 'immutable';

import { ComparableEnrollment, Enrollment, EnrollmentType } from 'model';
import { Dropdown, EnrollmentSearchContextProvider } from 'components';

import EnrollmentMenu from './EnrollmentMenu';

interface Props {
  type: EnrollmentType;
  label: string;
  selected: Set<Enrollment>;
  multiple?: boolean;
  onFinished: (selected: Set<ComparableEnrollment>) => Promise<void>;
}

function EnrollmentDropdown({ type, label, multiple, onFinished, selected }: Props) {
  const [enrollments, setEnrollment] = useState(selected.map(x => new ComparableEnrollment(x)));

  useEffect(() => {
    setEnrollment(selected.map(x => new ComparableEnrollment(x)));
  }, [selected, setEnrollment]);

  const doClickout = () => onFinished?.(enrollments);

  const doSelect = (enrollment: ComparableEnrollment) => {
    setEnrollment(state =>
      multiple
        ? state.has(enrollment)
          ? state.remove(enrollment)
          : state.add(enrollment)
        : state.has(enrollment)
          ? state.remove(enrollment)
          : state.clear().add(enrollment),
    );
  };

  return (
    <EnrollmentSearchContextProvider type={type}>
      <Dropdown label={label} onClickout={doClickout}>
        <EnrollmentMenu
          selected={enrollments}
          multiple={multiple}
          onSelect={doSelect}
          type={type}
          label={label}
        />
      </Dropdown>
    </EnrollmentSearchContextProvider>
  );
}

export type EnrollmentDropdownProps = Props;
export default EnrollmentDropdown;
