import { createContext } from 'react';
import IPageContext from './IPageContext';

const PageContext = createContext<IPageContext>({
  query: undefined,
  after: undefined,
  before: undefined,
  sort: undefined,
  direction: undefined,
  first: 10,
  update(_next) {
  },
});

export default PageContext;
