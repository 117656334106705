import { Divider, GridArea, ModelForm, ModelFormProps, Select, TextArea } from 'components';
import { Member, MemberDefaultValues, MemberNormalize, CreateMemberNormalize } from 'model';
import { useMemberSchema } from 'model';
import MemberFormTemplate from './MemberFormTemplate';

interface Props {
  home: string;
  waitForLoad?: boolean;
}

function MemberForm({ home, waitForLoad = true }: Props) {
  const schema = useMemberSchema();

  const props: ModelFormProps<Member> = {
    home,
    schema,
    waitForLoad,
    normalize: waitForLoad ? MemberNormalize : CreateMemberNormalize,
    initialValues: MemberDefaultValues,
  };

  return (
    <ModelForm {...props}>
      {ModelField => (
        <MemberFormTemplate>
          <ModelField name='memberNumber' label={'Member Number'} disabled />
          <ModelField name='givenName' label='First Name' />
          <ModelField name='surname' label='Last Name' />
          <ModelField name={'middleInitial'} label='Middle Initial' />
          <ModelField name='birthdate' type='date' />
          <ModelField name='joinedAt' type='date' label='Joined On' />
          <ModelField name='title' as={Select}>
            <option value=''>No title</option>
            <option value='Mr'>Mr</option>
            <option value='Mrs'>Mrs</option>
            <option value='Dr'>Dr</option>
            <option value='MD'>MD</option>
            <option value='JD'>JD</option>
            <option value='DO'>DO</option>
          </ModelField>
          <GridArea area='secondary'>
            <Divider />
            <ModelField as={TextArea} name='remarks' rows={10} />
          </GridArea>
        </MemberFormTemplate>
      )}
    </ModelForm>
  );
}

export default MemberForm;
