import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import useToast from './useToast';

function useErrorHandler() {
  const toast = useToast();

  const handler = useCallback(
    (error: ApolloError | undefined) => {
      if (error) {
        console.error(error);
        toast(error.message, { context: 'danger' });
      }
    },
    [toast]
  );

  return handler;
}

export default useErrorHandler;
