import { IEntity } from 'data';
import { createContext } from 'react';
import IModelPageContext from './IModelPageContext';

const ModelPageContext = createContext<IModelPageContext<IEntity>>({
  loading: false,
  merging: false,
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    endCursor: undefined,
    startCursor: undefined,
    totalCount: 0,
  },
  edges: undefined,
});

export default ModelPageContext;
