import { useContext } from 'react';
import Button, { ButtonProps } from '../Button';
import PageContext from '../../core/PageContext';
import { useModelPage } from '../../hooks';

type Props = Omit<ButtonProps, 'onClick' | 'disabled'>;

function BackPage(props: Props) {
  const paging = useContext(PageContext);
  const { pageInfo } = useModelPage();

  const handleClick = () => {
    paging.update(state => state.set('after', undefined).set('before', pageInfo?.startCursor));
  };

  return (
    <Button {...props} onClick={handleClick} disabled={!pageInfo?.hasPreviousPage}>
      Back
    </Button>
  );
}

export default BackPage;
