import { css, cx } from '@emotion/css';
import { useTheme } from 'components/hooks';
import { ReactNode } from 'react';
import {
  ThemeBreakpoints,
  Grid,
  GridArea,
  AppNav,
  AppNavMenu,
  MainContent,
  Link,
  ExternalLink,
  GridAreaProps,
  Authorize,
} from 'components';
import { Outlet } from 'react-router-dom';
import RootSearchContextProvider from '../components/SearchContext/RootSearchContextProvider';

const template = `
"nav" 56px
"main" auto
"footer" auto
`;

const responsive: Partial<Record<ThemeBreakpoints, string>> = {
  [ThemeBreakpoints.lg]: `
"aside nav" 56px
"aside main" auto
"aside footer" 100px
/ 220px 1fr`,
};

type Props = {
  children?: ReactNode;
};

function Layout(_: Props) {
  const theme = useTheme();
  const padded = css({
    padding: theme.spacing.sm,
  });

  const aside: GridAreaProps = {
    className: cx(
      padded,
      css({
        height: '100%',
      }),
    ),
    alignSelf: 'start',
    as: 'aside',
    area: 'aside',
    show: [ThemeBreakpoints.lg],
  };

  const nav: GridAreaProps = {
    as: 'nav',
    area: 'nav',
    className: padded,
  };

  const main: GridAreaProps = {
    alignSelf: 'start',
    as: 'main',
    area: 'main',
    className: cx(
      padded,
      css({
        paddingTop: 0,
      }),
    ),
  };

  const footer: GridAreaProps = {
    as: 'footer',
    area: 'footer',
    className: padded,
  };

  return (
    <Authorize>
      <RootSearchContextProvider>
        <Grid template={template} responsive={responsive}>
          <GridArea {...nav}>
            <AppNav />
          </GridArea>
          <GridArea {...aside}>
            <AppNavMenu />
          </GridArea>
          <GridArea {...main}>
            <MainContent>
              <Outlet />
            </MainContent>
          </GridArea>
          <GridArea {...footer}>
            <Link to='/legal'>Terms & Conditions</Link>
            <br />
            Maintained with ❤️ by the software team at{' '}
            <ExternalLink href='https://iteamnm.com' target='_blank'>
              iTEAM Consulting
            </ExternalLink>
          </GridArea>
        </Grid>
      </RootSearchContextProvider>
    </Authorize>
  );
}

export default Layout;
