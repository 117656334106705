import { IEntity, ModelColumnInfo } from '../../data';
import ColumnHeader from './ColumnHeader';

interface Props<T extends IEntity> {
  columns: Array<ModelColumnInfo<T>>;
}

function ModelTableHead<T extends IEntity>({ columns }: Props<T>) {
  const headers = columns.map((info, idx) => <ColumnHeader key={idx} info={info} />);
  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  );
}

export default ModelTableHead;
