import { EnrollmentType } from '../model';
import { ReactNode, useContext, useMemo } from 'react';
import { PageContext } from '../core';

interface EnrollmentPageContextProviderProps {
  type: EnrollmentType,
  children?: ReactNode
}

function EnrollmentPageContextProvider({ type, children }: EnrollmentPageContextProviderProps) {
  const { query, ...rest } = useContext(PageContext);

  // mutate the query to include the type at all times
  const enhancedQuery = useMemo(() => {
    return `(type:${type}) ${query}`;
  }, [query, type]);

  return (
    <PageContext.Provider value={{ query: enhancedQuery, ...rest }}>
      {children}
    </PageContext.Provider>
  );
}

export default EnrollmentPageContextProvider;
