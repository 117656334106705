import { ApolloError } from '@apollo/client';
import { css } from '@emotion/css';

import { useDialog, useSrOnlyStyle } from 'hooks';
import { ReactComponent as Icon } from 'icons/exclamation-circle-light.svg';
import useTheme from './Theme/useTheme';

interface Props {
  error?: ApolloError;
}

function TransportError({ error }: Props) {
  const dialog = useDialog();
  const theme = useTheme();
  const srOnly = useSrOnlyStyle();

  if (!error) {
    return null;
  }

  const rootClass = css({
    cursor: 'pointer',
    color: theme.colors.danger,
    marginRight: theme.spacing.sm,
  });

  const doClick = () => {
    dialog(error.message, {
      context: 'danger',
      onClosed: _ => {},
    });
  };

  return (
    <span className={rootClass} onClick={doClick}>
      <span className={srOnly}>{error.message}</span>
      <Icon height="1rem" />
    </span>
  );
}

export default TransportError;
