import { Link as RRLink, LinkProps } from 'react-router-dom';
import useButtonStyle from '../hooks/useButtonStyle';
import useLinkStyle from '../hooks/useLinkStyle';
import UIContext from '../shared/UIContext';

type Props = {
  button?: boolean;
  context?: UIContext;
  c2a?: boolean;
} & LinkProps;

function Link({ className, button, context, c2a = false, ...props }: Props) {
  const linkClass = useLinkStyle(className);
  const buttonClass = useButtonStyle(linkClass, context, false, c2a);

  return <RRLink {...props} className={button ? buttonClass : linkClass} />;
}

export default Link;
