import { css } from '@emotion/css';
import { useContext } from 'react';
import Reset from './components/Reset';
import { ImportContext } from './context';

const container = css`
  background: #7dff7d59;
  border: 2px solid #409f40;
`;

const heading = css`
  color: #125312;
  margin-top: 0;
`;

const FileSuccess = () => {
  const {
    state: { success, accepted },
  } = useContext(ImportContext);

  if (!success || !accepted || accepted.length < 1) {
    return null;
  }

  const filename = accepted[0].name;
  return (
    <div className={container}>
      <h2 className={heading}>Successfully uploaded '{filename}'</h2>
      <Reset label='Upload Another' />
    </div>
  );
};

export default FileSuccess;
