import { css } from '@emotion/css';
import { Currency, Flex, Loading, TransportError } from 'components';
import { useQuery } from 'hooks';
import { QUERY_MEMBER_FORM } from '../../../model';

interface Props {
  id: string;
}

function MemberCurrentNempac(props: Props) {
  const sub = useQuery(QUERY_MEMBER_FORM, {
    variables: props,
  });

  if (sub.loading) {
    return <Loading />;
  }

  const rootClass = css({
    alignItems: 'center',
  });

  return (
    <Flex className={rootClass}>
      <TransportError error={sub.error} />
      <Currency value={sub.data?.node?.currentNempacDonation} />
    </Flex>
  );
}

export default MemberCurrentNempac;
