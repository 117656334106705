import { css, cx } from '@emotion/css';
import { ReactNodeArray, HTMLAttributes, ReactNode } from 'react';

type Props = {
  arrange?: 'split' | 'left' | 'right';
  children?: ReactNodeArray | ReactNode;
} & HTMLAttributes<HTMLDivElement>;

function FlexStack({ children, arrange = 'split', className, ...props }: Props) {
  const left = css({
    justifyItems: 'start',
    '> :last-child': {
      marginRight: 'auto',
    },
  });

  const right = css({
    justifyItems: 'end',
    '> :first-child': {
      marginLeft: 'auto',
    },
  });

  const split = css({
    justifyContent: 'space-between',
  });

  const rootClass = cx(
    css({
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    }),
    className,
    {
      [left]: arrange === 'left',
      [right]: arrange === 'right',
      [split]: arrange === 'split',
    }
  );

  return (
    <div {...props} className={rootClass}>
      {children}
    </div>
  );
}

export default FlexStack;
