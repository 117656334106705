import { useContext } from 'react';
import { ImportContext } from './context';
import { css } from '@emotion/css';

const container = css`
  animation: gradient 2500ms ease infinite;
  background: linear-gradient(45deg, #708aaf, #98b0d1, #c3d1e5, #e8f0fb);
  background-size: 400% 400%;
  border: 2px solid #708aaf;

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const FileLoading = () => {
  const {
    state: { loading },
  } = useContext(ImportContext);

  if (!loading) {
    return null;
  }

  return (
    <div className={container}>
      <h2>Uploading...</h2>
    </div>
  );
};

export default FileLoading;
