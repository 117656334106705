import { createContext } from 'react';
import IIDContext from './IIDContext';

const IDContext = createContext<IIDContext>({
  id: undefined,
  set(_) {
  },
});

export default IDContext;
