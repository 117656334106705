import { Divider, GridArea, ModelForm, Select, TextArea } from 'components';
import { BatchNormalize, createBatchDefaultValues } from 'model/Batch';
import BatchTypeField from './BatchTypeField';
import CheckNumberField from './CheckNumberField';
import DepositSlipNumberField from './DepositSlipNumberField';
import MemberBatchFormTemplate from './MemberBatchFormTemplate';
import MemberBatchTransactions from './MemberBatchTransactions';
import BatchEntityProvider from './BatchEntityProvider';
import BatchFormSummary from './BatchFormSummary';
import { useContext } from 'react';
import { IDContext } from '../../../../../core';

interface Props {
}

function MemberBatchForm(_: Props) {
  const { id } = useContext(IDContext);

  const formProps = {
    home: '/member',
    initialValues: createBatchDefaultValues(),
    normalize: BatchNormalize,
  };

  return (
    <BatchEntityProvider batch={createBatchDefaultValues()}>
      <ModelForm {...formProps}>
        {ModelField => (
          <MemberBatchFormTemplate>
            <ModelField name='memberId' value={id} type='hidden' />
            <ModelField autoFocus as={Select} name='method'>
              <option value='credit'>Credit</option>
              <option value='check'>Check</option>
              <option value='cash'>Cash</option>
            </ModelField>
            <BatchTypeField />
            <CheckNumberField />
            <DepositSlipNumberField />
            <ModelField as={TextArea} name='description' />
            <ModelField name='processedAt' type='date' label='Processed On' />
            <GridArea area='divider'>
              <Divider />
            </GridArea>
            <GridArea area='transactions'>
              <MemberBatchTransactions />
            </GridArea>
            <GridArea area='total'>
              <BatchFormSummary />
            </GridArea>
          </MemberBatchFormTemplate>
        )}
      </ModelForm>
    </BatchEntityProvider>
  );
}

export default MemberBatchForm;
