import { useContext, useMemo } from 'react';
import InternationalizationContext from '../core/InternationalizationContext';

interface CurrencyProps {
  value?: number | string;
}

function Currency({ value }: CurrencyProps) {
  const { currency } = useContext(InternationalizationContext);
  const numberFormatter = useMemo(() => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }), [currency]);

  return <>{numberFormatter.format(typeof value === 'string' ? parseFloat(value) : value || 0)}</>;
}

export default Currency;
