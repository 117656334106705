import { Currency, DateCell, GridArea, ModelPageProvider, ModelTable, QueryPageContextProvider } from 'components';
import { ModelColumnInfo } from 'data';
import { QUERY_TRANSACTION_TABLE_PAGE, TransactionTableView } from 'model';
import TransactionTableHeading from './TransactionTableHeading';
import TransactionSearchContextProvider from '../Payment/components/TransactionSearchContextProvider';

interface Props {
  home: string;
}

function TransactionTable({ home }: Props) {
  const columns: Array<ModelColumnInfo<TransactionTableView>> = [
    {
      as: DateCell,
      column: 'paymentAt',
      enumValue: 'TRANSACTION_PAYMENT_AT',
      label: 'Payment Processed',
    },
    {
      column: 'amount',
      as: Currency,
      enumValue: 'TRANSACTION_AMOUNT',
    },
    {
      column: 'description',
      enumValue: 'TRANSACTION_DESCRIPTION',
    },
    {
      column: 'type',
      disableSort: true,
    },
    {
      column: 'ledger',
      disableSort: true,
    },
    {
      column: 'createdAt',
      disableSort: true,
      label: 'Created At',
      as: DateCell,
    },
  ];

  return (
    <TransactionSearchContextProvider>
      <QueryPageContextProvider>
        <ModelPageProvider documents={{ page: QUERY_TRANSACTION_TABLE_PAGE }}>
          <GridArea area='heading'>
            <TransactionTableHeading />
          </GridArea>
          <GridArea area='content'>
            <ModelTable columns={columns} home={home} />
          </GridArea>
        </ModelPageProvider>
      </QueryPageContextProvider>
    </TransactionSearchContextProvider>
  );
}

export default TransactionTable;
